import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var OfflineRecommendForWebReq = function OfflineRecommendForWebReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.gameType = 0;
  this.currentRoomId = 0;
  this.lcid = 0;
  this.anchorId = 0;
  this.region = "";
  this.language = "";
  this.roomNum = 0;
};
OfflineRecommendForWebReq.prototype._clone = function () {
  return new NimoBuss.OfflineRecommendForWebReq();
};
OfflineRecommendForWebReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
OfflineRecommendForWebReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
OfflineRecommendForWebReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.gameType);
  os.writeInt64(2, this.currentRoomId);
  os.writeInt32(3, this.lcid);
  os.writeInt64(4, this.anchorId);
  os.writeString(5, this.region);
  os.writeString(6, this.language);
  os.writeInt32(7, this.roomNum);
};
OfflineRecommendForWebReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.gameType = is.readInt64(1, false, this.gameType);
  this.currentRoomId = is.readInt64(2, false, this.currentRoomId);
  this.lcid = is.readInt32(3, false, this.lcid);
  this.anchorId = is.readInt64(4, false, this.anchorId);
  this.region = is.readString(5, false, this.region);
  this.language = is.readString(6, false, this.language);
  this.roomNum = is.readInt32(7, false, this.roomNum);
};
NimoBuss.OfflineRecommendForWebReq = OfflineRecommendForWebReq;
export default OfflineRecommendForWebReq;