import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var BigAnimEffect = function BigAnimEffect() {
  this.sResource = "";
  this.iRoomStatus = 0;
  this.iMP4Width = 0;
  this.iMP4Height = 0;
  this.sMP4Resource = "";
  this.sMP4ConfigResource = "";
  this.vMP4Placeholder = new Taf.Vector(new Taf.STRING());
  this.sAnimEffectID = "";
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
BigAnimEffect.prototype._clone = function () {
  return new Nimo.BigAnimEffect();
};
BigAnimEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BigAnimEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BigAnimEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sResource);
  os.writeInt32(1, this.iRoomStatus);
  os.writeInt32(2, this.iMP4Width);
  os.writeInt32(3, this.iMP4Height);
  os.writeString(4, this.sMP4Resource);
  os.writeString(5, this.sMP4ConfigResource);
  os.writeVector(6, this.vMP4Placeholder);
  os.writeString(7, this.sAnimEffectID);
  os.writeMap(8, this.mExtraData);
};
BigAnimEffect.prototype.readFrom = function (is) {
  this.sResource = is.readString(0, false, this.sResource);
  this.iRoomStatus = is.readInt32(1, false, this.iRoomStatus);
  this.iMP4Width = is.readInt32(2, false, this.iMP4Width);
  this.iMP4Height = is.readInt32(3, false, this.iMP4Height);
  this.sMP4Resource = is.readString(4, false, this.sMP4Resource);
  this.sMP4ConfigResource = is.readString(5, false, this.sMP4ConfigResource);
  this.vMP4Placeholder = is.readVector(6, false, this.vMP4Placeholder);
  this.sAnimEffectID = is.readString(7, false, this.sAnimEffectID);
  this.mExtraData = is.readMap(8, false, this.mExtraData);
};
Nimo.BigAnimEffect = BigAnimEffect;
export default BigAnimEffect;