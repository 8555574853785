import ua from '@client/common/utils/ua.js';

// webkit bug: copy from https://github.com/jakearchibald/safari-14-idb-fix

function idbReady() {
  var _ua$browser;
  var isSafari = !navigator.userAgentData && (ua.isIOS || ((_ua$browser = ua.browser) === null || _ua$browser === void 0 ? void 0 : _ua$browser.name) === 'Safari' || navigator.platform && /iPhone|iPod|iPad/.test(navigator.platform)); // No point putting other browsers or older versions of Safari through this mess.

  if (!isSafari || !indexedDB.databases) return Promise.resolve();
  var intervalId;
  return new Promise(function (resolve) {
    var tryIdb = function tryIdb() {
      return indexedDB.databases().finally(resolve);
    };
    intervalId = setInterval(tryIdb, 100);
    tryIdb();
  }).finally(function () {
    return clearInterval(intervalId);
  });
}
var p;
var getPromise = function getPromise() {
  try {
    return idbReady();
  } catch (error) {
    return Promise.resolve();
  }
};

/**
 * @returns {Promise<void>}
 */
var waitIdb = function waitIdb() {
  if (!p) {
    p = getPromise();
  }
  return p;
};
export default waitIdb;