import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var SayHelloRsp = function SayHelloRsp() {
  this.iCode = 0;
  this.sMessage = "";
};
SayHelloRsp.prototype._clone = function () {
  return new Nimo.SayHelloRsp();
};
SayHelloRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SayHelloRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SayHelloRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sMessage);
};
SayHelloRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sMessage = is.readString(1, false, this.sMessage);
};
Nimo.SayHelloRsp = SayHelloRsp;
export default SayHelloRsp;