import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var RemoveMsgBlackListRsp = function RemoveMsgBlackListRsp() {
  this.iCode = 0;
  this.lUid = 0;
};
RemoveMsgBlackListRsp.prototype._clone = function () {
  return new Nimo.RemoveMsgBlackListRsp();
};
RemoveMsgBlackListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RemoveMsgBlackListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RemoveMsgBlackListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt64(1, this.lUid);
};
RemoveMsgBlackListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.lUid = is.readInt64(1, false, this.lUid);
};
Nimo.RemoveMsgBlackListRsp = RemoveMsgBlackListRsp;
export default RemoveMsgBlackListRsp;