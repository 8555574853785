import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var DashBoardRecommendConfig = function DashBoardRecommendConfig() {
  this.sTitle = "";
  this.sContent = "";
  this.sUrl = "";
  this.sImg = "";
  this.iJumpType = 0;
  this.SButtonContent = "";
};
DashBoardRecommendConfig.prototype._clone = function () {
  return new Nimo.DashBoardRecommendConfig();
};
DashBoardRecommendConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DashBoardRecommendConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DashBoardRecommendConfig.prototype.writeTo = function (os) {
  os.writeString(0, this.sTitle);
  os.writeString(1, this.sContent);
  os.writeString(2, this.sUrl);
  os.writeString(3, this.sImg);
  os.writeInt32(4, this.iJumpType);
  os.writeString(5, this.SButtonContent);
};
DashBoardRecommendConfig.prototype.readFrom = function (is) {
  this.sTitle = is.readString(0, false, this.sTitle);
  this.sContent = is.readString(1, false, this.sContent);
  this.sUrl = is.readString(2, false, this.sUrl);
  this.sImg = is.readString(3, false, this.sImg);
  this.iJumpType = is.readInt32(4, false, this.iJumpType);
  this.SButtonContent = is.readString(5, false, this.SButtonContent);
};
Nimo.DashBoardRecommendConfig = DashBoardRecommendConfig;
export default DashBoardRecommendConfig;