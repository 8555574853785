import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserInfo';
var GetRoomManagerListRsp = function GetRoomManagerListRsp() {
  this.lRoomId = 0;
  this.iPageSize = 0;
  this.iMaxPage = 0;
  this.iCurPage = 0;
  this.iUserList = new Taf.Vector(new Nimo.UserInfo());
  this.mUid2Privilegetes = new Taf.Map(new Taf.INT64(), new Taf.Vector(new Taf.INT32()));
};
GetRoomManagerListRsp.prototype._clone = function () {
  return new Nimo.GetRoomManagerListRsp();
};
GetRoomManagerListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRoomManagerListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRoomManagerListRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt32(1, this.iPageSize);
  os.writeInt32(2, this.iMaxPage);
  os.writeInt32(3, this.iCurPage);
  os.writeVector(4, this.iUserList);
  os.writeMap(6, this.mUid2Privilegetes);
};
GetRoomManagerListRsp.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.iPageSize = is.readInt32(1, false, this.iPageSize);
  this.iMaxPage = is.readInt32(2, false, this.iMaxPage);
  this.iCurPage = is.readInt32(3, false, this.iCurPage);
  this.iUserList = is.readVector(4, false, this.iUserList);
  this.mUid2Privilegetes = is.readMap(6, false, this.mUid2Privilegetes);
};
Nimo.GetRoomManagerListRsp = GetRoomManagerListRsp;
export default GetRoomManagerListRsp;