import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetMsgGroupMemberInfoListReq = function GetMsgGroupMemberInfoListReq() {
  this.tUserId = new Nimo.UserId();
  this.lMsgGroupId = 0;
};
GetMsgGroupMemberInfoListReq.prototype._clone = function () {
  return new Nimo.GetMsgGroupMemberInfoListReq();
};
GetMsgGroupMemberInfoListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMsgGroupMemberInfoListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMsgGroupMemberInfoListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lMsgGroupId);
};
GetMsgGroupMemberInfoListReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lMsgGroupId = is.readInt64(1, false, this.lMsgGroupId);
};
Nimo.GetMsgGroupMemberInfoListReq = GetMsgGroupMemberInfoListReq;
export default GetMsgGroupMemberInfoListReq;