import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './EventRoomView';
import './RoomScreenshotsView';
import './TeamRoomView';
import './StarLiveRoomView';
import './NormalMultiRoomView';
var LiveChannelStaticVo = function LiveChannelStaticVo() {
  this.channelId = 0;
  this.roomTheme = "";
  this.alise = "";
  this.roomType = 0;
  this.roomTypeName = "";
  this.roomSort = 0;
  this.businessType = 0;
  this.templateType = 0;
  this.anchorName = "";
  this.anchorAvatarUrl = "";
  this.avatarBoxUrl = "";
  this.authenticatedAnchor = 0;
  this.anchorAnnouncement = "";
  this.anchorCountryCode = "";
  this.eventId = 0;
  this.eventRoomListView = new Taf.Vector(new NimoBuss.EventRoomView());
  this.headImg = "";
  this.backgroundImg = "";
  this.anchorId = 0;
  this.userNimoId = 0;
  this.roomScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.showScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.roomNumbering = "";
  this.liveStreamStatus = 0;
  this.lcid = 0;
  this.lcidText = "";
  this.endLiveTime = 0;
  this.createdTime = 0;
  this.updatedTime = 0;
  this.teamRoomViews = new Taf.Vector(new NimoBuss.TeamRoomView());
  this.teamId = 0;
  this.starLiveRoomView = new NimoBuss.StarLiveRoomView();
  this.dynamicAvatarBoxUrl = "";
  this.normalMultiRoomList = new Taf.Vector(new NimoBuss.NormalMultiRoomView());
  this.liveId = 0;
  this.chatRuleStatus = 0;
  this.chatRuleText = "";
  this.subRoomSort = 0;
  this.voiceScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.isLiveHouse = 0;
  this.liveType = 0;
  this.blockCurrentUser = 0;
  this.anchorLocationAnnounce = "";
  this.anchorAgeAnnounce = "";
};
LiveChannelStaticVo.prototype._clone = function () {
  return new NimoBuss.LiveChannelStaticVo();
};
LiveChannelStaticVo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveChannelStaticVo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveChannelStaticVo.prototype.writeTo = function (os) {
  os.writeInt64(1, this.channelId);
  os.writeString(2, this.roomTheme);
  os.writeString(3, this.alise);
  os.writeInt64(4, this.roomType);
  os.writeString(5, this.roomTypeName);
  os.writeInt32(6, this.roomSort);
  os.writeInt32(7, this.businessType);
  os.writeInt32(8, this.templateType);
  os.writeString(9, this.anchorName);
  os.writeString(10, this.anchorAvatarUrl);
  os.writeString(11, this.avatarBoxUrl);
  os.writeInt32(12, this.authenticatedAnchor);
  os.writeString(13, this.anchorAnnouncement);
  os.writeString(14, this.anchorCountryCode);
  os.writeInt64(15, this.eventId);
  os.writeVector(16, this.eventRoomListView);
  os.writeString(17, this.headImg);
  os.writeString(18, this.backgroundImg);
  os.writeInt64(19, this.anchorId);
  os.writeInt64(20, this.userNimoId);
  os.writeVector(21, this.roomScreenshots);
  os.writeVector(22, this.showScreenshots);
  os.writeString(23, this.roomNumbering);
  os.writeInt32(24, this.liveStreamStatus);
  os.writeInt32(25, this.lcid);
  os.writeString(26, this.lcidText);
  os.writeInt32(27, this.endLiveTime);
  os.writeInt64(28, this.createdTime);
  os.writeInt64(29, this.updatedTime);
  os.writeVector(30, this.teamRoomViews);
  os.writeInt32(31, this.teamId);
  os.writeStruct(32, this.starLiveRoomView);
  os.writeString(33, this.dynamicAvatarBoxUrl);
  os.writeVector(34, this.normalMultiRoomList);
  os.writeInt64(35, this.liveId);
  os.writeInt32(36, this.chatRuleStatus);
  os.writeString(37, this.chatRuleText);
  os.writeInt32(38, this.subRoomSort);
  os.writeVector(39, this.voiceScreenshots);
  os.writeInt32(40, this.isLiveHouse);
  os.writeInt32(41, this.liveType);
  os.writeInt32(42, this.blockCurrentUser);
  os.writeString(43, this.anchorLocationAnnounce);
  os.writeString(44, this.anchorAgeAnnounce);
};
LiveChannelStaticVo.prototype.readFrom = function (is) {
  this.channelId = is.readInt64(1, false, this.channelId);
  this.roomTheme = is.readString(2, false, this.roomTheme);
  this.alise = is.readString(3, false, this.alise);
  this.roomType = is.readInt64(4, false, this.roomType);
  this.roomTypeName = is.readString(5, false, this.roomTypeName);
  this.roomSort = is.readInt32(6, false, this.roomSort);
  this.businessType = is.readInt32(7, false, this.businessType);
  this.templateType = is.readInt32(8, false, this.templateType);
  this.anchorName = is.readString(9, false, this.anchorName);
  this.anchorAvatarUrl = is.readString(10, false, this.anchorAvatarUrl);
  this.avatarBoxUrl = is.readString(11, false, this.avatarBoxUrl);
  this.authenticatedAnchor = is.readInt32(12, false, this.authenticatedAnchor);
  this.anchorAnnouncement = is.readString(13, false, this.anchorAnnouncement);
  this.anchorCountryCode = is.readString(14, false, this.anchorCountryCode);
  this.eventId = is.readInt64(15, false, this.eventId);
  this.eventRoomListView = is.readVector(16, false, this.eventRoomListView);
  this.headImg = is.readString(17, false, this.headImg);
  this.backgroundImg = is.readString(18, false, this.backgroundImg);
  this.anchorId = is.readInt64(19, false, this.anchorId);
  this.userNimoId = is.readInt64(20, false, this.userNimoId);
  this.roomScreenshots = is.readVector(21, false, this.roomScreenshots);
  this.showScreenshots = is.readVector(22, false, this.showScreenshots);
  this.roomNumbering = is.readString(23, false, this.roomNumbering);
  this.liveStreamStatus = is.readInt32(24, false, this.liveStreamStatus);
  this.lcid = is.readInt32(25, false, this.lcid);
  this.lcidText = is.readString(26, false, this.lcidText);
  this.endLiveTime = is.readInt32(27, false, this.endLiveTime);
  this.createdTime = is.readInt64(28, false, this.createdTime);
  this.updatedTime = is.readInt64(29, false, this.updatedTime);
  this.teamRoomViews = is.readVector(30, false, this.teamRoomViews);
  this.teamId = is.readInt32(31, false, this.teamId);
  this.starLiveRoomView = is.readStruct(32, false, this.starLiveRoomView);
  this.dynamicAvatarBoxUrl = is.readString(33, false, this.dynamicAvatarBoxUrl);
  this.normalMultiRoomList = is.readVector(34, false, this.normalMultiRoomList);
  this.liveId = is.readInt64(35, false, this.liveId);
  this.chatRuleStatus = is.readInt32(36, false, this.chatRuleStatus);
  this.chatRuleText = is.readString(37, false, this.chatRuleText);
  this.subRoomSort = is.readInt32(38, false, this.subRoomSort);
  this.voiceScreenshots = is.readVector(39, false, this.voiceScreenshots);
  this.isLiveHouse = is.readInt32(40, false, this.isLiveHouse);
  this.liveType = is.readInt32(41, false, this.liveType);
  this.blockCurrentUser = is.readInt32(42, false, this.blockCurrentUser);
  this.anchorLocationAnnounce = is.readString(43, false, this.anchorLocationAnnounce);
  this.anchorAgeAnnounce = is.readString(44, false, this.anchorAgeAnnounce);
};
NimoBuss.LiveChannelStaticVo = LiveChannelStaticVo;
export default LiveChannelStaticVo;