import nimoReport from '@client/common/services/report';
// import ua from '@client/common/utils/ua';
import { local } from '@client/common/utils/storage';

// const { isIOS, isAdr } = ua;
// eslint-disable-next-line
// const platform = isIOS ? 'ios' : isAdr ? 'android' : false; // 区分 ios 和 android
// const reportPlatform = {
//   platform_: platform
// };
// const getType = businessType => (businessType === 1 ? 'game' : 'liveshow');
var isFirst = function isFirst(eventname) {
  if (local.get(eventname)) {
    return false;
  } else {
    local.set(eventname);
    return true;
  }
};
export default {
  groupup_guide_show: function groupup_guide_show() {
    nimoReport.report(nimoReport.formatReportObj('sys/show/groupup_guide', '系统曝光groupup引导', {
      state_: isFirst("groupup_guide_show") ? 'first_guide' : 'non_first_guide'
    }));
  },
  groupup_guide_click: function groupup_guide_click() {
    nimoReport.report(nimoReport.formatReportObj('usr/click/groupup_guide', '用户点击groupup引导', {
      state_: isFirst("groupup_guide_show_click") ? 'first_guide' : 'non_first_guide'
    }));
  },
  groupup_guide_icon_click: function groupup_guide_icon_click() {
    nimoReport.report(nimoReport.formatReportObj('usr/click/groupup_guide_icon', '用户点击groupup引导查看icon'));
  },
  groupup_setting_show: function groupup_setting_show(ispanel) {
    nimoReport.report(nimoReport.formatReportObj('sys/show/groupup_setting', '系统曝光groupup设置界面', {
      from_: ispanel ? 'panel' : 'popup'
    }));
  },
  groupup_setting_copy: function groupup_setting_copy(_ref) {
    var target = _ref.target;
    nimoReport.report(nimoReport.formatReportObj('usr/click/groupup_setting_copy', '用户点击1设置界面的复制按钮', {
      goal_: target
    }));
  },
  groupup_setting_start: function groupup_setting_start(_ref2) {
    var target = _ref2.target;
    nimoReport.report(nimoReport.formatReportObj('usr/click/groupup_setting_start', '用户点击1设置界面的开启按钮', {
      goal_: target
    }));
  },
  groupup_progress_show: function groupup_progress_show(_ref3) {
    var target = _ref3.target,
      _ref3$from = _ref3.from,
      from = _ref3$from === void 0 ? 'panel' : _ref3$from;
    nimoReport.report(nimoReport.formatReportObj('sys/show/groupup_progress', '系统曝光groupup进程中界面', {
      goal_: target,
      from_: from
    }));
  },
  groupup_progress_share: function groupup_progress_share(_ref4) {
    var target = _ref4.target;
    nimoReport.report(nimoReport.formatReportObj('usr/click/groupup_progress_share', '用户点击groupup进程中界面的分享按钮', {
      goal_: target
    }));
  },
  groupup_progress_copy: function groupup_progress_copy(_ref5) {
    var target = _ref5.target;
    nimoReport.report(nimoReport.formatReportObj('usr/click/groupup_progress_copy', '用户点击groupup进程中界面的复制按钮', {
      goal_: target
    }));
  },
  groupup_progress_share_show: function groupup_progress_share_show(_ref6) {
    var target = _ref6.target,
      from = _ref6.from;
    nimoReport.report(nimoReport.formatReportObj('sys/show/share_popup', '系统展示1分享引导弹窗', {
      goal_: target,
      from_: from
    }));
  },
  groupup_share_click: function groupup_share_click(_ref7) {
    var platform = _ref7.platform;
    nimoReport.report(nimoReport.formatReportObj('usr/click/share_popup', '用户点击1分享引导弹窗', {
      platform_: platform
    }));
  },
  /**
   * 
   * @param {int} type 0:主播未完成拉新任务，但用户完成了拉新任务,1:主播完成了拉新任务，但用户未完成,2:主播和用户均未完成拉新任务,3:完成
   */
  groupup_result_show: function groupup_result_show(_ref8) {
    var target = _ref8.target,
      finished_goal = _ref8.finished_goal,
      isSuccess = _ref8.isSuccess,
      type = _ref8.type;
    nimoReport.report(nimoReport.formatReportObj('sys/show/groupup_result', '系统曝光groupup结果页面', {
      goal_: target,
      finished_goal_: finished_goal,
      state_: isSuccess ? 'success' : 'fail',
      reason_: ['streamer_unfinished', 'user_unfinished', 'overall_unfinished', 'finished'][type]
    }));
  },
  groupup_result_copy: function groupup_result_copy(_ref9) {
    var target = _ref9.target;
    nimoReport.report(nimoReport.formatReportObj('usr/click/groupup_result_copy', '用户点击groupup结果界面的复制按钮', {
      goal_: target
    }));
  },
  groupup_poup_start: function groupup_poup_start(uid) {
    nimoReport.report(nimoReport.formatReportObj('usr/click/selfpromotion_togroupup_poup_start', '用户点击Groupup引导开启弹窗中的开启GroupUP', {
      streameruid_: uid
    }));
  },
  groupup_poup_decline: function groupup_poup_decline(uid) {
    nimoReport.report(nimoReport.formatReportObj('usr/click/usr/click/selfpromotion_togroupup_poup_decline', '用户点击Groupup引导开启弹窗中的拒绝GroupUP', {
      streameruid_: uid
    }));
  },
  groupup_popup: function groupup_popup(uid) {
    nimoReport.report(nimoReport.formatReportObj('sys/show/selfpromotion_togroupup_popup', '系统曝光Groupup引导开启弹窗', {
      streameruid_: uid
    }));
  }
};