import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
import { UPDATE_GAME_LIST } from './actions';
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = _objectWithoutProperties(action, _excluded);
  switch (type) {
    case UPDATE_GAME_LIST:
      return payload === null || payload === void 0 ? void 0 : payload.gameList;
    default:
      return state;
  }
}
export default reducer;