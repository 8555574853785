import NimoSailActivity from "../nimosailactivity";
import Taf from "@nimo-fed/taf-lib-wup";
var QueryShareRewardReq = function QueryShareRewardReq() {
  this.udbUserId = 0;
};
QueryShareRewardReq.prototype._clone = function () {
  return new NimoSailActivity.QueryShareRewardReq();
};
QueryShareRewardReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
QueryShareRewardReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
QueryShareRewardReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
};
QueryShareRewardReq.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
};
NimoSailActivity.QueryShareRewardReq = QueryShareRewardReq;
export default QueryShareRewardReq;