import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var DeleteVideoReq = function DeleteVideoReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.lAuthorId = 0;
  this.opsType = 0;
  this.videoIdList = new Taf.Vector(new Taf.STRING());
  this.dotType = 0;
  this.liveId = "";
  this.opsTarget = 0;
};
DeleteVideoReq.prototype._clone = function () {
  return new NimoBuss.DeleteVideoReq();
};
DeleteVideoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DeleteVideoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DeleteVideoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.lAuthorId);
  os.writeInt32(2, this.opsType);
  os.writeVector(3, this.videoIdList);
  os.writeInt32(4, this.dotType);
  os.writeString(5, this.liveId);
  os.writeInt32(6, this.opsTarget);
};
DeleteVideoReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.lAuthorId = is.readInt64(1, false, this.lAuthorId);
  this.opsType = is.readInt32(2, false, this.opsType);
  this.videoIdList = is.readVector(3, false, this.videoIdList);
  this.dotType = is.readInt32(4, false, this.dotType);
  this.liveId = is.readString(5, false, this.liveId);
  this.opsTarget = is.readInt32(6, false, this.opsTarget);
};
NimoBuss.DeleteVideoReq = DeleteVideoReq;
export default DeleteVideoReq;