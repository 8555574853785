import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var UserClipRsp = function UserClipRsp() {
  this.iCode = 0;
  this.sMessage = "";
};
UserClipRsp.prototype._clone = function () {
  return new NimoBuss.UserClipRsp();
};
UserClipRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserClipRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserClipRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sMessage);
};
UserClipRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sMessage = is.readString(1, false, this.sMessage);
};
NimoBuss.UserClipRsp = UserClipRsp;
export default UserClipRsp;