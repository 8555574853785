import { parseURL, roomEnterURL } from '@client/common/utils/fn';
import { FROM_COMMON_SHARE_MAP } from '@client/common/services/commonShare';
import addUrlParams from '@client/common/utils/addUrlParams';
import { platformIdOfCopy } from '@desktop/src/common/components/RoomShare/constants';
var commonShareBaseUrl = 'https://nimotv.onelink.me/B6cW';
export var checkIsShortChain = function checkIsShortChain(url) {
  var result = false;
  if (url) {
    var _parseURL = parseURL(url),
      host = _parseURL.host;
    if (host === DOMAINS.vip_short_chain_domain) {
      result = true;
    }
  }
  return result;
};

/**
 * 拼接唯一的onelink长链
 * 因为获取个性化域名短链需要保持长链唯一，故新增该方法供此场景使用
 * * @param roomInfo
 * * @returns {string}
 */
export var getSvipLongChain = function getSvipLongChain(_ref) {
  var roomInfo = _ref.roomInfo,
    overrideShareUserId = _ref.overrideShareUserId;
  var roomId = roomInfo.roomId,
    anchorId = roomInfo.anchorId;
  var domain = DOMAINS.isDebug === 'true' ? 'www-test.nimo.tv' : DOMAINS.maindomain;
  var _fallbackUrl = "https://".concat(domain).concat(roomEnterURL(roomInfo));
  var from = FROM_COMMON_SHARE_MAP.SELF;
  var source = platformIdOfCopy;
  var shareuid = overrideShareUserId !== null && overrideShareUserId !== void 0 ? overrideShareUserId : roomId;
  var encodedDeepLink = '';
  encodedDeepLink = "nimotv://huya.nimo.com/living_room?anchor_id=".concat(anchorId, "&room_id=").concat(roomId, "&from=").concat(from);
  encodedDeepLink = encodeURIComponent(encodedDeepLink);

  // NOTE: 保持参数固定，即参数顺序不能变，也不能新增或删除
  var commonShareParams = {
    pid: 'nimo_wap',
    c: 'common_share',
    af_ios_url: encodeURIComponent(_fallbackUrl),
    af_android_url: encodeURIComponent(_fallbackUrl),
    af_web_dp: encodeURIComponent(_fallbackUrl),
    // 为保持长链唯一，此处不加_lang
    af_dp: encodedDeepLink,
    af_adset: from,
    af_sub1: from,
    af_sub2: roomId,
    af_sub3: shareuid,
    af_force_deeplink: 'true',
    is_retargeting: 'true',
    af_sub4: source,
    af_ad: source
  };
  return addUrlParams(commonShareBaseUrl, commonShareParams);
};