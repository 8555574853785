import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var AnchorLevelUpMsgReq = function AnchorLevelUpMsgReq() {
  this.tUser = new Nimo.UserId();
};
AnchorLevelUpMsgReq.prototype._clone = function () {
  return new Nimo.AnchorLevelUpMsgReq();
};
AnchorLevelUpMsgReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelUpMsgReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelUpMsgReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
};
AnchorLevelUpMsgReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
};
Nimo.AnchorLevelUpMsgReq = AnchorLevelUpMsgReq;
export default AnchorLevelUpMsgReq;