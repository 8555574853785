import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { fetchRoomInfo } from '@desktop/src/modules/dashboard/index/index/api';
import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useMount } from '@client/common/components/hooks/useMount';
var _loading = false;
var _promiseCache;
var getRoomInfo = function getRoomInfo() {
  _loading = true;
  _promiseCache = fetchRoomInfo.apply(void 0, arguments);
  return _promiseCache;
};
var isEmptyObject = function isEmptyObject(obj) {
  return !obj || Object.keys(obj).length === 0;
};
var initPromiseWrapper = function initPromiseWrapper() {
  var wrapper = {};
  wrapper.promise = new Promise(function (resolve, reject) {
    wrapper.resolve = resolve;
    wrapper.reject = reject;
  });
  return wrapper;
};
export var useGetRoomInfo = function useGetRoomInfo() {
  var _useSelector = useSelector(function (state) {
      return {
        isLandingPage: state.common.isLandingPage,
        userInfo: state.home.room.userInfo,
        reduxRoomInfo: state.home.room.roomInfo
      };
    }, shallowEqual),
    userInfo = _useSelector.userInfo,
    reduxRoomInfo = _useSelector.reduxRoomInfo,
    isLandingPage = _useSelector.isLandingPage;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setOpen = _useState2[1];
  var roomInfoPromiseWrapper = useRef();
  useMount(function () {
    if (!roomInfoPromiseWrapper.current) {
      roomInfoPromiseWrapper.current = initPromiseWrapper();
    }
    var udbUserId = userInfo.udbUserId,
      lang = userInfo.lang;
    if (isLandingPage) {
      // 落地页用缓存的roomInfo
      if (!_loading) {
        getRoomInfo(udbUserId, lang).then(roomInfoPromiseWrapper.current.resolve);
      } else if (_promiseCache) {
        _promiseCache.then(roomInfoPromiseWrapper.current.resolve);
      }
      setOpen(true);
    } else {
      // 用刷新的roomInfo
      getRoomInfo(udbUserId, lang).then(function (ret) {
        setOpen(true);
        roomInfoPromiseWrapper.current.resolve(ret);
      });
    }
  });
  var isEmpty = useMemo(function () {
    return isEmptyObject(reduxRoomInfo);
  }, [reduxRoomInfo]);
  var innerGetRoomInfo = useCallback(function () {
    if (!roomInfoPromiseWrapper.current) {
      roomInfoPromiseWrapper.current = initPromiseWrapper();
    }
    return roomInfoPromiseWrapper.current.promise;
  }, []);
  var roomInfo = isOpen && !isEmpty ? reduxRoomInfo : null;
  return [roomInfo, innerGetRoomInfo];
};

// 强制获取新的直播间信息
export var useInitRoomInfo = function useInitRoomInfo() {
  var _useGetRoomInfo = useGetRoomInfo(),
    _useGetRoomInfo2 = _slicedToArray(_useGetRoomInfo, 1),
    roomInfo = _useGetRoomInfo2[0];
  return roomInfo;
};

// 获取直播间信息（不重复获取）
// 返回roomInfo === null说明在加载
// isAnchor true 时确定是主播
// isNotAnchor true时确定不是主播
export var useRoomInfo = function useRoomInfo() {
  var _useSelector2 = useSelector(function (state) {
      return {
        userInfo: state.home.room.userInfo,
        reduxRoomInfo: state.home.room.roomInfo
      };
    }, shallowEqual),
    userInfo = _useSelector2.userInfo,
    reduxRoomInfo = _useSelector2.reduxRoomInfo;
  var isEmpty = useMemo(function () {
    return isEmptyObject(reduxRoomInfo);
  }, [reduxRoomInfo]);
  useEffect(function () {
    if (!isEmpty || _loading) return;
    var udbUserId = userInfo.udbUserId,
      lang = userInfo.lang;
    getRoomInfo(udbUserId, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var roomId = reduxRoomInfo === null || reduxRoomInfo === void 0 ? void 0 : reduxRoomInfo.roomId;
  // 确定不是主播
  var isNotAnchor = isEmpty ? false : !roomId;
  // 确定是主播
  var isAnchor = roomId > 0;
  return {
    isRoomInfoLoading: isEmpty,
    roomInfo: isEmpty ? null : reduxRoomInfo,
    isAnchor: isAnchor,
    isNotAnchor: isNotAnchor
  };
};