import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var LiveChannelToCParam = function LiveChannelToCParam() {
  this.baseParam = new NimoBuss.BaseParam();
  this.channelId = 0;
  this.anchorId = 0;
  this.aliase = "";
  this.searchType = 0;
  this.language = "";
  this.countryCode = "";
  this.lcid = 0;
};
LiveChannelToCParam.prototype._clone = function () {
  return new NimoBuss.LiveChannelToCParam();
};
LiveChannelToCParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveChannelToCParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveChannelToCParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.channelId);
  os.writeInt64(2, this.anchorId);
  os.writeString(3, this.aliase);
  os.writeInt32(4, this.searchType);
  os.writeString(5, this.language);
  os.writeString(6, this.countryCode);
  os.writeInt32(7, this.lcid);
};
LiveChannelToCParam.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.channelId = is.readInt64(1, false, this.channelId);
  this.anchorId = is.readInt64(2, false, this.anchorId);
  this.aliase = is.readString(3, false, this.aliase);
  this.searchType = is.readInt32(4, false, this.searchType);
  this.language = is.readString(5, false, this.language);
  this.countryCode = is.readString(6, false, this.countryCode);
  this.lcid = is.readInt32(7, false, this.lcid);
};
NimoBuss.LiveChannelToCParam = LiveChannelToCParam;
export default LiveChannelToCParam;