import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var OverlayEffect = function OverlayEffect() {
  this.sWebUrl = "";
  this.sAdrVerticalUrl = "";
  this.sAdrHorizontalUrl = "";
  this.sIosVerticalUrl = "";
  this.sIosHorizontalUrl = "";
};
OverlayEffect.prototype._clone = function () {
  return new Nimo.OverlayEffect();
};
OverlayEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
OverlayEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
OverlayEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sWebUrl);
  os.writeString(1, this.sAdrVerticalUrl);
  os.writeString(2, this.sAdrHorizontalUrl);
  os.writeString(3, this.sIosVerticalUrl);
  os.writeString(4, this.sIosHorizontalUrl);
};
OverlayEffect.prototype.readFrom = function (is) {
  this.sWebUrl = is.readString(0, false, this.sWebUrl);
  this.sAdrVerticalUrl = is.readString(1, false, this.sAdrVerticalUrl);
  this.sAdrHorizontalUrl = is.readString(2, false, this.sAdrHorizontalUrl);
  this.sIosVerticalUrl = is.readString(3, false, this.sIosVerticalUrl);
  this.sIosHorizontalUrl = is.readString(4, false, this.sIosHorizontalUrl);
};
Nimo.OverlayEffect = OverlayEffect;
export default OverlayEffect;