import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetPushVideoHisReq = function GetPushVideoHisReq() {
  this.user = new Nimo.UserId();
  this.iPage = 0;
  this.iPageNum = 0;
};
GetPushVideoHisReq.prototype._clone = function () {
  return new Nimo.GetPushVideoHisReq();
};
GetPushVideoHisReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPushVideoHisReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPushVideoHisReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt32(1, this.iPage);
  os.writeInt32(2, this.iPageNum);
};
GetPushVideoHisReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.iPage = is.readInt32(1, false, this.iPage);
  this.iPageNum = is.readInt32(2, false, this.iPageNum);
};
Nimo.GetPushVideoHisReq = GetPushVideoHisReq;
export default GetPushVideoHisReq;