import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var DecorationInfo = function DecorationInfo() {
  this.iAppId = 0;
  this.iViewType = 0;
  this.vData = new Taf.BinBuffer();
  this.sName = "";
  this.iAvailableDays = 0;
  this.iSort = 0;
  this.iRank = 0;
  this.sIconUrl = "";
  this.lFansBadgeId = 0;
  this.iFansLevel = 0;
  this.iPositionType = 0;
  this.iRecordId = 0;
  this.sDynamicUrl = "";
  this.mNameLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sLevelName = "";
  this.mLevelNameLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iUpperLimitDay = 0;
};
DecorationInfo.prototype._clone = function () {
  return new Nimo.DecorationInfo();
};
DecorationInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DecorationInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DecorationInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iAppId);
  os.writeInt32(1, this.iViewType);
  os.writeBytes(2, this.vData);
  os.writeString(3, this.sName);
  os.writeInt32(4, this.iAvailableDays);
  os.writeInt32(5, this.iSort);
  os.writeInt32(6, this.iRank);
  os.writeString(7, this.sIconUrl);
  os.writeInt64(8, this.lFansBadgeId);
  os.writeInt32(9, this.iFansLevel);
  os.writeInt32(10, this.iPositionType);
  os.writeInt32(11, this.iRecordId);
  os.writeString(12, this.sDynamicUrl);
  os.writeMap(13, this.mNameLang);
  os.writeMap(14, this.mExtraData);
  os.writeString(15, this.sLevelName);
  os.writeMap(16, this.mLevelNameLang);
  os.writeInt32(17, this.iUpperLimitDay);
};
DecorationInfo.prototype.readFrom = function (is) {
  this.iAppId = is.readInt32(0, false, this.iAppId);
  this.iViewType = is.readInt32(1, false, this.iViewType);
  this.vData = is.readBytes(2, false, this.vData);
  this.sName = is.readString(3, false, this.sName);
  this.iAvailableDays = is.readInt32(4, false, this.iAvailableDays);
  this.iSort = is.readInt32(5, false, this.iSort);
  this.iRank = is.readInt32(6, false, this.iRank);
  this.sIconUrl = is.readString(7, false, this.sIconUrl);
  this.lFansBadgeId = is.readInt64(8, false, this.lFansBadgeId);
  this.iFansLevel = is.readInt32(9, false, this.iFansLevel);
  this.iPositionType = is.readInt32(10, false, this.iPositionType);
  this.iRecordId = is.readInt32(11, false, this.iRecordId);
  this.sDynamicUrl = is.readString(12, false, this.sDynamicUrl);
  this.mNameLang = is.readMap(13, false, this.mNameLang);
  this.mExtraData = is.readMap(14, false, this.mExtraData);
  this.sLevelName = is.readString(15, false, this.sLevelName);
  this.mLevelNameLang = is.readMap(16, false, this.mLevelNameLang);
  this.iUpperLimitDay = is.readInt32(17, false, this.iUpperLimitDay);
};
Nimo.DecorationInfo = DecorationInfo;
export default DecorationInfo;