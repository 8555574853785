import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var DelVIPCustomEmojiReq = function DelVIPCustomEmojiReq() {
  this.tUser = new Nimo.UserId();
  this.iLevel = 0;
  this.iPosition = 0;
};
DelVIPCustomEmojiReq.prototype._clone = function () {
  return new Nimo.DelVIPCustomEmojiReq();
};
DelVIPCustomEmojiReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DelVIPCustomEmojiReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DelVIPCustomEmojiReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt32(1, this.iLevel);
  os.writeInt32(2, this.iPosition);
};
DelVIPCustomEmojiReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.iLevel = is.readInt32(1, false, this.iLevel);
  this.iPosition = is.readInt32(2, false, this.iPosition);
};
Nimo.DelVIPCustomEmojiReq = DelVIPCustomEmojiReq;
export default DelVIPCustomEmojiReq;