import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var CheckMsgBlackListRsp = function CheckMsgBlackListRsp() {
  this.iCode = 0;
  this.lUid = 0;
  this.bBlack = true;
};
CheckMsgBlackListRsp.prototype._clone = function () {
  return new Nimo.CheckMsgBlackListRsp();
};
CheckMsgBlackListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CheckMsgBlackListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CheckMsgBlackListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt64(1, this.lUid);
  os.writeBoolean(2, this.bBlack);
};
CheckMsgBlackListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.bBlack = is.readBoolean(2, false, this.bBlack);
};
Nimo.CheckMsgBlackListRsp = CheckMsgBlackListRsp;
export default CheckMsgBlackListRsp;