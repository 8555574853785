import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GiftTab = function GiftTab() {
  this.sTabName = "";
  this.vGiftId = new Taf.Vector(new Taf.INT32());
  this.iTabId = 0;
  this.iTabType = 0;
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sBackground = "";
  this.sGotoURL = "";
  this.sDescription = "";
};
GiftTab.prototype._clone = function () {
  return new Nimo.GiftTab();
};
GiftTab.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftTab.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftTab.prototype.writeTo = function (os) {
  os.writeString(0, this.sTabName);
  os.writeVector(1, this.vGiftId);
  os.writeInt32(2, this.iTabId);
  os.writeInt32(3, this.iTabType);
  os.writeMap(4, this.mExtraData);
  os.writeString(5, this.sBackground);
  os.writeString(6, this.sGotoURL);
  os.writeString(7, this.sDescription);
};
GiftTab.prototype.readFrom = function (is) {
  this.sTabName = is.readString(0, false, this.sTabName);
  this.vGiftId = is.readVector(1, false, this.vGiftId);
  this.iTabId = is.readInt32(2, false, this.iTabId);
  this.iTabType = is.readInt32(3, false, this.iTabType);
  this.mExtraData = is.readMap(4, false, this.mExtraData);
  this.sBackground = is.readString(5, false, this.sBackground);
  this.sGotoURL = is.readString(6, false, this.sGotoURL);
  this.sDescription = is.readString(7, false, this.sDescription);
};
Nimo.GiftTab = GiftTab;
export default GiftTab;