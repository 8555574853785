import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './EPushUrlType';
import './UserId';
import './StreamInfo';
var GetPushInfoReq = function GetPushInfoReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.bCreateNew = true;
  this.bKickOther = false;
  this.eUseType = Nimo.EPushUrlType.EPUSH_APP;
  this.tStreamInfo = new Nimo.StreamInfo();
  this.sRegion = "";
  this.sNetwork = "";
  this.sNetvender = "";
  this.sDevice = "";
  this.sCpu = "";
  this.iMem = 0;
  this.sGpu = "";
  this.iHWACC = 0;
  this.bNotRepush = false;
};
GetPushInfoReq.prototype._clone = function () {
  return new Nimo.GetPushInfoReq();
};
GetPushInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPushInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPushInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeBoolean(2, this.bCreateNew);
  os.writeBoolean(3, this.bKickOther);
  os.writeInt32(4, this.eUseType);
  os.writeStruct(5, this.tStreamInfo);
  os.writeString(6, this.sRegion);
  os.writeString(7, this.sNetwork);
  os.writeString(8, this.sNetvender);
  os.writeString(9, this.sDevice);
  os.writeString(10, this.sCpu);
  os.writeInt32(11, this.iMem);
  os.writeString(12, this.sGpu);
  os.writeInt32(13, this.iHWACC);
  os.writeBoolean(14, this.bNotRepush);
};
GetPushInfoReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.bCreateNew = is.readBoolean(2, false, this.bCreateNew);
  this.bKickOther = is.readBoolean(3, false, this.bKickOther);
  this.eUseType = is.readInt32(4, false, this.eUseType);
  this.tStreamInfo = is.readStruct(5, false, this.tStreamInfo);
  this.sRegion = is.readString(6, false, this.sRegion);
  this.sNetwork = is.readString(7, false, this.sNetwork);
  this.sNetvender = is.readString(8, false, this.sNetvender);
  this.sDevice = is.readString(9, false, this.sDevice);
  this.sCpu = is.readString(10, false, this.sCpu);
  this.iMem = is.readInt32(11, false, this.iMem);
  this.sGpu = is.readString(12, false, this.sGpu);
  this.iHWACC = is.readInt32(13, false, this.iHWACC);
  this.bNotRepush = is.readBoolean(14, false, this.bNotRepush);
};
Nimo.GetPushInfoReq = GetPushInfoReq;
export default GetPushInfoReq;