import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GiftEffectInfo = function GiftEffectInfo() {
  this.iEffectType = 0;
  this.iPropsCount = 1;
  this.sResource = "";
  this.iConfigType = 0;
  this.dDisplayDuration = 0;
  this.lTriggerCondition = 0;
  this.iEdition = 0;
  this.iChannel = 0;
  this.iPosition = 0;
  this.iRoomStatus = 0;
  this.iStreamerLevel = 0;
  this.iMP4Width = 0;
  this.iMP4Height = 0;
  this.sMP4Resource = "";
  this.iJumpType = 0;
  this.sJumpUrl = "";
};
GiftEffectInfo.prototype._clone = function () {
  return new Nimo.GiftEffectInfo();
};
GiftEffectInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftEffectInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftEffectInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iEffectType);
  os.writeInt32(1, this.iPropsCount);
  os.writeString(2, this.sResource);
  os.writeInt32(3, this.iConfigType);
  os.writeDouble(4, this.dDisplayDuration);
  os.writeInt64(5, this.lTriggerCondition);
  os.writeInt32(6, this.iEdition);
  os.writeInt32(7, this.iChannel);
  os.writeInt32(8, this.iPosition);
  os.writeInt32(9, this.iRoomStatus);
  os.writeInt32(10, this.iStreamerLevel);
  os.writeInt32(11, this.iMP4Width);
  os.writeInt32(12, this.iMP4Height);
  os.writeString(13, this.sMP4Resource);
  os.writeInt32(14, this.iJumpType);
  os.writeString(15, this.sJumpUrl);
};
GiftEffectInfo.prototype.readFrom = function (is) {
  this.iEffectType = is.readInt32(0, false, this.iEffectType);
  this.iPropsCount = is.readInt32(1, false, this.iPropsCount);
  this.sResource = is.readString(2, false, this.sResource);
  this.iConfigType = is.readInt32(3, false, this.iConfigType);
  this.dDisplayDuration = is.readDouble(4, false, this.dDisplayDuration);
  this.lTriggerCondition = is.readInt64(5, false, this.lTriggerCondition);
  this.iEdition = is.readInt32(6, false, this.iEdition);
  this.iChannel = is.readInt32(7, false, this.iChannel);
  this.iPosition = is.readInt32(8, false, this.iPosition);
  this.iRoomStatus = is.readInt32(9, false, this.iRoomStatus);
  this.iStreamerLevel = is.readInt32(10, false, this.iStreamerLevel);
  this.iMP4Width = is.readInt32(11, false, this.iMP4Width);
  this.iMP4Height = is.readInt32(12, false, this.iMP4Height);
  this.sMP4Resource = is.readString(13, false, this.sMP4Resource);
  this.iJumpType = is.readInt32(14, false, this.iJumpType);
  this.sJumpUrl = is.readString(15, false, this.sJumpUrl);
};
Nimo.GiftEffectInfo = GiftEffectInfo;
export default GiftEffectInfo;