var _window = window,
  nimonative = _window.nimonative;
var iframeG;
function invokeJsBridge(url) {
  if (!iframeG) {
    iframeG = document.createElement('iframe');
    iframeG.src = url;
    iframeG.style.display = 'none';
    document.body.appendChild(iframeG);
  } else {
    iframeG.src = url;
  }
}

/**
 * @param {string} funcName
 * @param {*} data
 */
function callpc(funcName) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (funcName) {
    invokeJsBridge("nimo://huya.nimo.com?hyaction=callpc&funcName=".concat(funcName, "&data=").concat(encodeURIComponent(JSON.stringify(data))));
  }
}

/**
 * 设置直播间信息
 * @param {string} anchorAnnouncement 开播公告
 * @param {string} roomTheme 直播间名称
 * @param {number} roomType 开播游戏ID
 * @param {number} lcid 开播语言ID
 * @param {string} businessType 直播间类型，1是游戏直播，2是星秀直播
 */
export var setRoomInfo = function setRoomInfo(_ref) {
  var _ref$anchorAnnounceme = _ref.anchorAnnouncement,
    anchorAnnouncement = _ref$anchorAnnounceme === void 0 ? '' : _ref$anchorAnnounceme,
    _ref$roomTheme = _ref.roomTheme,
    roomTheme = _ref$roomTheme === void 0 ? '' : _ref$roomTheme,
    _ref$roomType = _ref.roomType,
    roomType = _ref$roomType === void 0 ? 0 : _ref$roomType,
    _ref$lcid = _ref.lcid,
    lcid = _ref$lcid === void 0 ? 0 : _ref$lcid,
    _ref$businessType = _ref.businessType,
    businessType = _ref$businessType === void 0 ? 0 : _ref$businessType;
  var params = {
    anchorAnnouncement: anchorAnnouncement,
    roomTheme: roomTheme,
    roomType: roomType,
    lcid: lcid,
    businessType: businessType
  };
  if (nimonative) {
    if (typeof nimonative.SetRoomInfo === 'function') {
      nimonative.SetRoomInfo(JSON.stringify(params));
    }
  } else {
    callpc('SetRoomInfo', params);
  }
};

/**
 * 调起PC浏览器
 * @param {string} url
 */
export var defaultBrowserNavigate = function defaultBrowserNavigate(url) {
  if (url) {
    if (nimonative) {
      if (typeof nimonative.DefaultBrowserNavigate === 'function') {
        nimonative.DefaultBrowserNavigate(url);
      }
    } else {
      callpc('DefaultBrowserNavigate', {
        url: url
      });
    }
  }
};