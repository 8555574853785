export default {
  namespace: "common_groupup",
  loaders: {
    "1025": function _() {
      return import("./ar.js");
    },
    "1028": function _() {
      return import("./zh.js");
    },
    "1033": function _() {
      return import("./en.js");
    },
    "1034": function _() {
      return import("./es.js");
    },
    "1041": function _() {
      return import("./ja.js");
    },
    "1046": function _() {
      return import("./pt.js");
    },
    "1049": function _() {
      return import("./ru.js");
    },
    "1054": function _() {
      return import("./th.js");
    },
    "1055": function _() {
      return import("./tr.js");
    },
    "1057": function _() {
      return import("./id.js");
    },
    "1066": function _() {
      return import("./vi.js");
    },
    "1081": function _() {
      return import("./hi.js");
    },
    "1086": function _() {
      return import("./ms.js");
    },
    "1109": function _() {
      return import("./my.js");
    },
    "1124": function _() {
      return import("./fil.js");
    }
  }
};