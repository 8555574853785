import NimoSailActivity from "../nimosailactivity";
import Taf from "@nimo-fed/taf-lib-wup";
var ShareRewardPopUpReq = function ShareRewardPopUpReq() {
  this.udbUserId = 0;
};
ShareRewardPopUpReq.prototype._clone = function () {
  return new NimoSailActivity.ShareRewardPopUpReq();
};
ShareRewardPopUpReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShareRewardPopUpReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShareRewardPopUpReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
};
ShareRewardPopUpReq.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
};
NimoSailActivity.ShareRewardPopUpReq = ShareRewardPopUpReq;
export default ShareRewardPopUpReq;