/**
 * @param {MediaQueryList} mql
 * @param {(ev: MediaQueryListEvent) => void} handleChange
 * @returns {() => void}
 */
export var addMQLEventListener = function addMQLEventListener(mql, handleChange) {
  if (mql) {
    var isError;
    try {
      mql.addEventListener('change', handleChange);
      return function () {
        mql.removeEventListener('change', handleChange);
      };
    } catch (_unused) {
      isError = true;
    }
    if (isError) {
      try {
        mql.addListener(handleChange);
        return function () {
          mql.removeListener(handleChange);
        };
      } catch (_unused2) {}
    }
  }
  return function () {};
};

/**
 * @param {string} query
 * @returns {MediaQueryList}
 */
export var tryMatchMedia = function tryMatchMedia(query) {
  try {
    return matchMedia(query);
  } catch (e) {}
  return null;
};