import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorInfo = function AnchorInfo() {
  this.lUid = 0;
  this.sLang = "";
  this.lRoomType = 0;
  this.sCountry = "";
  this.lRoomId = 0;
  this.sSystemCode = "";
  this.sDeviceModel = "";
  this.sMachineModel = "";
};
AnchorInfo.prototype._clone = function () {
  return new Nimo.AnchorInfo();
};
AnchorInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.sLang);
  os.writeInt64(2, this.lRoomType);
  os.writeString(3, this.sCountry);
  os.writeInt64(4, this.lRoomId);
  os.writeString(5, this.sSystemCode);
  os.writeString(6, this.sDeviceModel);
  os.writeString(7, this.sMachineModel);
};
AnchorInfo.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.sLang = is.readString(1, false, this.sLang);
  this.lRoomType = is.readInt64(2, false, this.lRoomType);
  this.sCountry = is.readString(3, false, this.sCountry);
  this.lRoomId = is.readInt64(4, false, this.lRoomId);
  this.sSystemCode = is.readString(5, false, this.sSystemCode);
  this.sDeviceModel = is.readString(6, false, this.sDeviceModel);
  this.sMachineModel = is.readString(7, false, this.sMachineModel);
};
Nimo.AnchorInfo = AnchorInfo;
export default AnchorInfo;