import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetPropsListByTypeReq = function GetPropsListByTypeReq() {
  this.user = new Nimo.UserId();
  this.sLang = "";
  this.sClientType = "";
  this.iPropsListType = 0;
  this.iCallType = 0;
};
GetPropsListByTypeReq.prototype._clone = function () {
  return new Nimo.GetPropsListByTypeReq();
};
GetPropsListByTypeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPropsListByTypeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPropsListByTypeReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeString(1, this.sLang);
  os.writeString(2, this.sClientType);
  os.writeInt32(3, this.iPropsListType);
  os.writeInt32(4, this.iCallType);
};
GetPropsListByTypeReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.sLang = is.readString(1, false, this.sLang);
  this.sClientType = is.readString(2, false, this.sClientType);
  this.iPropsListType = is.readInt32(3, false, this.iPropsListType);
  this.iCallType = is.readInt32(4, false, this.iCallType);
};
Nimo.GetPropsListByTypeReq = GetPropsListByTypeReq;
export default GetPropsListByTypeReq;