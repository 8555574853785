import lazy from '@client/common/components/functional/lazyLoadComp';
import { preloadParser } from '@nimo-fed/ui/es/SVGA/parser';

/**
 * use @client/components/SVGA instead
 * @deprecated
 */
var DeferredSvgaElement = lazy(function () {
  preloadParser();
  return import('./index');
}, {
  loading: false,
  forwardRef: true
});
export default DeferredSvgaElement;