import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './VideoData';
var NoticeVideoList = function NoticeVideoList() {
  this.lRoomId = 0;
  this.iVedoNums = 0;
  this.vVedioList = new Taf.Vector(new Nimo.VideoData());
  this.iRecommendCode = 0;
  this.sBizVideoID = "";
  this.iPassReview = 1;
};
NoticeVideoList.prototype._clone = function () {
  return new Nimo.NoticeVideoList();
};
NoticeVideoList.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NoticeVideoList.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NoticeVideoList.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt32(1, this.iVedoNums);
  os.writeVector(2, this.vVedioList);
  os.writeInt32(3, this.iRecommendCode);
  os.writeString(4, this.sBizVideoID);
  os.writeInt32(5, this.iPassReview);
};
NoticeVideoList.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.iVedoNums = is.readInt32(1, false, this.iVedoNums);
  this.vVedioList = is.readVector(2, false, this.vVedioList);
  this.iRecommendCode = is.readInt32(3, false, this.iRecommendCode);
  this.sBizVideoID = is.readString(4, false, this.sBizVideoID);
  this.iPassReview = is.readInt32(5, false, this.iPassReview);
};
Nimo.NoticeVideoList = NoticeVideoList;
export default NoticeVideoList;