import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var SenderInfo = function SenderInfo() {
  this.lUid = 0;
  this.sNickName = "";
  this.iGender = 0;
  this.iIsAnchor = 0;
  this.iIsManager = 0;
  this.iFanLevel = 0;
  this.iVipLevel = 0;
  this.iRoyalLevel = 0;
  this.iIsOfficial = 0;
  this.iIsFollower = 0;
  this.lUidLocal = 0;
  this.iIsVoiceMember = 0;
  this.iIsNewUser = 0;
};
SenderInfo.prototype._clone = function () {
  return new Nimo.SenderInfo();
};
SenderInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SenderInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SenderInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.sNickName);
  os.writeInt32(2, this.iGender);
  os.writeInt32(3, this.iIsAnchor);
  os.writeInt32(4, this.iIsManager);
  os.writeInt32(5, this.iFanLevel);
  os.writeInt32(6, this.iVipLevel);
  os.writeInt32(7, this.iRoyalLevel);
  os.writeInt32(8, this.iIsOfficial);
  os.writeInt32(9, this.iIsFollower);
  os.writeInt64(10, this.lUidLocal);
  os.writeInt32(11, this.iIsVoiceMember);
  os.writeInt32(12, this.iIsNewUser);
};
SenderInfo.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.sNickName = is.readString(1, false, this.sNickName);
  this.iGender = is.readInt32(2, false, this.iGender);
  this.iIsAnchor = is.readInt32(3, false, this.iIsAnchor);
  this.iIsManager = is.readInt32(4, false, this.iIsManager);
  this.iFanLevel = is.readInt32(5, false, this.iFanLevel);
  this.iVipLevel = is.readInt32(6, false, this.iVipLevel);
  this.iRoyalLevel = is.readInt32(7, false, this.iRoyalLevel);
  this.iIsOfficial = is.readInt32(8, false, this.iIsOfficial);
  this.iIsFollower = is.readInt32(9, false, this.iIsFollower);
  this.lUidLocal = is.readInt64(10, false, this.lUidLocal);
  this.iIsVoiceMember = is.readInt32(11, false, this.iIsVoiceMember);
  this.iIsNewUser = is.readInt32(12, false, this.iIsNewUser);
};
Nimo.SenderInfo = SenderInfo;
export default SenderInfo;