import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GetGiftListByIdReq = function GetGiftListByIdReq() {
  this.vGiftId = new Taf.Vector(new Taf.INT32());
  this.iGetCategory = 0;
};
GetGiftListByIdReq.prototype._clone = function () {
  return new Nimo.GetGiftListByIdReq();
};
GetGiftListByIdReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetGiftListByIdReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetGiftListByIdReq.prototype.writeTo = function (os) {
  os.writeVector(0, this.vGiftId);
  os.writeInt32(1, this.iGetCategory);
};
GetGiftListByIdReq.prototype.readFrom = function (is) {
  this.vGiftId = is.readVector(0, false, this.vGiftId);
  this.iGetCategory = is.readInt32(1, false, this.iGetCategory);
};
Nimo.GetGiftListByIdReq = GetGiftListByIdReq;
export default GetGiftListByIdReq;