import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './EmoticonInfo';
var GetEmoticonByTypeRsp = function GetEmoticonByTypeRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.mEmoji = new Taf.Map(new Taf.INT32(), new Taf.Vector(new Nimo.EmoticonInfo()));
};
GetEmoticonByTypeRsp.prototype._clone = function () {
  return new Nimo.GetEmoticonByTypeRsp();
};
GetEmoticonByTypeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEmoticonByTypeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEmoticonByTypeRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeMap(2, this.mEmoji);
};
GetEmoticonByTypeRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.mEmoji = is.readMap(2, false, this.mEmoji);
};
Nimo.GetEmoticonByTypeRsp = GetEmoticonByTypeRsp;
export default GetEmoticonByTypeRsp;