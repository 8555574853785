import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var AnchorTaskDetailReq = function AnchorTaskDetailReq() {
  this.user = new Nimo.UserId();
};
AnchorTaskDetailReq.prototype._clone = function () {
  return new Nimo.AnchorTaskDetailReq();
};
AnchorTaskDetailReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorTaskDetailReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorTaskDetailReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
};
AnchorTaskDetailReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
};
Nimo.AnchorTaskDetailReq = AnchorTaskDetailReq;
export default AnchorTaskDetailReq;