import { eventEmitter, EVENTS } from '@client/common/utils/events';
import DOMEvent from '@client/common/utils/DOMEvent';
import addEventListener from 'rc-util/lib/Dom/addEventListener';
import ua from '@nimo-fed/ua';
import { copyToClipboardByApp } from '@nimo-fed/bridge';
// const $ = document.querySelector;

// options.prefix 复制增加前缀文案
// options.suffix 复制增加后缀文案
var clipboard = function clipboard(selector) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  this.init(selector, options);
};
clipboard.prototype = {
  select: function select(element) {
    var selectedText;
    if (element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA') {
      element.focus();
      element.setSelectionRange(0, element.value.length);
      selectedText = element.value;
    } else {
      if (element.hasAttribute('contenteditable')) {
        element.focus();
      }
      var selection = window.getSelection();
      var range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);

      // ios app内设置了user-select：none，selection.toString()拿不到文案
      // 只能用range.toString()顶一下，但是这个有个缺点，隐藏的文案也会被获取到。
      // 开发的过程中发现复制出额外多的东西就自己改改代码，不要在中间塞一些css隐藏的东西啦
      selectedText = selection.toString() || (ua.isInNimoApp ? range.toString() : ''); // 只有在原本的selection获取不到同时是在app内时尝试获取rang的文本
    }
    return selectedText;
  },
  init: function init(selector) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _this = this;
    var content = '';
    var selectorDom = document.querySelector(selector);
    var copytTarget = selectorDom ? selectorDom.getAttribute('texttarget') : '';
    event.off('click', 'body', selector).on('click', 'body', selector, function (e) {
      var target = e.target.getAttribute('texttarget');
      var result = false;
      if (target) {
        try {
          content = _this.select(document.querySelector(target));
          if (ua.isInNimoApp) {
            // app需要用js桥才能实现复制到剪贴板
            copyToClipboardByApp({
              content: content
            });
            result = true;
          } else {
            result = document.execCommand('copy');
          }
        } catch (error) {}

        // if (result) {
        eventEmitter.emit(EVENTS.CLIPBOARD_COPY, {
          selector: selector,
          result: result,
          content: content
        });
        // }
      }
    });
    var remover = function remover() {};
    if (copytTarget && (options.prefix || options.suffix)) {
      remover = addEventListener(document.querySelector(copytTarget), 'copy', function (event) {
        var e = event.nativeEvent;
        e.preventDefault();
        var text = _this.select(document.querySelector(copytTarget));
        var prefix = options.prefix,
          suffix = options.suffix;
        if (typeof prefix === 'string' && prefix) {
          text = prefix + text;
        }
        if (typeof suffix === 'string' && suffix) {
          text += suffix;
        }
        if (typeof e.clipboardData === 'undefined') {
          // IE 11
          window.clipboardData.clearData();
          window.clipboardData.setData('Text', text);
        } else {
          // all other browsers
          e.clipboardData.clearData();
          e.clipboardData.setData('Text', text);
        }
      });
    }
    return remover;
  }
};
var event = new DOMEvent();
export default clipboard;