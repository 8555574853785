import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState, useCallback } from 'react';
import { install } from '@client/common/lib/install-pwa/install';
import { local } from '@client/common/utils/storage';
import nimoReport from '@client/common/services/report';
import { getCountry } from '../../utils/cookieFn';

// for dashboard only
var ENABLE_PWAINSTALL = location.hostname === DOMAINS.dashboarddomain;
export var getPromptShown = function getPromptShown() {
  return !!local.get('pwa-dash');
};
export var setPromptShown = function setPromptShown() {
  local.set('pwa-dash', '1');
};
var ctx = ENABLE_PWAINSTALL ? install({
  onAppInstalled: function onAppInstalled() {
    setPromptShown();
    nimoReport.formatAndSend('sys/install/quickapp', '系统/安装/quickapp', {
      from_: getCountry()
    });
  }
}) : null;
export var usePwaContext = function usePwaContext() {
  var _useState = useState(function () {
      return ctx ? ctx.getPromptEvent() : null;
    }),
    _useState2 = _slicedToArray(_useState, 2),
    event = _useState2[0],
    setEvent = _useState2[1];
  var _useState3 = useState(function () {
      return Boolean(ctx && !local.get('pwa-rd'));
    }),
    _useState4 = _slicedToArray(_useState3, 2),
    showRedDot = _useState4[0],
    setShowRedDot = _useState4[1];
  useEffect(function () {
    if (ctx) {
      var handlePrompt = function handlePrompt() {
        setEvent(ctx.getPromptEvent());
      };
      var handleReset = function handleReset() {
        setEvent(null);
      };
      ctx.emitter.on('prompt', handlePrompt);
      ctx.emitter.on('reset', handleReset);
      return function () {
        ctx.emitter.removeListener('prompt', handlePrompt);
        ctx.emitter.removeListener('reset', handleReset);
      };
    }
    return function () {};
  }, []);
  var prompt = useCallback(function () {
    if (event) {
      event.prompt();
      return event.userChoice;
    }
    return Promise.reject(Error('can not prompt'));
  }, [event]);
  var promptFromRedDot = useCallback(function () {
    if (showRedDot) {
      local.set('pwa-rd', '1');
      setShowRedDot(false);
    }
    prompt();
  }, [prompt, showRedDot]);
  return {
    enable: !!ctx,
    event: event,
    prompt: prompt,
    showRedDot: showRedDot,
    promptFromRedDot: promptFromRedDot
  };
};