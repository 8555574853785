import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './LiveRoomView';
import './PageControlView';
import './GameAbbr';
import './LiveRoomPKInfo';
import './SubGameItem';
var LiveChannelListView = function LiveChannelListView() {
  this.liveRoomViewList = new Taf.Vector(new NimoBuss.LiveRoomView());
  this.pageControlView = new NimoBuss.PageControlView();
  this.gameName = "";
  this.LCID = 0;
  this.bg = "";
  this.cover = "";
  this.intro = "";
  this.gameId = 0;
  this.gameAbbrList = new Taf.Vector(new NimoBuss.GameAbbr());
  this.gameTitle = "";
  this.gameDescription = "";
  this.businessType = 0;
  this.templateType = 0;
  this.liveRoomPKInfos = new Taf.Vector(new NimoBuss.LiveRoomPKInfo());
  this.subGameItemList = new Taf.Vector(new NimoBuss.SubGameItem());
  this.multiRoomType = 0;
};
LiveChannelListView.prototype._clone = function () {
  return new NimoBuss.LiveChannelListView();
};
LiveChannelListView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveChannelListView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveChannelListView.prototype.writeTo = function (os) {
  os.writeVector(0, this.liveRoomViewList);
  os.writeStruct(1, this.pageControlView);
  os.writeString(2, this.gameName);
  os.writeInt32(3, this.LCID);
  os.writeString(4, this.bg);
  os.writeString(5, this.cover);
  os.writeString(6, this.intro);
  os.writeInt32(7, this.gameId);
  os.writeVector(8, this.gameAbbrList);
  os.writeString(9, this.gameTitle);
  os.writeString(10, this.gameDescription);
  os.writeInt32(11, this.businessType);
  os.writeInt32(12, this.templateType);
  os.writeVector(13, this.liveRoomPKInfos);
  os.writeVector(14, this.subGameItemList);
  os.writeInt32(15, this.multiRoomType);
};
LiveChannelListView.prototype.readFrom = function (is) {
  this.liveRoomViewList = is.readVector(0, false, this.liveRoomViewList);
  this.pageControlView = is.readStruct(1, false, this.pageControlView);
  this.gameName = is.readString(2, false, this.gameName);
  this.LCID = is.readInt32(3, false, this.LCID);
  this.bg = is.readString(4, false, this.bg);
  this.cover = is.readString(5, false, this.cover);
  this.intro = is.readString(6, false, this.intro);
  this.gameId = is.readInt32(7, false, this.gameId);
  this.gameAbbrList = is.readVector(8, false, this.gameAbbrList);
  this.gameTitle = is.readString(9, false, this.gameTitle);
  this.gameDescription = is.readString(10, false, this.gameDescription);
  this.businessType = is.readInt32(11, false, this.businessType);
  this.templateType = is.readInt32(12, false, this.templateType);
  this.liveRoomPKInfos = is.readVector(13, false, this.liveRoomPKInfos);
  this.subGameItemList = is.readVector(14, false, this.subGameItemList);
  this.multiRoomType = is.readInt32(15, false, this.multiRoomType);
};
NimoBuss.LiveChannelListView = LiveChannelListView;
export default LiveChannelListView;