import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorTaskDetail = function AnchorTaskDetail() {
  this.iID = 0;
  this.iProgress = 0;
  this.lCompleteTime = 0;
  this.iCurrentNum = 0;
  this.iTargetNum = 0;
};
AnchorTaskDetail.prototype._clone = function () {
  return new Nimo.AnchorTaskDetail();
};
AnchorTaskDetail.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorTaskDetail.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorTaskDetail.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iID);
  os.writeInt32(1, this.iProgress);
  os.writeInt64(2, this.lCompleteTime);
  os.writeInt32(3, this.iCurrentNum);
  os.writeInt32(4, this.iTargetNum);
};
AnchorTaskDetail.prototype.readFrom = function (is) {
  this.iID = is.readInt32(0, false, this.iID);
  this.iProgress = is.readInt32(1, false, this.iProgress);
  this.lCompleteTime = is.readInt64(2, false, this.lCompleteTime);
  this.iCurrentNum = is.readInt32(3, false, this.iCurrentNum);
  this.iTargetNum = is.readInt32(4, false, this.iTargetNum);
};
Nimo.AnchorTaskDetail = AnchorTaskDetail;
export default AnchorTaskDetail;