import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './MarkReadSession';
var MarkReadBatchRsp = function MarkReadBatchRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.vSessions = new Taf.Vector(new Nimo.MarkReadSession());
};
MarkReadBatchRsp.prototype._clone = function () {
  return new Nimo.MarkReadBatchRsp();
};
MarkReadBatchRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MarkReadBatchRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MarkReadBatchRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeVector(2, this.vSessions);
};
MarkReadBatchRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.vSessions = is.readVector(2, false, this.vSessions);
};
Nimo.MarkReadBatchRsp = MarkReadBatchRsp;
export default MarkReadBatchRsp;