import DataReport from '@client/common/lib/dataReport';
var reporter = new DataReport({
  pro: DOMAINS.pasnimoweb
});
var ROUTE_KEY_MAP = {
  'live': 'liveroom',
  'liveAlias': 'liveroom',
  'clip': 'videopage'
};
function reportMiniPlayerShow(routeKey) {
  var obj = {
    eid: 'suspension_player_show',
    eid_desc: '缩略播放器_展示',
    prop: JSON.stringify({
      type_: ROUTE_KEY_MAP[routeKey] || 'other'
    })
  };
  reporter.report(obj);
}
function reportMiniPlayerClose(routeKey) {
  var obj = {
    eid: 'suspension_player_close_click',
    eid_desc: '缩略播放器_关闭按钮_点击',
    prop: JSON.stringify({
      type_: ROUTE_KEY_MAP[routeKey] || 'other'
    })
  };
  reporter.report(obj);
}
function reportMiniPlayerExpand(routeKey) {
  var obj = {
    eid: 'suspension_player_return_click',
    eid_desc: '缩略播放器_返回直播间_点击',
    prop: JSON.stringify({
      type_: ROUTE_KEY_MAP[routeKey] || 'other'
    })
  };
  reporter.report(obj);
}
function reportMiniPlayerDrag(routeKey) {
  var obj = {
    eid: 'suspension_player_drag',
    eid_desc: '缩略播放器_拖动',
    prop: JSON.stringify({
      type_: ROUTE_KEY_MAP[routeKey] || 'other'
    })
  };
  reporter.report(obj);
}
function reportMiniPlayerPauseClick(routeKey) {
  var obj = {
    eid: 'suspension_player_pause_click',
    eid_desc: '缩略播放器_暂停_点击',
    prop: JSON.stringify({
      type_: ROUTE_KEY_MAP[routeKey] || 'other'
    })
  };
  reporter.report(obj);
}
function reportMiniPlayerPlayClick(routeKey) {
  var obj = {
    eid: 'suspension_player_play_click',
    eid_desc: '缩略播放器_播放_点击',
    prop: JSON.stringify({
      type_: ROUTE_KEY_MAP[routeKey] || 'other'
    })
  };
  reporter.report(obj);
}
export { reportMiniPlayerShow, reportMiniPlayerClose, reportMiniPlayerExpand, reportMiniPlayerDrag, reportMiniPlayerPauseClick, reportMiniPlayerPlayClick };