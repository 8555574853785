import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EPropChannelType = {
  EPROP_CHANNEL_HIDE: -1,
  EPROP_CHANNEL_ALL: 0,
  EPROP_CHANNEL_ROOM: 1,
  EPROP_CHANNEL_GAME: 2,
  EPROP_CHANNEL_LANG: 3
};
Nimo.EPropChannelType = EPropChannelType;
export default EPropChannelType;