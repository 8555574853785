import NimoSailActivity from "../nimosailactivity";
import Taf from "@nimo-fed/taf-lib-wup";
import './QueryShareRewardInfo';
var QueryShareRewardRsp = function QueryShareRewardRsp() {
  this.iCode = 0;
  this.status = 0;
  this.newUserNum = 0;
  this.targetNewUserNum = 0;
  this.announcerNewUserNum = 0;
  this.targetAnnouncerNewUserNum = 0;
  this.millsSecondsEnd = 0;
  this.duringHours = 0;
  this.udbUserId = 0;
  this.rewards = new Taf.Vector(new NimoSailActivity.QueryShareRewardInfo());
  this.uniqueId = "";
};
QueryShareRewardRsp.prototype._clone = function () {
  return new NimoSailActivity.QueryShareRewardRsp();
};
QueryShareRewardRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
QueryShareRewardRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
QueryShareRewardRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt32(1, this.status);
  os.writeInt32(2, this.newUserNum);
  os.writeInt32(3, this.targetNewUserNum);
  os.writeInt32(4, this.announcerNewUserNum);
  os.writeInt32(5, this.targetAnnouncerNewUserNum);
  os.writeInt64(6, this.millsSecondsEnd);
  os.writeInt32(7, this.duringHours);
  os.writeInt64(8, this.udbUserId);
  os.writeVector(9, this.rewards);
  os.writeString(10, this.uniqueId);
};
QueryShareRewardRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.status = is.readInt32(1, false, this.status);
  this.newUserNum = is.readInt32(2, false, this.newUserNum);
  this.targetNewUserNum = is.readInt32(3, false, this.targetNewUserNum);
  this.announcerNewUserNum = is.readInt32(4, false, this.announcerNewUserNum);
  this.targetAnnouncerNewUserNum = is.readInt32(5, false, this.targetAnnouncerNewUserNum);
  this.millsSecondsEnd = is.readInt64(6, false, this.millsSecondsEnd);
  this.duringHours = is.readInt32(7, false, this.duringHours);
  this.udbUserId = is.readInt64(8, false, this.udbUserId);
  this.rewards = is.readVector(9, false, this.rewards);
  this.uniqueId = is.readString(10, false, this.uniqueId);
};
NimoSailActivity.QueryShareRewardRsp = QueryShareRewardRsp;
export default QueryShareRewardRsp;