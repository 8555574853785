import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetVIPCustomEmojiReq = function GetVIPCustomEmojiReq() {
  this.tUser = new Nimo.UserId();
};
GetVIPCustomEmojiReq.prototype._clone = function () {
  return new Nimo.GetVIPCustomEmojiReq();
};
GetVIPCustomEmojiReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetVIPCustomEmojiReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetVIPCustomEmojiReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
};
GetVIPCustomEmojiReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
};
Nimo.GetVIPCustomEmojiReq = GetVIPCustomEmojiReq;
export default GetVIPCustomEmojiReq;