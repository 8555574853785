import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { local } from '@client/common/utils/storage';
export var getDefaultSettings = function getDefaultSettings() {
  return {
    showEnterRoomMsg: true,
    showCoinGiftMsg: true,
    showFollowAnchorMsg: true,
    showGiftAnimation: true,
    isConcise: false,
    showEnterRoomBanner: true,
    enterRoomBannerSound: false,
    chat_pickme_switch: true,
    chat_enter_effect_switch: true
  };
};
var _cacheDefault;
export var getSetting = function getSetting(settings, key) {
  if (!settings) {
    if (!_cacheDefault) _cacheDefault = getDefaultSettings();
    return _cacheDefault[key];
  }
  return settings[key];
};
function getStoreKey(auId) {
  return "nimo-chatroom-msg-config-".concat(auId);
}
export function getMessageSettings(auId) {
  if (!auId) return null;
  var rawdata = local.get(getStoreKey(auId));
  if (!rawdata) return getDefaultSettings();
  var settings;
  try {
    settings = JSON.parse(rawdata);
  } catch (e) {
    // ignore error
  }
  settings = settings ? _objectSpread(_objectSpread({}, getDefaultSettings()), settings) : getDefaultSettings();
  return settings;
}
export function setMessageSettings(auId, settings) {
  if (_typeof(settings) === 'object' && settings !== null && auId) {
    local.set(getStoreKey(auId), JSON.stringify(settings));
  }
}