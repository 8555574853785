import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { UPDATE_PROFILE_LANGPKG, GET_ARTICLE_AUTH, GET_ANCHOR_LEVEL_DETAIL, GET_ANCHOR_PRIVILEGE_CONFIG_LIST, GET_VIDEO_UPLOAD_AUTH, GET_COMPANY_INFO } from './actions';
export var PENDING = 'pending'; // pending 状态

var initialState = {
  langPkg: {},
  articleAuth: PENDING,
  anchorPrivilegeConfigList: [],
  anchorLevelDetail: {},
  videoUploadAuth: PENDING
};
function profileReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case UPDATE_PROFILE_LANGPKG:
      return _objectSpread(_objectSpread({}, state), payload);
    case GET_ARTICLE_AUTH:
      return _objectSpread(_objectSpread({}, state), {}, {
        articleAuth: payload
      });
    case GET_ANCHOR_LEVEL_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        anchorLevelDetail: payload
      });
    case GET_ANCHOR_PRIVILEGE_CONFIG_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        anchorPrivilegeConfigList: payload
      });
    case GET_VIDEO_UPLOAD_AUTH:
      return _objectSpread(_objectSpread({}, state), payload);
    case GET_COMPANY_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        companyInfo: payload
      });
    default:
      return state;
  }
}
export default profileReducer;