import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Modal from './Modal';
import confirm from './confirm';
import showModal from './showModal';
import './style/index';
Modal.info = function (props) {
  var config = _objectSpread({
    type: 'info',
    iconType: 'info-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.success = function (props) {
  var config = _objectSpread({
    type: 'success',
    iconType: 'check-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.error = function (props) {
  var config = _objectSpread({
    type: 'error',
    iconType: 'cross-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.warn = function (props) {
  var config = _objectSpread({
    type: 'warning',
    iconType: 'exclamation-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.warning = Modal.warn;
Modal.confirm = function (props) {
  var config = _objectSpread({
    type: 'confirm',
    okCancel: true
  }, props);
  return confirm(config);
};
Modal.show = showModal;
export default Modal;