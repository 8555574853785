import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { loadLangPkg, normalizeLangRegistry } from '@client/common/lang';
import logger from '@client/common/utils/logger';

// action type
export var UPDATE_PROMPT = 'update_prompt';
export var UPDATE_COMMON_LANGPKG = 'update_common_langpkg';
export var UPDATE_LANG = 'update_lang';
export var GET_CLANG_LIST = 'get_clang_list';
export var UPDATE_CLANG = 'update_clang';
export var UPDATE_USER_INFO = 'update_user_info_common';
export var UPDATE_IS_LANDING_PAGE = 'update_is_landing_page';
export var UPDATE_STREAM_INFO = 'update_stream_info';
export var UPDATE_PLAYER_LOADED_DATA = 'update_player_loaded_data';
export var UPDATE_PLAYER_INTERSECTION_STATE = 'update_player_intersection_state';
export var SHOW_MINI_PLAYER = 'show_min_player';
export var UPDATE_PLAYER_STATE = 'update_player_state';
export var UPDATE_SIGNALING_STATE = 'update_signaling_state';
export var GET_DECORATION_INFO_LIST = 'get_decoration_info_list';
export var SET_MESSAGE = 'set_message';
export var SET_THEME = 'set_theme';

// action creators
// 头部及个人中心导航栏小红点提示
export function updatePrompt(type, value) {
  return {
    type: UPDATE_PROMPT,
    payload: {
      type: type,
      prompt: value < 0 ? 0 : value
    }
  };
}

// 更新common公用多语言
export var updateCommonLangPkg = function updateCommonLangPkg(id) {
  var _id = id || -1;
  return function (dispatch) {
    return Promise.all([loadLangPkg(require('@client/common/lang/header'), _id), loadLangPkg(require('@client/common/lang/error_code'), _id), loadLangPkg(require('@client/common/lang/base'), _id)]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
        header = _ref2[0],
        errorCode = _ref2[1],
        base = _ref2[2];
      dispatch({
        type: UPDATE_COMMON_LANGPKG,
        payload: {
          header: header,
          errorCode: errorCode,
          base: base
        }
      });
    });
  };
};
export var updateCommonModuleLangPkgByRegistry = function updateCommonModuleLangPkgByRegistry(registry, lang) {
  return function (dispatch) {
    var langRegistry = normalizeLangRegistry(registry);
    if (!langRegistry) {
      return Promise.reject(new Error('is not a lang registry'));
    }
    var moduleId = langRegistry.namespace.replace(/^common_/, '');
    return loadLangPkg(registry, lang).then(function (langPkg) {
      dispatch({
        type: UPDATE_COMMON_LANGPKG,
        payload: _defineProperty({}, moduleId, langPkg)
      });
    });
  };
};

// 更新语言ID
export var updateLang = function updateLang(id) {
  return {
    type: UPDATE_LANG,
    payload: id
  };
};

// 获取内容语言列表
export var getClangList = function getClangList() {
  return function (dispatch) {
    return import('@client/common/models/api-cf/configCf').then(function (_ref3) {
      var configCfModel = _ref3.configCfModel;
      return configCfModel.fetchLanguageSetting();
    }).then(function (clangList) {
      var priorityClangOrder = [1046, 1034, 1054, 1057, 1066, 1081, 1033];
      var newClang = new Array(7);
      var rest = [];
      for (var i = 0; i < clangList.length; i += 1) {
        var curClang = clangList[i];
        var priorityIndex = priorityClangOrder.indexOf(curClang.lcid);
        if (priorityIndex !== -1) {
          newClang[priorityIndex] = curClang;
        } else {
          rest.push(curClang);
        }
      }
      newClang = newClang.filter(function (cl) {
        return !!cl;
      });
      var result = [].concat(_toConsumableArray(newClang), rest);
      dispatch({
        type: GET_CLANG_LIST,
        payload: result
      });
      return result;
    });
  };
};

// 更新内容语言ID
export var updateClang = function updateClang(id) {
  return {
    type: UPDATE_CLANG,
    payload: id
  };
};
export var updateUserInfo = function updateUserInfo(info) {
  return {
    type: UPDATE_USER_INFO,
    payload: info
  };
};
export var updateIsLandingPage = function updateIsLandingPage(state) {
  return {
    type: UPDATE_IS_LANDING_PAGE,
    payload: state
  };
};
export var updateStreamInfo = function updateStreamInfo(streamInfo) {
  logger.info('UPDATE_STREAM_INFO', streamInfo);
  return {
    type: UPDATE_STREAM_INFO,
    payload: streamInfo
  };
};
export function updatePlayerLoadedData(playerLoadedData) {
  return {
    type: UPDATE_PLAYER_LOADED_DATA,
    payload: playerLoadedData
  };
}
export var updatePlayerIntersectionState = function updatePlayerIntersectionState(state) {
  return {
    type: UPDATE_PLAYER_INTERSECTION_STATE,
    payload: state
  };
};
export var showMiniPlayer = function showMiniPlayer(state) {
  return {
    type: SHOW_MINI_PLAYER,
    payload: state
  };
};
export var updatePlayerState = function updatePlayerState(state) {
  return {
    type: UPDATE_PLAYER_STATE,
    payload: state
  };
};
export function updateSignalingState(signalingState) {
  return {
    type: UPDATE_SIGNALING_STATE,
    payload: signalingState
  };
}
export function getDecorationInfoList(decorationInfoList) {
  return {
    type: GET_DECORATION_INFO_LIST,
    payload: decorationInfoList
  };
}
export function setMessage(data) {
  return {
    type: SET_MESSAGE,
    payload: {
      message: data
    }
  };
}
export function setTheme(theme) {
  return {
    type: SET_THEME,
    payload: {
      theme: theme
    }
  };
}