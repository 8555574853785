import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorPrivilegeByTypeReq = function AnchorPrivilegeByTypeReq() {
  this.lUid = 0;
  this.iType = 0;
  this.iLangID = 0;
};
AnchorPrivilegeByTypeReq.prototype._clone = function () {
  return new Nimo.AnchorPrivilegeByTypeReq();
};
AnchorPrivilegeByTypeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorPrivilegeByTypeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorPrivilegeByTypeReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeInt32(1, this.iType);
  os.writeInt32(2, this.iLangID);
};
AnchorPrivilegeByTypeReq.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.iType = is.readInt32(1, false, this.iType);
  this.iLangID = is.readInt32(2, false, this.iLangID);
};
Nimo.AnchorPrivilegeByTypeReq = AnchorPrivilegeByTypeReq;
export default AnchorPrivilegeByTypeReq;