import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ForbidMessageRecord = function ForbidMessageRecord() {
  this.lUserNimoId = 0;
  this.lUserUDBId = 0;
  this.sUserNick = "";
  this.sUserAvatarUrl = "";
  this.lRemainTime = 0;
};
ForbidMessageRecord.prototype._clone = function () {
  return new Nimo.ForbidMessageRecord();
};
ForbidMessageRecord.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ForbidMessageRecord.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ForbidMessageRecord.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUserNimoId);
  os.writeInt64(1, this.lUserUDBId);
  os.writeString(2, this.sUserNick);
  os.writeString(3, this.sUserAvatarUrl);
  os.writeInt64(4, this.lRemainTime);
};
ForbidMessageRecord.prototype.readFrom = function (is) {
  this.lUserNimoId = is.readInt64(0, false, this.lUserNimoId);
  this.lUserUDBId = is.readInt64(1, false, this.lUserUDBId);
  this.sUserNick = is.readString(2, false, this.sUserNick);
  this.sUserAvatarUrl = is.readString(3, false, this.sUserAvatarUrl);
  this.lRemainTime = is.readInt64(4, false, this.lRemainTime);
};
Nimo.ForbidMessageRecord = ForbidMessageRecord;
export default ForbidMessageRecord;