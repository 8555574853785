import Emitter from 'events';
import supportInstallPrompt from './supportInstallPrompt';
import { registerServiceWorker } from "../serviceWorker/window";
export { supportInstallPrompt };
var cachedCtx;
var promptEvent;

/**
 * @param {{ onAppInstalled: function }} [options]
 */
export function install(options) {
  var opts = options || {};
  if (cachedCtx) return cachedCtx;
  if (supportInstallPrompt()) {
    var emitter = new Emitter();
    registerServiceWorker();
    window.addEventListener('beforeinstallprompt', function (event) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      promptEvent = event;
      promptEvent.userChoice.then(function () {
        cachedCtx.reset();
      });
      emitter.emit('prompt');
    });
    window.addEventListener('appinstalled', function (event) {
      if (promptEvent) {
        cachedCtx.reset();
      }
      if (opts.onAppInstalled) {
        opts.onAppInstalled(event);
      }
    });
    cachedCtx = {
      emitter: emitter,
      getPromptEvent: function getPromptEvent() {
        return promptEvent;
      },
      reset: function reset() {
        promptEvent = null;
        emitter.emit('reset');
      }
    };
    return cachedCtx;
  } else {
    return null;
  }
}