import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var QueryUserInRoomRsp = function QueryUserInRoomRsp() {
  this.bInRoom = 0;
  this.lPid = 0;
};
QueryUserInRoomRsp.prototype._clone = function () {
  return new Nimo.QueryUserInRoomRsp();
};
QueryUserInRoomRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
QueryUserInRoomRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
QueryUserInRoomRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.bInRoom);
  os.writeInt64(1, this.lPid);
};
QueryUserInRoomRsp.prototype.readFrom = function (is) {
  this.bInRoom = is.readInt32(0, false, this.bInRoom);
  this.lPid = is.readInt64(1, false, this.lPid);
};
Nimo.QueryUserInRoomRsp = QueryUserInRoomRsp;
export default QueryUserInRoomRsp;