import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetRoomManagerListReq = function GetRoomManagerListReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.iPageSize = 0;
  this.iPage = 0;
  this.iType = 0;
};
GetRoomManagerListReq.prototype._clone = function () {
  return new Nimo.GetRoomManagerListReq();
};
GetRoomManagerListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRoomManagerListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRoomManagerListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeInt32(2, this.iPageSize);
  os.writeInt32(3, this.iPage);
  os.writeInt32(4, this.iType);
};
GetRoomManagerListReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.iPageSize = is.readInt32(2, false, this.iPageSize);
  this.iPage = is.readInt32(3, false, this.iPage);
  this.iType = is.readInt32(4, false, this.iType);
};
Nimo.GetRoomManagerListReq = GetRoomManagerListReq;
export default GetRoomManagerListReq;