import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
import './GiftEffectID';
var GiftConsumeReq = function GiftConsumeReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.lPresenterUid = 0;
  this.iItemType = 0;
  this.iItemCount = 0;
  this.sPayId = "";
  this.sSendContent = "";
  this.sSenderNick = "";
  this.sPresenterNick = "";
  this.iPayType = 0;
  this.iFromType = 0;
  this.sGameId = "";
  this.sMid = "";
  this.sSenderAvatarUrl = "";
  this.iChannelType = 0;
  this.lRoomType = 0;
  this.iLcid = 0;
  this.sAlise = "";
  this.sPresenterUrl = "";
  this.sPresenterCountry = "";
  this.sSenderCountry = "";
  this.iActivityId = 0;
  this.iJavaGiftType = 0;
  this.iConsumeSource = 0;
  this.iVersion = 0;
  this.lRecipientId = 0;
  this.mExtParam = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.tPreferGiftEffectID = new Nimo.GiftEffectID();
  this.vRecipientId = new Taf.Vector(new Taf.INT64());
};
GiftConsumeReq.prototype._clone = function () {
  return new Nimo.GiftConsumeReq();
};
GiftConsumeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftConsumeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftConsumeReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lPresenterUid);
  os.writeInt32(3, this.iItemType);
  os.writeInt32(4, this.iItemCount);
  os.writeString(5, this.sPayId);
  os.writeString(6, this.sSendContent);
  os.writeString(7, this.sSenderNick);
  os.writeString(8, this.sPresenterNick);
  os.writeInt32(9, this.iPayType);
  os.writeInt32(10, this.iFromType);
  os.writeString(11, this.sGameId);
  os.writeString(12, this.sMid);
  os.writeString(13, this.sSenderAvatarUrl);
  os.writeInt32(14, this.iChannelType);
  os.writeInt64(15, this.lRoomType);
  os.writeInt32(16, this.iLcid);
  os.writeString(17, this.sAlise);
  os.writeString(18, this.sPresenterUrl);
  os.writeString(19, this.sPresenterCountry);
  os.writeString(20, this.sSenderCountry);
  os.writeInt32(21, this.iActivityId);
  os.writeInt32(22, this.iJavaGiftType);
  os.writeInt32(23, this.iConsumeSource);
  os.writeInt32(24, this.iVersion);
  os.writeInt64(25, this.lRecipientId);
  os.writeMap(26, this.mExtParam);
  os.writeStruct(27, this.tPreferGiftEffectID);
  os.writeVector(28, this.vRecipientId);
};
GiftConsumeReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lPresenterUid = is.readInt64(2, false, this.lPresenterUid);
  this.iItemType = is.readInt32(3, false, this.iItemType);
  this.iItemCount = is.readInt32(4, false, this.iItemCount);
  this.sPayId = is.readString(5, false, this.sPayId);
  this.sSendContent = is.readString(6, false, this.sSendContent);
  this.sSenderNick = is.readString(7, false, this.sSenderNick);
  this.sPresenterNick = is.readString(8, false, this.sPresenterNick);
  this.iPayType = is.readInt32(9, false, this.iPayType);
  this.iFromType = is.readInt32(10, false, this.iFromType);
  this.sGameId = is.readString(11, false, this.sGameId);
  this.sMid = is.readString(12, false, this.sMid);
  this.sSenderAvatarUrl = is.readString(13, false, this.sSenderAvatarUrl);
  this.iChannelType = is.readInt32(14, false, this.iChannelType);
  this.lRoomType = is.readInt64(15, false, this.lRoomType);
  this.iLcid = is.readInt32(16, false, this.iLcid);
  this.sAlise = is.readString(17, false, this.sAlise);
  this.sPresenterUrl = is.readString(18, false, this.sPresenterUrl);
  this.sPresenterCountry = is.readString(19, false, this.sPresenterCountry);
  this.sSenderCountry = is.readString(20, false, this.sSenderCountry);
  this.iActivityId = is.readInt32(21, false, this.iActivityId);
  this.iJavaGiftType = is.readInt32(22, false, this.iJavaGiftType);
  this.iConsumeSource = is.readInt32(23, false, this.iConsumeSource);
  this.iVersion = is.readInt32(24, false, this.iVersion);
  this.lRecipientId = is.readInt64(25, false, this.lRecipientId);
  this.mExtParam = is.readMap(26, false, this.mExtParam);
  this.tPreferGiftEffectID = is.readStruct(27, false, this.tPreferGiftEffectID);
  this.vRecipientId = is.readVector(28, false, this.vRecipientId);
};
Nimo.GiftConsumeReq = GiftConsumeReq;
export default GiftConsumeReq;