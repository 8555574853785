import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './DashBoardRecommendConfig';
var GetDashBoardRecommendConfigRsp = function GetDashBoardRecommendConfigRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.vConfig = new Taf.Map(new Taf.INT32(), new Nimo.DashBoardRecommendConfig());
};
GetDashBoardRecommendConfigRsp.prototype._clone = function () {
  return new Nimo.GetDashBoardRecommendConfigRsp();
};
GetDashBoardRecommendConfigRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetDashBoardRecommendConfigRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetDashBoardRecommendConfigRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeMap(2, this.vConfig);
};
GetDashBoardRecommendConfigRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.vConfig = is.readMap(2, false, this.vConfig);
};
Nimo.GetDashBoardRecommendConfigRsp = GetDashBoardRecommendConfigRsp;
export default GetDashBoardRecommendConfigRsp;