import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GiftResource';
import './GiftPlay';
var GiftItem = function GiftItem() {
  this.iGiftId = 0;
  this.sGiftName = "";
  this.sGiftDesc = "";
  this.iCostDiamond = 0;
  this.iCostCoin = 0;
  this.vSelectNum = new Taf.Vector(new Taf.INT32());
  this.iWeight = 0;
  this.iMaxNum = 0;
  this.tGiftResource = new Nimo.GiftResource();
  this.tGiftPlay = new Nimo.GiftPlay();
  this.iShowType = 0;
  this.bIsNew = true;
  this.vGiftPlay = new Taf.Vector(new Nimo.GiftPlay());
  this.iSpecialId = 0;
  this.iCostBean = 0;
  this.mExtraData = new Taf.Map(new Taf.INT32(), new Taf.BinBuffer());
  this.iPayType = 0;
  this.iGiftPrice = 0;
  this.iSubScript = 0;
  this.lAvailableStartTime = 0;
  this.lAvailableEndTime = 0;
  this.lAvailableRemainSec = 0;
  this.iTimeShelf = 0;
  this.iFCoin = 0;
  this.lCustomizeUID = 0;
  this.sCustomizeNick = "";
  this.sCustomizeAvatar = "";
  this.dExchangeMoney = 0;
  this.mGiftShowDescription = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sDescriptionID = "";
  this.lTitleUID = 0;
  this.sTitleNick = "";
  this.sTitleAvatar = "";
  this.iCrossRoom = 0;
};
GiftItem.prototype._clone = function () {
  return new Nimo.GiftItem();
};
GiftItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftItem.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iGiftId);
  os.writeString(1, this.sGiftName);
  os.writeString(2, this.sGiftDesc);
  os.writeInt32(3, this.iCostDiamond);
  os.writeInt32(4, this.iCostCoin);
  os.writeVector(5, this.vSelectNum);
  os.writeInt32(6, this.iWeight);
  os.writeInt32(7, this.iMaxNum);
  os.writeStruct(8, this.tGiftResource);
  os.writeStruct(9, this.tGiftPlay);
  os.writeInt32(10, this.iShowType);
  os.writeBoolean(11, this.bIsNew);
  os.writeVector(12, this.vGiftPlay);
  os.writeInt32(13, this.iSpecialId);
  os.writeInt32(14, this.iCostBean);
  os.writeMap(15, this.mExtraData);
  os.writeInt32(16, this.iPayType);
  os.writeInt32(17, this.iGiftPrice);
  os.writeInt32(18, this.iSubScript);
  os.writeInt64(19, this.lAvailableStartTime);
  os.writeInt64(20, this.lAvailableEndTime);
  os.writeInt64(21, this.lAvailableRemainSec);
  os.writeInt32(22, this.iTimeShelf);
  os.writeInt32(23, this.iFCoin);
  os.writeInt64(24, this.lCustomizeUID);
  os.writeString(25, this.sCustomizeNick);
  os.writeString(26, this.sCustomizeAvatar);
  os.writeDouble(27, this.dExchangeMoney);
  os.writeMap(28, this.mGiftShowDescription);
  os.writeString(29, this.sDescriptionID);
  os.writeInt64(30, this.lTitleUID);
  os.writeString(31, this.sTitleNick);
  os.writeString(32, this.sTitleAvatar);
  os.writeInt32(33, this.iCrossRoom);
};
GiftItem.prototype.readFrom = function (is) {
  this.iGiftId = is.readInt32(0, false, this.iGiftId);
  this.sGiftName = is.readString(1, false, this.sGiftName);
  this.sGiftDesc = is.readString(2, false, this.sGiftDesc);
  this.iCostDiamond = is.readInt32(3, false, this.iCostDiamond);
  this.iCostCoin = is.readInt32(4, false, this.iCostCoin);
  this.vSelectNum = is.readVector(5, false, this.vSelectNum);
  this.iWeight = is.readInt32(6, false, this.iWeight);
  this.iMaxNum = is.readInt32(7, false, this.iMaxNum);
  this.tGiftResource = is.readStruct(8, false, this.tGiftResource);
  this.tGiftPlay = is.readStruct(9, false, this.tGiftPlay);
  this.iShowType = is.readInt32(10, false, this.iShowType);
  this.bIsNew = is.readBoolean(11, false, this.bIsNew);
  this.vGiftPlay = is.readVector(12, false, this.vGiftPlay);
  this.iSpecialId = is.readInt32(13, false, this.iSpecialId);
  this.iCostBean = is.readInt32(14, false, this.iCostBean);
  this.mExtraData = is.readMap(15, false, this.mExtraData);
  this.iPayType = is.readInt32(16, false, this.iPayType);
  this.iGiftPrice = is.readInt32(17, false, this.iGiftPrice);
  this.iSubScript = is.readInt32(18, false, this.iSubScript);
  this.lAvailableStartTime = is.readInt64(19, false, this.lAvailableStartTime);
  this.lAvailableEndTime = is.readInt64(20, false, this.lAvailableEndTime);
  this.lAvailableRemainSec = is.readInt64(21, false, this.lAvailableRemainSec);
  this.iTimeShelf = is.readInt32(22, false, this.iTimeShelf);
  this.iFCoin = is.readInt32(23, false, this.iFCoin);
  this.lCustomizeUID = is.readInt64(24, false, this.lCustomizeUID);
  this.sCustomizeNick = is.readString(25, false, this.sCustomizeNick);
  this.sCustomizeAvatar = is.readString(26, false, this.sCustomizeAvatar);
  this.dExchangeMoney = is.readDouble(27, false, this.dExchangeMoney);
  this.mGiftShowDescription = is.readMap(28, false, this.mGiftShowDescription);
  this.sDescriptionID = is.readString(29, false, this.sDescriptionID);
  this.lTitleUID = is.readInt64(30, false, this.lTitleUID);
  this.sTitleNick = is.readString(31, false, this.sTitleNick);
  this.sTitleAvatar = is.readString(32, false, this.sTitleAvatar);
  this.iCrossRoom = is.readInt32(33, false, this.iCrossRoom);
};
Nimo.GiftItem = GiftItem;
export default GiftItem;