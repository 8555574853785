import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EMsgGroupAuthType = {
  E_MSGGROUP_AUTH_ALL: 0,
  E_MSGGROUP_AUTH_NOT_JOIN: 1,
  E_MSGGROUP_AUTH_NOT_LEAVE: 2,
  E_MSGGROUP_AUTH_NOT_KICK: 4,
  E_MSGGROUP_AUTH_NOT_SHARE: 8,
  E_MSGGROUP_AUTH_NOT_ADD: 16
};
Nimo.EMsgGroupAuthType = EMsgGroupAuthType;
export default EMsgGroupAuthType;