import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './RoomLineInfo';
var WS_StreamNew = function WS_StreamNew() {
  this.lRoomId = 0;
  this.roomLine = new Nimo.RoomLineInfo();
};
WS_StreamNew.prototype._clone = function () {
  return new Nimo.WS_StreamNew();
};
WS_StreamNew.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WS_StreamNew.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WS_StreamNew.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeStruct(1, this.roomLine);
};
WS_StreamNew.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.roomLine = is.readStruct(1, false, this.roomLine);
};
Nimo.WS_StreamNew = WS_StreamNew;
export default WS_StreamNew;