/**
 * 路由路径配置
 * index 模块主页面路由
 * langKey: t => title, d => description lang key
 * nav 模块对应头部导航 active key
 * bodyClass 页面 body class 切换路由时自动更新
 * langModules 页面多语言模块名 切换路由自动加载更新 redux
 */
export var routePathConf = {
  index: {
    index: '/',
    langKey: {
      t: 'titles.index'
      // d: 'descriptions.index'
    },
    nav: 'home',
    bodyClass: 'page-index nimo-page-with-footer',
    langModules: [require('@client/common/lang/player')],
    layout: {
      footer: true,
      floatNavigator: true,
      player: true
    }
  },
  indexRedir: {
    // 兼容php路由
    createReg: function createReg() {
      return /^\/index(?:\.(?:html|php))?$/;
    },
    langKey: {
      t: 'titles.index'
      // d: 'descriptions.index'
    },
    bodyClass: 'page-index nimo-page-with-footer',
    langModules: [require('@client/common/lang/player')],
    layout: {
      footer: true,
      floatNavigator: true
    }
  },
  lives: {
    index: '/lives',
    langKey: {
      t: 'titles.lives'
      // d: 'descriptions.lives'
    },
    nav: 'lives',
    bodyClass: 'page-lives',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  games: {
    index: '/games',
    langKey: {
      t: 'titles.games'
      // d: 'descriptions.games'
    },
    nav: 'category',
    bodyClass: 'page-games',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  list: {
    index: '/list/:id',
    langKey: {
      // 需要页面内获取完数据才能进行替换，故此处继续使用默认值
      t: 'titles.index'
      // d: 'descriptions.index'
    },
    nav: 'lives',
    bodyClass: 'page-list',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  game: {
    index: '/game/:id',
    langKey: {
      // 需要页面内获取完数据才能进行替换，故此处继续使用默认值
      t: 'titles.index'
      // d: 'descriptions.index'
    },
    nav: 'category',
    navSwitch: [{
      path: '/game/185',
      nav: 'liveshow'
    }],
    bodyClass: 'page-game',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  search: {
    index: '/search',
    // notes: 若search路由有调整，需及时调整frontend/common/copyPhp/static_by_env/xx/nms/search/opensearch.xml 文件
    langKey: {
      t: 'titles.index'
      // d: 'descriptions.index'
    },
    nav: 'category',
    bodyClass: 'nimo-page-with-footer',
    layout: {
      footer: true
    }
  },
  match: {
    index: '/esports/all',
    langKey: {
      t: 'match_all_seo'
      // d: 'descriptions.match'
    },
    nav: 'lives',
    bodyClass: 'nimo-page-with-footer page-match-all',
    layout: {
      footer: true
    }
  },
  ranks: {
    index: '/ranks',
    langKey: {
      t: 'ranks_seo'
      // d: 'descriptions.ranks'
    },
    nav: 'ranks',
    bodyClass: 'page-ranks',
    langModules: [require('@client/common/lang/rank')],
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  halloffame: {
    index: '/halloffame',
    nav: 'halloffame',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  family: {
    index: '/fam/home',
    nav: 'fam',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  homePath: {
    index: '/fam/home/:familyId',
    needLogin: false,
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  member: {
    index: '/fam/member',
    nav: 'fam',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  delete: {
    index: '/fam/member/delete',
    nav: 'fam',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  review: {
    index: '/fam/member/review',
    nav: 'fam',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  familylist: {
    index: '/fam/list',
    nav: 'fam',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  familycreate: {
    index: '/fam/create',
    nav: 'fam',
    layout: {
      sideBar: true,
      floatNavigator: true
    }
  },
  channel: {
    index: '/c/:id',
    bodyClass: 'page-channel',
    langKey: {},
    layout: {
      sideBar: true,
      floatNavigator: false
    }
  },
  user: {
    // NOTE: 全局播放器用到 routeKey === 'user' 做判断，如需更改，确保正确
    index: '/user/:roomId',
    langKey: {
      t: 'titles.user'
      // d: 'descriptions.user'
    },
    bodyClass: 'page-user',
    layout: {
      sideBar: true,
      floatNavigator: false
    }
  },
  article: {
    index: '/a/:id',
    langKey: {
      t: 'titles.index'
      // d: 'descriptions.index'
    },
    bodyClass: 'page-article',
    layout: {
      sideBar: true,
      floatNavigator: false
    }
  },
  clipCreate: {
    index: '/clip/create',
    bodyClass: 'page-clip-create',
    langKey: {},
    nav: '',
    langModules: [require('@client/common/lang/clip')],
    layout: {
      sideBar: false,
      footer: false
    }
  },
  clip: {
    index: '/(clip|v)/:vid',
    bodyClass: 'nimo-page-with-footer page-clip',
    langKey: {},
    nav: '',
    langModules: [require('@client/common/lang/clip')],
    layout: {
      sideBar: true,
      footer: true
    }
  },
  live: {
    index: '/live/:roomId',
    langKey: {},
    bodyClass: 'page-room',
    layout: {
      sideBar: true,
      player: true
    },
    isLiveRoom: true
  },
  matches: {
    index: '/esports',
    createReg: function createReg() {
      return /^\/esports(?:\/game\/(\d|[a-zA-Z])+)?\/?$/;
    },
    nav: 'matches',
    bodyClass: 'page-match-center',
    langKey: {},
    layout: {
      sideBar: true,
      footer: false
    }
  },
  mall: {
    index: '/mall',
    bodyClass: 'page-mall',
    langKey: {},
    layout: {
      sideBar: false,
      footer: false,
      hideHeader: true
    }
  },
  mallRechangeRecord: {
    index: '/mall/exchange-record',
    bodyClass: 'page-mall',
    langKey: {},
    layout: {
      sideBar: false,
      footer: false,
      hideHeader: true
    }
  },
  gameCenter: {
    index: '/game-center',
    nav: 'gameCenter',
    layout: {
      sideBar: true,
      footer: false,
      hideHeader: false
    }
  },
  liveAlias: {
    index: '/:alias',
    createReg: function createReg() {
      return /^(?=.*[A-Za-z])\/[A-Za-z\d_-]{3,}\/?$/;
    },
    langKey: {},
    bodyClass: 'page-room',
    layout: {
      sideBar: true,
      player: true // 页面是否有播放器，影响侧边栏等的加载时机
    },
    isLiveRoom: true
  },
  // clanHome: {
  //   index: '/clan/:id',
  //   langKey: {
  //     d: 'descriptions.index'
  //   },
  //   bodyClass: 'page-clan-home nimo-page-with-footer',
  //   layout: {
  //     sideBar: false,
  //     footer: true,
  //     hideHeader: false,
  //     floatNavigator: {
  //       onClick: clanHomeFloatNavReport,
  //       btns: [
  //         NAV_BTNS.BACK_HOME,
  //         NAV_BTNS.DOWANLOAD_APP,
  //         NAV_BTNS.GO_LIVE,
  //         NAV_BTNS.BACK_TOP
  //       ]
  //     }
  //   }
  // },
  mktLives: {
    index: '/mkt/:actId/lives',
    bodyClass: 'page-room',
    langKey: {},
    layout: {
      sideBar: false,
      footer: false,
      hideHeader: true
    }
  },
  mktLive: {
    index: '/mkt/:actId/live/:roomId',
    bodyClass: 'page-room',
    langKey: {},
    layout: {
      sideBar: false,
      footer: false,
      hideHeader: true
    }
  },
  notFound: {
    index: '',
    createReg: function createReg() {
      return /.*/;
    },
    langKey: {},
    bodyClass: 'nimo-page-with-footer',
    layout: {
      footer: true
    }
  }
};
var createReg = function createReg(path, customCreateReg) {
  if (typeof customCreateReg === 'function') {
    return customCreateReg();
  } else if (typeof path === 'string') {
    return new RegExp("^".concat(path.replace(/:[\w-_]+(?=\/?)/g, function () {
      return '[\\w-_]+';
    }), "(\\/)?$"));
  } else {
    return null;
  }
};
export var getCurRoutePath = function getCurRoutePath(pathname) {
  return Object.values(routePathConf).find(function (route) {
    return pathname.match(createReg(route.index, route.createReg));
  });
};
export var getCurRouteKey = function getCurRouteKey(pathname) {
  return Object.keys(routePathConf).find(function (routeKey) {
    var route = routePathConf[routeKey] || {};
    return pathname.match(createReg(route.index, route.createReg));
  });
};
export var getNav = function getNav(pathname) {
  var routeConf = getCurRoutePath(pathname);
  if (routeConf) {
    var defaultNav = routeConf.nav || '';
    if (Array.isArray(routeConf.navSwitch)) {
      var navItem = routeConf.navSwitch.find(function (item) {
        return pathname.match(createReg(item.path));
      });
      if (navItem) defaultNav = navItem.nav;
    }
    return defaultNav;
  }
  return '';
};