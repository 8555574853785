import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var SetUserProfileRsp = function SetUserProfileRsp() {
  this.iCode = 0;
  this.mUserProfile = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
SetUserProfileRsp.prototype._clone = function () {
  return new Nimo.SetUserProfileRsp();
};
SetUserProfileRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SetUserProfileRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SetUserProfileRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeMap(1, this.mUserProfile);
};
SetUserProfileRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.mUserProfile = is.readMap(1, false, this.mUserProfile);
};
Nimo.SetUserProfileRsp = SetUserProfileRsp;
export default SetUserProfileRsp;