import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GiftResource';
import './GiftEffect';
import './GiftChannel';
import './StickerEffect';
var SpecialGiftItem = function SpecialGiftItem() {
  this.iConfigId = 0;
  this.iType = 0;
  this.vDataByType = new Taf.Vector(new Taf.STRING());
  this.iGiftId = 0;
  this.mNameLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mDescLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mDynamicResource = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.tGiftResource = new Nimo.GiftResource();
  this.mGiftEffect = new Taf.Map(new Taf.STRING(), new Nimo.GiftEffect());
  this.vGiftChannel = new Taf.Vector(new Nimo.GiftChannel());
  this.lUpdateTime = 0;
  this.lCreateTime = 0;
  this.mStickerEffect = new Taf.Map(new Taf.STRING(), new Nimo.StickerEffect());
  this.iSubScript = 0;
  this.lAvailableStartTime = 0;
  this.lAvailableEndTime = 0;
  this.lAvailableRemainSec = 0;
  this.iTimeShelf = 0;
  this.lTitleUID = 0;
  this.sTitleNick = "";
  this.sTitleAvatar = "";
};
SpecialGiftItem.prototype._clone = function () {
  return new Nimo.SpecialGiftItem();
};
SpecialGiftItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SpecialGiftItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SpecialGiftItem.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iConfigId);
  os.writeInt32(1, this.iType);
  os.writeVector(2, this.vDataByType);
  os.writeInt32(3, this.iGiftId);
  os.writeMap(4, this.mNameLang);
  os.writeMap(5, this.mDescLang);
  os.writeMap(6, this.mDynamicResource);
  os.writeStruct(7, this.tGiftResource);
  os.writeMap(8, this.mGiftEffect);
  os.writeVector(9, this.vGiftChannel);
  os.writeInt64(10, this.lUpdateTime);
  os.writeInt64(11, this.lCreateTime);
  os.writeMap(12, this.mStickerEffect);
  os.writeInt32(13, this.iSubScript);
  os.writeInt64(14, this.lAvailableStartTime);
  os.writeInt64(15, this.lAvailableEndTime);
  os.writeInt64(16, this.lAvailableRemainSec);
  os.writeInt32(17, this.iTimeShelf);
  os.writeInt64(18, this.lTitleUID);
  os.writeString(19, this.sTitleNick);
  os.writeString(20, this.sTitleAvatar);
};
SpecialGiftItem.prototype.readFrom = function (is) {
  this.iConfigId = is.readInt32(0, false, this.iConfigId);
  this.iType = is.readInt32(1, false, this.iType);
  this.vDataByType = is.readVector(2, false, this.vDataByType);
  this.iGiftId = is.readInt32(3, false, this.iGiftId);
  this.mNameLang = is.readMap(4, false, this.mNameLang);
  this.mDescLang = is.readMap(5, false, this.mDescLang);
  this.mDynamicResource = is.readMap(6, false, this.mDynamicResource);
  this.tGiftResource = is.readStruct(7, false, this.tGiftResource);
  this.mGiftEffect = is.readMap(8, false, this.mGiftEffect);
  this.vGiftChannel = is.readVector(9, false, this.vGiftChannel);
  this.lUpdateTime = is.readInt64(10, false, this.lUpdateTime);
  this.lCreateTime = is.readInt64(11, false, this.lCreateTime);
  this.mStickerEffect = is.readMap(12, false, this.mStickerEffect);
  this.iSubScript = is.readInt32(13, false, this.iSubScript);
  this.lAvailableStartTime = is.readInt64(14, false, this.lAvailableStartTime);
  this.lAvailableEndTime = is.readInt64(15, false, this.lAvailableEndTime);
  this.lAvailableRemainSec = is.readInt64(16, false, this.lAvailableRemainSec);
  this.iTimeShelf = is.readInt32(17, false, this.iTimeShelf);
  this.lTitleUID = is.readInt64(18, false, this.lTitleUID);
  this.sTitleNick = is.readString(19, false, this.sTitleNick);
  this.sTitleAvatar = is.readString(20, false, this.sTitleAvatar);
};
Nimo.SpecialGiftItem = SpecialGiftItem;
export default SpecialGiftItem;