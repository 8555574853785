import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var UserTitleData = function UserTitleData() {
  this.lUid = 0;
  this.iBgColor = "";
  this.iTextColor = "";
  this.sIcon = "";
  this.sTitleDesc = "";
  this.sBusinessCardIcon = "";
  this.lStartTime = 0;
  this.lEndTime = 0;
  this.sName = "";
  this.sContent = "";
};
UserTitleData.prototype._clone = function () {
  return new Nimo.UserTitleData();
};
UserTitleData.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserTitleData.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserTitleData.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.iBgColor);
  os.writeString(2, this.iTextColor);
  os.writeString(3, this.sIcon);
  os.writeString(4, this.sTitleDesc);
  os.writeString(5, this.sBusinessCardIcon);
  os.writeInt64(6, this.lStartTime);
  os.writeInt64(7, this.lEndTime);
  os.writeString(8, this.sName);
  os.writeString(9, this.sContent);
};
UserTitleData.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.iBgColor = is.readString(1, false, this.iBgColor);
  this.iTextColor = is.readString(2, false, this.iTextColor);
  this.sIcon = is.readString(3, false, this.sIcon);
  this.sTitleDesc = is.readString(4, false, this.sTitleDesc);
  this.sBusinessCardIcon = is.readString(5, false, this.sBusinessCardIcon);
  this.lStartTime = is.readInt64(6, false, this.lStartTime);
  this.lEndTime = is.readInt64(7, false, this.lEndTime);
  this.sName = is.readString(8, false, this.sName);
  this.sContent = is.readString(9, false, this.sContent);
};
Nimo.UserTitleData = UserTitleData;
export default UserTitleData;