import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './LiveInfo';
var LiveEndNotice = function LiveEndNotice() {
  this.tLiveInfo = new Nimo.LiveInfo();
  this.iType = 0;
};
LiveEndNotice.prototype._clone = function () {
  return new Nimo.LiveEndNotice();
};
LiveEndNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveEndNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveEndNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tLiveInfo);
  os.writeInt32(1, this.iType);
};
LiveEndNotice.prototype.readFrom = function (is) {
  this.tLiveInfo = is.readStruct(0, false, this.tLiveInfo);
  this.iType = is.readInt32(1, false, this.iType);
};
Nimo.LiveEndNotice = LiveEndNotice;
export default LiveEndNotice;