import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetMessageBannedUserReq = function GetMessageBannedUserReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.lUserNimoId = 0;
  this.lUserUDBId = 0;
  this.iPageSize = 0;
  this.iCurrentPage = 0;
};
GetMessageBannedUserReq.prototype._clone = function () {
  return new Nimo.GetMessageBannedUserReq();
};
GetMessageBannedUserReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMessageBannedUserReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMessageBannedUserReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lUserNimoId);
  os.writeInt64(3, this.lUserUDBId);
  os.writeInt32(4, this.iPageSize);
  os.writeInt32(5, this.iCurrentPage);
};
GetMessageBannedUserReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lUserNimoId = is.readInt64(2, false, this.lUserNimoId);
  this.lUserUDBId = is.readInt64(3, false, this.lUserUDBId);
  this.iPageSize = is.readInt32(4, false, this.iPageSize);
  this.iCurrentPage = is.readInt32(5, false, this.iCurrentPage);
};
Nimo.GetMessageBannedUserReq = GetMessageBannedUserReq;
export default GetMessageBannedUserReq;