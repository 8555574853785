import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EmoticonType = {
  EMOTICON_TYPE_COMMON: 1,
  EMOTICON_TYPE_FANS: 2,
  EMOTICON_TYPE_VIP_CUSTOM: 3,
  EMOTICON_TYPE_VIP: 4,
  EMOTICON_TYPE_PLATFORM_PAY: 5,
  EMOTICON_TYPE_CUSTOM_PAY: 6,
  EMOTICON_TYPE_GAME_PLAY: 7,
  EMOTICON_TYPE_USERL_LEVEL: 8,
  EMOTICON_TYPE_MAX: 62
};
Nimo.EmoticonType = EmoticonType;
export default EmoticonType;