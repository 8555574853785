import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './LiveRoomView';
import './PageControlView';
import './RecommendGameEntity';
var LiveRoomListForWebPageView = function LiveRoomListForWebPageView() {
  this.liveRoomViewList = new Taf.Vector(new NimoBuss.LiveRoomView());
  this.pageControlView = new NimoBuss.PageControlView();
  this.gameEntityList = new Taf.Vector(new NimoBuss.RecommendGameEntity());
  this.lcid = 0;
};
LiveRoomListForWebPageView.prototype._clone = function () {
  return new NimoBuss.LiveRoomListForWebPageView();
};
LiveRoomListForWebPageView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveRoomListForWebPageView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveRoomListForWebPageView.prototype.writeTo = function (os) {
  os.writeVector(0, this.liveRoomViewList);
  os.writeStruct(1, this.pageControlView);
  os.writeVector(2, this.gameEntityList);
  os.writeInt32(3, this.lcid);
};
LiveRoomListForWebPageView.prototype.readFrom = function (is) {
  this.liveRoomViewList = is.readVector(0, false, this.liveRoomViewList);
  this.pageControlView = is.readStruct(1, false, this.pageControlView);
  this.gameEntityList = is.readVector(2, false, this.gameEntityList);
  this.lcid = is.readInt32(3, false, this.lcid);
};
NimoBuss.LiveRoomListForWebPageView = LiveRoomListForWebPageView;
export default LiveRoomListForWebPageView;