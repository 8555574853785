import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var GetChatRuleReq = function GetChatRuleReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.udbUserId = 0;
};
GetChatRuleReq.prototype._clone = function () {
  return new NimoBuss.GetChatRuleReq();
};
GetChatRuleReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetChatRuleReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetChatRuleReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.udbUserId);
};
GetChatRuleReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.udbUserId = is.readInt64(1, false, this.udbUserId);
};
NimoBuss.GetChatRuleReq = GetChatRuleReq;
export default GetChatRuleReq;