import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var ActivityLabelView = function ActivityLabelView() {
  this.type = 0;
  this.icon = "";
  this.text = "";
  this.backgroundUrl = "";
  this.imageUrl = "";
};
ActivityLabelView.prototype._clone = function () {
  return new NimoBuss.ActivityLabelView();
};
ActivityLabelView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ActivityLabelView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ActivityLabelView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.type);
  os.writeString(1, this.icon);
  os.writeString(2, this.text);
  os.writeString(3, this.backgroundUrl);
  os.writeString(4, this.imageUrl);
};
ActivityLabelView.prototype.readFrom = function (is) {
  this.type = is.readInt32(0, false, this.type);
  this.icon = is.readString(1, false, this.icon);
  this.text = is.readString(2, false, this.text);
  this.backgroundUrl = is.readString(3, false, this.backgroundUrl);
  this.imageUrl = is.readString(4, false, this.imageUrl);
};
NimoBuss.ActivityLabelView = ActivityLabelView;
export default ActivityLabelView;