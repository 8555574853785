import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from 'react';
import { loadLangPkg, getLangPkgFromCache, noop, isNoopLangPkg } from '@client/common/lang';
export { loadLangPkg, getLangPkgFromCache, isNoopLangPkg };

/**
 * @typedef {import('@client/common/lang').LangRegistry} LangRegistry
 */

/**
 * @template T
 * @typedef {import('@client/common/lang').OptionalLangPkg<T>} OptionalLangPkg<T>
 */

/**
 * @template {LangRegistry} T
 * @param {T | {default:T}} registry
 * @param {{ lang?: string | number , defaultValue?: OptionalLangPkg<T> }} [param1]
 */
export var useLangPkg = function useLangPkg(registry) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    overrideLang = _ref.lang,
    _ref$defaultValue = _ref.defaultValue,
    defaultValue = _ref$defaultValue === void 0 ? noop : _ref$defaultValue;
  var _useState = useState(function () {
      return getLangPkgFromCache(registry, overrideLang) || defaultValue;
    }),
    _useState2 = _slicedToArray(_useState, 2),
    lang = _useState2[0],
    setLang = _useState2[1];
  useEffect(function () {
    loadLangPkg(registry, overrideLang).then(function (data) {
      setLang(data);
    });
  }, [registry, overrideLang]);
  return lang;
};