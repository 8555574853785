import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var LifBanUserMessageReq = function LifBanUserMessageReq() {
  this.user = new Nimo.UserId();
  this.lForbidUid = 0;
  this.lRoomId = 0;
  this.lAnchorUid = 0;
};
LifBanUserMessageReq.prototype._clone = function () {
  return new Nimo.LifBanUserMessageReq();
};
LifBanUserMessageReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LifBanUserMessageReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LifBanUserMessageReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lForbidUid);
  os.writeInt64(2, this.lRoomId);
  os.writeInt64(3, this.lAnchorUid);
};
LifBanUserMessageReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lForbidUid = is.readInt64(1, false, this.lForbidUid);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
  this.lAnchorUid = is.readInt64(3, false, this.lAnchorUid);
};
Nimo.LifBanUserMessageReq = LifBanUserMessageReq;
export default LifBanUserMessageReq;