import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './VideoData';
var GetVideoListRsp = function GetVideoListRsp() {
  this.iVideoStatus = 0;
  this.iVedoNums = 0;
  this.vVedioList = new Taf.Vector(new Nimo.VideoData());
  this.iRecommendCode = 0;
  this.sBizVideoVID = "";
};
GetVideoListRsp.prototype._clone = function () {
  return new Nimo.GetVideoListRsp();
};
GetVideoListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetVideoListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetVideoListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iVideoStatus);
  os.writeInt32(1, this.iVedoNums);
  os.writeVector(2, this.vVedioList);
  os.writeInt32(3, this.iRecommendCode);
  os.writeString(4, this.sBizVideoVID);
};
GetVideoListRsp.prototype.readFrom = function (is) {
  this.iVideoStatus = is.readInt32(0, false, this.iVideoStatus);
  this.iVedoNums = is.readInt32(1, false, this.iVedoNums);
  this.vVedioList = is.readVector(2, false, this.vVedioList);
  this.iRecommendCode = is.readInt32(3, false, this.iRecommendCode);
  this.sBizVideoVID = is.readString(4, false, this.sBizVideoVID);
};
Nimo.GetVideoListRsp = GetVideoListRsp;
export default GetVideoListRsp;