import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './RoomScreenshotsView';
var PkLiveRoomView = function PkLiveRoomView() {
  this.id = 0;
  this.anchorId = 0;
  this.anchorAvatarUrl = "";
  this.roomScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.showScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.anchorScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.voiceScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.templateType = 0;
};
PkLiveRoomView.prototype._clone = function () {
  return new NimoBuss.PkLiveRoomView();
};
PkLiveRoomView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PkLiveRoomView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PkLiveRoomView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.id);
  os.writeInt64(1, this.anchorId);
  os.writeString(2, this.anchorAvatarUrl);
  os.writeVector(3, this.roomScreenshots);
  os.writeVector(4, this.showScreenshots);
  os.writeVector(5, this.anchorScreenshots);
  os.writeVector(6, this.voiceScreenshots);
  os.writeInt32(7, this.templateType);
};
PkLiveRoomView.prototype.readFrom = function (is) {
  this.id = is.readInt64(0, false, this.id);
  this.anchorId = is.readInt64(1, false, this.anchorId);
  this.anchorAvatarUrl = is.readString(2, false, this.anchorAvatarUrl);
  this.roomScreenshots = is.readVector(3, false, this.roomScreenshots);
  this.showScreenshots = is.readVector(4, false, this.showScreenshots);
  this.anchorScreenshots = is.readVector(5, false, this.anchorScreenshots);
  this.voiceScreenshots = is.readVector(6, false, this.voiceScreenshots);
  this.templateType = is.readInt32(7, false, this.templateType);
};
NimoBuss.PkLiveRoomView = PkLiveRoomView;
export default PkLiveRoomView;