import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["threshold"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import LazyLoad from 'vanilla-lazyload';
var lazyload;
var updateLazyload = function updateLazyload() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$threshold = _ref.threshold,
    threshold = _ref$threshold === void 0 ? 600 : _ref$threshold,
    rest = _objectWithoutProperties(_ref, _excluded);
  if (!lazyload) {
    lazyload = new LazyLoad(_objectSpread({
      threshold: threshold,
      elements_selector: "img",
      cancel_on_exit: false
    }, rest));
  } else {
    lazyload.update();
  }
};
export default updateLazyload;
export function destroyLazyLoad() {
  if (lazyload) {
    lazyload.destroy();
    lazyload = null;
  }
}