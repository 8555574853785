import { loadLangPkg } from '@client/common/lang';
import { gameregionModel } from '@client/common/models/video/gameregion';
import anchorLevelWupModel from '@client/common/models/wup/anchorLevelWupModel';
import { guildAdminModel } from '@client/common/models/guild/guildAdmin';
export var UPDATE_PROFILE_LANGPKG = 'update_profile_langpkg';
export var GET_ARTICLE_AUTH = 'get_article_auth';
export var GET_ANCHOR_LEVEL_DETAIL = 'get_anchor_level_detail';
export var GET_ANCHOR_PRIVILEGE_CONFIG_LIST = 'get_anchor_privilege_config_list';
export var GET_VIDEO_UPLOAD_AUTH = 'get_video_upload_auth';
export var GET_COMPANY_INFO = 'get_company_info';
export var updateProfileLangPkg = function updateProfileLangPkg(id) {
  return function (dispatch) {
    return loadLangPkg(require('@desktop/src/common/lang/profile'), id).then(function (res) {
      dispatch({
        type: UPDATE_PROFILE_LANGPKG,
        payload: {
          langPkg: res
        }
      });
      return res;
    });
  };
};

// 获取文章模块权限
export function getArticleAuth() {
  return function (dispatch) {
    return gameregionModel.checkUserValid().then(function (res) {
      dispatch({
        type: GET_ARTICLE_AUTH,
        payload: !!res.status
      });
      return res.status;
    });
  };
}
export var getAnchorLevelDetail = function getAnchorLevelDetail() {
  return function (dispatch) {
    return anchorLevelWupModel.getAnchorLevelDetail().then(function (_ref) {
      var detail = _ref.detail;
      dispatch({
        type: GET_ANCHOR_LEVEL_DETAIL,
        payload: detail
      });
      return detail;
    });
  };
};
export var getAnchorPrivilegeConfigList = function getAnchorPrivilegeConfigList(iLangID) {
  return function (dispatch) {
    return anchorLevelWupModel.getAnchorPrivilegeConfig({
      iLevel: -1,
      iLangID: iLangID
    }).then(function (_ref2) {
      var vPrivileges = _ref2.vPrivileges;
      dispatch({
        type: GET_ANCHOR_PRIVILEGE_CONFIG_LIST,
        payload: vPrivileges
      });
      return vPrivileges;
    });
  };
};

// 获取视频上传权限
export function getVideoUploadAuth() {
  return function (dispatch) {
    return new Promise(function (resolve) {
      dispatch({
        type: GET_VIDEO_UPLOAD_AUTH,
        payload: {
          videoUploadAuth: true
        }
      });
      resolve(true);
    });
  };
}

// 获取公会管理信息
export function getCompanyInfo() {
  return function (dispatch) {
    return guildAdminModel.getGuildManagenfo().then(function (res) {
      dispatch({
        type: GET_COMPANY_INFO,
        payload: res
      });
      return res;
    });
  };
}