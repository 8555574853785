import NimoSailActivity from "../nimosailactivity";
import Taf from "@nimo-fed/taf-lib-wup";
var ShareRewardPopUpRsp = function ShareRewardPopUpRsp() {
  this.iCode = 0;
};
ShareRewardPopUpRsp.prototype._clone = function () {
  return new NimoSailActivity.ShareRewardPopUpRsp();
};
ShareRewardPopUpRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShareRewardPopUpRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShareRewardPopUpRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
};
ShareRewardPopUpRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
};
NimoSailActivity.ShareRewardPopUpRsp = ShareRewardPopUpRsp;
export default ShareRewardPopUpRsp;