import { getLang } from '@client/common/utils/cookieFn';
var defaultLang = '1033';
var currentLang = getLang();
var getPlainText = function getPlainText(_ref) {
  var richText = _ref.richText,
    _ref$lang = _ref.lang,
    lang = _ref$lang === void 0 ? currentLang : _ref$lang;
  var _ref2 = richText || {},
    mContent = _ref2.mContent,
    mRichLabel = _ref2.mRichLabel;
  var replacedContent = (mContent === null || mContent === void 0 ? void 0 : mContent[lang]) || (mContent === null || mContent === void 0 ? void 0 : mContent[defaultLang]) || '';
  var labelKeys = mRichLabel && Object.keys(mRichLabel);
  if (typeof replacedContent !== 'string') return '';
  if (!labelKeys || labelKeys.length === 0) {
    return replacedContent;
  }
  var keysReg = new RegExp("(".concat(labelKeys.map(function (key) {
    return "{\\$".concat(key, "}");
  }).join('|'), ")"), 'g');
  var text = '';
  replacedContent.split(keysReg).forEach(function (match) {
    if (keysReg.test(match)) {
      var _key2;
      var _key = /{\$(.*)}/g.exec(match);
      _key = (_key2 = _key) === null || _key2 === void 0 ? void 0 : _key2[1];
      var RichLabel = mRichLabel === null || mRichLabel === void 0 ? void 0 : mRichLabel[_key];
      var _ref3 = RichLabel || {},
        iType = _ref3.iType,
        tText = _ref3.tText;
      if (iType === 1) {
        var mText = tText.mText;
        var t = (mText === null || mText === void 0 ? void 0 : mText[lang]) || (mText === null || mText === void 0 ? void 0 : mText[defaultLang]);
        if (typeof t === 'string') {
          text += t;
        }
      }
    } else {
      text += match;
    }
  });
  return text;
};
export default getPlainText;