import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './AnchorLevelUpMsg';
var AnchorLevelUpMsgRsp = function AnchorLevelUpMsgRsp() {
  this.iCode = 0;
  this.vMsg = new Taf.Vector(new Nimo.AnchorLevelUpMsg());
  this.iLiveDays = 0;
  this.iFansNum = 0;
  this.nextPrivilegeMsg = new Nimo.AnchorLevelUpMsg();
  this.sAnchorRate = "";
};
AnchorLevelUpMsgRsp.prototype._clone = function () {
  return new Nimo.AnchorLevelUpMsgRsp();
};
AnchorLevelUpMsgRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelUpMsgRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelUpMsgRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeVector(1, this.vMsg);
  os.writeInt32(2, this.iLiveDays);
  os.writeInt32(3, this.iFansNum);
  os.writeStruct(5, this.nextPrivilegeMsg);
  os.writeString(6, this.sAnchorRate);
};
AnchorLevelUpMsgRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.vMsg = is.readVector(1, false, this.vMsg);
  this.iLiveDays = is.readInt32(2, false, this.iLiveDays);
  this.iFansNum = is.readInt32(3, false, this.iFansNum);
  this.nextPrivilegeMsg = is.readStruct(5, false, this.nextPrivilegeMsg);
  this.sAnchorRate = is.readString(6, false, this.sAnchorRate);
};
Nimo.AnchorLevelUpMsgRsp = AnchorLevelUpMsgRsp;
export default AnchorLevelUpMsgRsp;