import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './GameAbbr';
var RecommendGameEntity = function RecommendGameEntity() {
  this.id = 0;
  this.name = "";
  this.pos = 0;
  this.platform = 0;
  this.gameId = 0;
  this.gameName = "";
  this.businessType = 0;
  this.logo = "";
  this.updateTime = 0;
  this.clientId = 0;
  this.clientName = "";
  this.nameLang = "";
  this.templateType = 0;
  this.gameAbbrList = new Taf.Vector(new NimoBuss.GameAbbr());
};
RecommendGameEntity.prototype._clone = function () {
  return new NimoBuss.RecommendGameEntity();
};
RecommendGameEntity.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RecommendGameEntity.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RecommendGameEntity.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeString(1, this.name);
  os.writeInt32(2, this.pos);
  os.writeInt32(3, this.platform);
  os.writeInt32(4, this.gameId);
  os.writeString(5, this.gameName);
  os.writeInt32(6, this.businessType);
  os.writeString(7, this.logo);
  os.writeInt64(8, this.updateTime);
  os.writeInt32(9, this.clientId);
  os.writeString(10, this.clientName);
  os.writeString(11, this.nameLang);
  os.writeInt32(12, this.templateType);
  os.writeVector(13, this.gameAbbrList);
};
RecommendGameEntity.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.name = is.readString(1, false, this.name);
  this.pos = is.readInt32(2, false, this.pos);
  this.platform = is.readInt32(3, false, this.platform);
  this.gameId = is.readInt32(4, false, this.gameId);
  this.gameName = is.readString(5, false, this.gameName);
  this.businessType = is.readInt32(6, false, this.businessType);
  this.logo = is.readString(7, false, this.logo);
  this.updateTime = is.readInt64(8, false, this.updateTime);
  this.clientId = is.readInt32(9, false, this.clientId);
  this.clientName = is.readString(10, false, this.clientName);
  this.nameLang = is.readString(11, false, this.nameLang);
  this.templateType = is.readInt32(12, false, this.templateType);
  this.gameAbbrList = is.readVector(13, false, this.gameAbbrList);
};
NimoBuss.RecommendGameEntity = RecommendGameEntity;
export default RecommendGameEntity;