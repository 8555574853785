'use strict';

/* eslint-disable no-param-reassign */
var STATE_CHANGE = 'STATE_CHANGE';
var stateful = function stateful(target) {
  var initialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!target.isEventful) {
    throw new TypeError('stateful(...), target is not eventful.');
  }
  Object.defineProperties(target, {
    isStateful: {
      value: true
    },
    stateChangeListenerQueue: {
      value: {}
    },
    state: {
      value: initialState
    },
    setState: {
      value: function value(key, val) {
        if (!target.state) {
          return;
        }
        var oldVal = target.state[key];
        if (oldVal !== undefined && oldVal === val) {
          return;
        }
        target.state[key] = val;
        // TODO: 判断 emit 是否存在
        target.emit(STATE_CHANGE, {
          key: key,
          value: val,
          oldValue: oldVal
        });
      }
    },
    onStateChange: {
      value: function value(key, listener) {
        if (!key) {
          return;
        }
        var listenerQueue = target.stateChangeListenerQueue[key];
        if (!listenerQueue) {
          listenerQueue = [];
          target.stateChangeListenerQueue[key] = listenerQueue;
        }
        if (listenerQueue.indexOf(listener) >= 0) {
          return;
        }
        listenerQueue.push(listener);
      }
    },
    offStateChange: {
      value: function value(key, listener) {
        if (!key) {
          return;
        }
        var _target$stateChangeLi = target.stateChangeListenerQueue[key],
          listenerQueue = _target$stateChangeLi === void 0 ? [] : _target$stateChangeLi;
        var listenerIndex = listenerQueue.indexOf(listener);
        if (listenerIndex < 0) {
          return;
        }
        target.stateChangeListenerQueue[key] = listenerQueue.slice(0, listenerIndex).concat(listenerQueue.slice(listenerIndex + 1));
      }
    }
  });
};
export default stateful;
export { STATE_CHANGE };