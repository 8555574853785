import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import Nimo from '../nimo';
import '../Nimo/UserId';
import './BaseParam';
var LiveRoomParam = function LiveRoomParam() {
  this.userId = new Nimo.UserId();
  this.language = "";
  this.countryCode = "";
  this.idList = new Taf.Vector(new Taf.INT64());
  this.anchorIdList = new Taf.Vector(new Taf.INT64());
  this.anchorId = 0;
  this.roomId = 0;
  this.lcid = 0;
  this.baseParam = new NimoBuss.BaseParam();
  this.dynamicListQuery = "";
  this.notShowLineInfo = 0;
};
LiveRoomParam.prototype._clone = function () {
  return new NimoBuss.LiveRoomParam();
};
LiveRoomParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveRoomParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveRoomParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.userId);
  os.writeString(1, this.language);
  os.writeString(2, this.countryCode);
  os.writeVector(3, this.idList);
  os.writeVector(4, this.anchorIdList);
  os.writeInt64(5, this.anchorId);
  os.writeInt64(6, this.roomId);
  os.writeInt32(7, this.lcid);
  os.writeStruct(8, this.baseParam);
  os.writeString(11, this.dynamicListQuery);
  os.writeInt32(12, this.notShowLineInfo);
};
LiveRoomParam.prototype.readFrom = function (is) {
  this.userId = is.readStruct(0, false, this.userId);
  this.language = is.readString(1, false, this.language);
  this.countryCode = is.readString(2, false, this.countryCode);
  this.idList = is.readVector(3, false, this.idList);
  this.anchorIdList = is.readVector(4, false, this.anchorIdList);
  this.anchorId = is.readInt64(5, false, this.anchorId);
  this.roomId = is.readInt64(6, false, this.roomId);
  this.lcid = is.readInt32(7, false, this.lcid);
  this.baseParam = is.readStruct(8, false, this.baseParam);
  this.dynamicListQuery = is.readString(11, false, this.dynamicListQuery);
  this.notShowLineInfo = is.readInt32(12, false, this.notShowLineInfo);
};
NimoBuss.LiveRoomParam = LiveRoomParam;
export default LiveRoomParam;