import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _window$nimo_base;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { UPDATE_HOME_LANGPKG, UPDATE_HEADER_NAV_ACTIVE, UPDATE_SIDEBAR_LOCATION, UPDATE_PLAYER, UPDATE_PROFILE_LANGPKG } from './actions';
import roomReducer from './room/reducers';
import clipReducer from './clip/reducers';
import gameReducer from './game/reducers';
var persistConfig = {
  key: 'game',
  storage: storage,
  whitelist: ['game']
};
var initialState = {
  langPkg: {},
  headerNavActiveKey: (_window$nimo_base = window.nimo_base) === null || _window$nimo_base === void 0 ? void 0 : _window$nimo_base.headerNav,
  sidebarLocation: {},
  player: null
};
function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case UPDATE_HOME_LANGPKG:
      return _objectSpread(_objectSpread({}, state), {}, {
        langPkg: _objectSpread(_objectSpread({}, state.langPkg), payload)
      });
    case UPDATE_HEADER_NAV_ACTIVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        headerNavActiveKey: payload
      });
    case UPDATE_SIDEBAR_LOCATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        sidebarLocation: payload
      });
    case UPDATE_PLAYER:
      return _objectSpread(_objectSpread({}, state), {}, {
        player: payload
      });
    case UPDATE_PROFILE_LANGPKG:
      return {
        langPkg: _objectSpread(_objectSpread({}, state.langPkg), payload)
      };
    default:
      return state;
  }
}
export default persistReducer(persistConfig, combineReducers({
  common: commonReducer,
  room: roomReducer,
  clip: clipReducer,
  game: gameReducer
}));