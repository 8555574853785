import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EFontColorType = {
  kFontColorShuHei: 1,
  kFontColorShuBai: 2,
  kFontColorHengQi: 3,
  kFontColorHengLiao: 4,
  kFontColorXiuChang: 5
};
Nimo.EFontColorType = EFontColorType;
export default EFontColorType;