import memoizeOne from 'memoize-one';
var _findCountryByCode = function _findCountryByCode(list, code) {
  var len = list === null || list === void 0 ? void 0 : list.length;
  if (len) {
    for (var i = 0; i < len; i++) {
      var item = list[i];
      if (item.code === code) {
        return item;
      }
    }
  }
  return null;
};
export var getCurCCountryNotInHotArea = memoizeOne(function (curCCountry, cCountryList) {
  // 先在 hotArea 中寻找
  var country = _findCountryByCode(cCountryList === null || cCountryList === void 0 ? void 0 : cCountryList.hotArea, curCCountry);
  if (country) {
    // 在 hotArea 中找到，则不额外凭借到列表中
    return {
      country: country,
      isInHot: true
    };
  }
  if (!country) {
    var areaViewsLen = cCountryList === null || cCountryList === void 0 ? void 0 : cCountryList.areaViews.length;
    if (areaViewsLen) {
      for (var i = 0; i < areaViewsLen; i++) {
        var areaView = cCountryList.areaViews[i];
        country = _findCountryByCode(areaView === null || areaView === void 0 ? void 0 : areaView.regionViewList, curCCountry);
        if (country) {
          break;
        }
      }
    }
  }
  return {
    country: country,
    isInHot: false
  };
});