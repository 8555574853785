import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GiftConsumeRsp = function GiftConsumeRsp() {
  this.iPayRespCode = 0;
  this.iItemType = 0;
  this.iItemCount = 0;
  this.strPayId = "";
  this.strPayConfirmUrl = "";
  this.strSendContent = "";
  this.lPresenterUid = 0;
  this.sExpand = "";
  this.strPayItemInfo = "";
  this.iPayType = 0;
  this.iComboScore = 0;
  this.iBPGiftRemainCount = 0;
  this.iMaxNum = 0;
  this.sTips = "";
  this.lRecipientId = 0;
};
GiftConsumeRsp.prototype._clone = function () {
  return new Nimo.GiftConsumeRsp();
};
GiftConsumeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftConsumeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftConsumeRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iPayRespCode);
  os.writeInt32(1, this.iItemType);
  os.writeInt32(2, this.iItemCount);
  os.writeString(3, this.strPayId);
  os.writeString(4, this.strPayConfirmUrl);
  os.writeString(5, this.strSendContent);
  os.writeInt64(6, this.lPresenterUid);
  os.writeString(7, this.sExpand);
  os.writeString(8, this.strPayItemInfo);
  os.writeInt32(9, this.iPayType);
  os.writeInt32(10, this.iComboScore);
  os.writeInt32(11, this.iBPGiftRemainCount);
  os.writeInt32(12, this.iMaxNum);
  os.writeString(13, this.sTips);
  os.writeInt64(14, this.lRecipientId);
};
GiftConsumeRsp.prototype.readFrom = function (is) {
  this.iPayRespCode = is.readInt32(0, false, this.iPayRespCode);
  this.iItemType = is.readInt32(1, false, this.iItemType);
  this.iItemCount = is.readInt32(2, false, this.iItemCount);
  this.strPayId = is.readString(3, false, this.strPayId);
  this.strPayConfirmUrl = is.readString(4, false, this.strPayConfirmUrl);
  this.strSendContent = is.readString(5, false, this.strSendContent);
  this.lPresenterUid = is.readInt64(6, false, this.lPresenterUid);
  this.sExpand = is.readString(7, false, this.sExpand);
  this.strPayItemInfo = is.readString(8, false, this.strPayItemInfo);
  this.iPayType = is.readInt32(9, false, this.iPayType);
  this.iComboScore = is.readInt32(10, false, this.iComboScore);
  this.iBPGiftRemainCount = is.readInt32(11, false, this.iBPGiftRemainCount);
  this.iMaxNum = is.readInt32(12, false, this.iMaxNum);
  this.sTips = is.readString(13, false, this.sTips);
  this.lRecipientId = is.readInt64(14, false, this.lRecipientId);
};
Nimo.GiftConsumeRsp = GiftConsumeRsp;
export default GiftConsumeRsp;