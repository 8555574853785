import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ForbidUserMessageRsp = function ForbidUserMessageRsp() {
  this.iCode = 0;
  this.lForbidUid = 0;
  this.lRoomId = 0;
  this.lTimeS = 0;
  this.sErrorMsg = "";
};
ForbidUserMessageRsp.prototype._clone = function () {
  return new Nimo.ForbidUserMessageRsp();
};
ForbidUserMessageRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ForbidUserMessageRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ForbidUserMessageRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt64(1, this.lForbidUid);
  os.writeInt64(2, this.lRoomId);
  os.writeInt64(3, this.lTimeS);
  os.writeString(4, this.sErrorMsg);
};
ForbidUserMessageRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.lForbidUid = is.readInt64(1, false, this.lForbidUid);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
  this.lTimeS = is.readInt64(3, false, this.lTimeS);
  this.sErrorMsg = is.readString(4, false, this.sErrorMsg);
};
Nimo.ForbidUserMessageRsp = ForbidUserMessageRsp;
export default ForbidUserMessageRsp;