import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './CodeLineInfo2';
var CodeLine2 = function CodeLine2() {
  this.iNameCode = 0;
  this.iBitRate = 0;
  this.vCdns = new Taf.Vector(new Nimo.CodeLineInfo2());
};
CodeLine2.prototype._clone = function () {
  return new Nimo.CodeLine2();
};
CodeLine2.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CodeLine2.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CodeLine2.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iNameCode);
  os.writeInt32(1, this.iBitRate);
  os.writeVector(2, this.vCdns);
};
CodeLine2.prototype.readFrom = function (is) {
  this.iNameCode = is.readInt32(0, false, this.iNameCode);
  this.iBitRate = is.readInt32(1, false, this.iBitRate);
  this.vCdns = is.readVector(2, false, this.vCdns);
};
Nimo.CodeLine2 = CodeLine2;
export default CodeLine2;