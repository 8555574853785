import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var MarkReadRsp = function MarkReadRsp() {
  this.iCode = 0;
  this.lId = 0;
  this.lLatestMsgId = 0;
};
MarkReadRsp.prototype._clone = function () {
  return new Nimo.MarkReadRsp();
};
MarkReadRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MarkReadRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MarkReadRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt64(1, this.lId);
  os.writeInt64(2, this.lLatestMsgId);
};
MarkReadRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.lId = is.readInt64(1, false, this.lId);
  this.lLatestMsgId = is.readInt64(2, false, this.lLatestMsgId);
};
Nimo.MarkReadRsp = MarkReadRsp;
export default MarkReadRsp;