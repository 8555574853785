import ua from '@client/common/utils/ua';

/**
 * @param {(value: boolean) => void} [cb]
 */
function imageTest(cb) {
  var image = new Image();
  function addResult(event) {
    // if the event is from 'onload', check the see if the image's width is
    // 1 pixel (which indicates support). otherwise, it fails
    var result = event && event.type === 'load' ? image.width === 1 : false;
    if (cb) {
      cb(result);
    }
  }
  image.onerror = addResult;
  image.onload = addResult;
  image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
}

/**
 * @param {(v: 0 | 1) => void} [cb]
 * @param {boolean} value
 */
function runCallback(cb, value) {
  if (typeof cb === 'function') {
    try {
      cb(transformValue(value));
    } catch (err) {
      setTimeout(function () {
        var _window$Sentry, _window$console;
        if ((_window$Sentry = window.Sentry) !== null && _window$Sentry !== void 0 && _window$Sentry.captureException) {
          Sentry.captureException(err);
        }
        if (DOMAINS.isDebug === 'true' && (_window$console = window.console) !== null && _window$console !== void 0 && _window$console.error) {
          console.error(err);
        }
      }, 0);
    }
  }
}
export var WEBP_VALUES = {
  CHECKING: -1,
  NOT_SUPPORT: 0,
  SUPPORT: 1
};

/**
 * @param {boolean | void} v
 */
function transformValue(v) {
  if (v === undefined) {
    return WEBP_VALUES.CHECKING;
  }
  return v ? WEBP_VALUES.SUPPORT : WEBP_VALUES.NOT_SUPPORT;
}

/**
 * @param {(value: 0 | 1) => void} [cb]
 */
export function isWebpSupport(cb) {
  if (isWebpSupport._v !== undefined) {
    runCallback(cb, isWebpSupport._v);
    return;
  }
  var browserVersion = ua.browser.version || '';
  var cacheSupported;
  var cacheBrowserVersion = '';
  try {
    cacheSupported = localStorage.getItem('webp-support') === '1';
    cacheBrowserVersion = localStorage.getItem('webp-bv') || '';
  } catch (e) {
    // ignore
  }
  if (cacheSupported && cacheBrowserVersion === browserVersion) {
    isWebpSupport._v = cacheSupported;
    runCallback(cb, cacheSupported);
    return;
  }
  if (isWebpSupport._q) {
    isWebpSupport._q.push(cb);
  } else {
    isWebpSupport._q = [cb];
    imageTest(function (value) {
      isWebpSupport._v = value;
      try {
        localStorage.setItem('webp-support', value ? '1' : '0');
        localStorage.setItem('webp-bv', browserVersion);
      } catch (e) {
        // ignore
      }
      isWebpSupport._q.forEach(function (f) {
        runCallback(f, value);
      });
      isWebpSupport._q = null;
    });
  }
}

/**
 * @returns {-1 | 0 | 1}
 */
export function getUnsafeIsWebpSupport() {
  isWebpSupport();
  return transformValue(isWebpSupport._v);
}