import { eventEmitter, EVENTS } from '@client/common/utils/events';
var currentAction = null;
export var setAction = function setAction(data) {
  currentAction = data;
  if (currentAction) eventEmitter.emit(EVENTS.DASHBOARD_OPEN_ACTION, currentAction);
};
export var resetAction = function resetAction() {
  currentAction = null;
};
export var getAction = function getAction() {
  return currentAction;
};