import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ChatBannerEffect = function ChatBannerEffect() {
  this.iStreamerLevel = 0;
};
ChatBannerEffect.prototype._clone = function () {
  return new Nimo.ChatBannerEffect();
};
ChatBannerEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ChatBannerEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ChatBannerEffect.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iStreamerLevel);
};
ChatBannerEffect.prototype.readFrom = function (is) {
  this.iStreamerLevel = is.readInt32(0, false, this.iStreamerLevel);
};
Nimo.ChatBannerEffect = ChatBannerEffect;
export default ChatBannerEffect;