import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GetRoomHistoryMsgInfo';
var GetRoomHistoryMsgRsp = function GetRoomHistoryMsgRsp() {
  this.lRoomId = 0;
  this.vContentList = new Taf.Vector(new Nimo.GetRoomHistoryMsgInfo());
};
GetRoomHistoryMsgRsp.prototype._clone = function () {
  return new Nimo.GetRoomHistoryMsgRsp();
};
GetRoomHistoryMsgRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRoomHistoryMsgRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRoomHistoryMsgRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeVector(1, this.vContentList);
};
GetRoomHistoryMsgRsp.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.vContentList = is.readVector(1, false, this.vContentList);
};
Nimo.GetRoomHistoryMsgRsp = GetRoomHistoryMsgRsp;
export default GetRoomHistoryMsgRsp;