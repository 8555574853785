import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ARStickerEffect = function ARStickerEffect() {
  this.sResource = "";
};
ARStickerEffect.prototype._clone = function () {
  return new Nimo.ARStickerEffect();
};
ARStickerEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ARStickerEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ARStickerEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sResource);
};
ARStickerEffect.prototype.readFrom = function (is) {
  this.sResource = is.readString(0, false, this.sResource);
};
Nimo.ARStickerEffect = ARStickerEffect;
export default ARStickerEffect;