import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorPunishInfo = function AnchorPunishInfo() {
  this.lUid = 0;
  this.iMaskSeconds = 0;
  this.dScale = 0;
  this.iMuteSeconds = 0;
};
AnchorPunishInfo.prototype._clone = function () {
  return new Nimo.AnchorPunishInfo();
};
AnchorPunishInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorPunishInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorPunishInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeInt32(1, this.iMaskSeconds);
  os.writeDouble(2, this.dScale);
  os.writeInt32(3, this.iMuteSeconds);
};
AnchorPunishInfo.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.iMaskSeconds = is.readInt32(1, false, this.iMaskSeconds);
  this.dScale = is.readDouble(2, false, this.dScale);
  this.iMuteSeconds = is.readInt32(3, false, this.iMuteSeconds);
};
Nimo.AnchorPunishInfo = AnchorPunishInfo;
export default AnchorPunishInfo;