import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ShareOriginID = {
  E_NONE: 0,
  E_DAILY_LOTTERY: 1,
  E_MATCH_SHARE_LOTTERY: 2,
  E_PICKME_SHARE_TASK: 3,
  E_LIVE_SHARE: 4,
  E_SCREEN: 5,
  E_GROUP_UP: 6,
  E_FANS_TASK: 7,
  E_SUPERSTAR_JUNE: 8,
  E_CLIP: 9,
  E_STREAM_MANAGER: 10,
  E_BIRTHDAY_PARTY: 11,
  E_FIVE_ANNIVERSARIES: 12
};
Nimo.ShareOriginID = ShareOriginID;
export default ShareOriginID;