import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var TeamPKSummaryView = function TeamPKSummaryView() {
  this.pkId = 0;
};
TeamPKSummaryView.prototype._clone = function () {
  return new NimoBuss.TeamPKSummaryView();
};
TeamPKSummaryView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TeamPKSummaryView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TeamPKSummaryView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.pkId);
};
TeamPKSummaryView.prototype.readFrom = function (is) {
  this.pkId = is.readInt64(0, false, this.pkId);
};
NimoBuss.TeamPKSummaryView = TeamPKSummaryView;
export default TeamPKSummaryView;