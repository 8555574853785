import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var EventRoomView = function EventRoomView() {
  this.title = "";
  this.roomId = 0;
  this.alise = "";
  this.weight = 0;
  this.updateTime = 0;
  this.liveStreamStatus = 0;
};
EventRoomView.prototype._clone = function () {
  return new NimoBuss.EventRoomView();
};
EventRoomView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EventRoomView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EventRoomView.prototype.writeTo = function (os) {
  os.writeString(0, this.title);
  os.writeInt64(1, this.roomId);
  os.writeString(2, this.alise);
  os.writeInt32(3, this.weight);
  os.writeInt64(4, this.updateTime);
  os.writeInt32(5, this.liveStreamStatus);
};
EventRoomView.prototype.readFrom = function (is) {
  this.title = is.readString(0, false, this.title);
  this.roomId = is.readInt64(1, false, this.roomId);
  this.alise = is.readString(2, false, this.alise);
  this.weight = is.readInt32(3, false, this.weight);
  this.updateTime = is.readInt64(4, false, this.updateTime);
  this.liveStreamStatus = is.readInt32(5, false, this.liveStreamStatus);
};
NimoBuss.EventRoomView = EventRoomView;
export default EventRoomView;