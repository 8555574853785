import nimoReport from '@client/common/services/reportWithoutInit'; // 要在pre-init的initPage()之后，等yaScript插入

// 曝光签到奖励领取弹窗
export var reportSignReward = function reportSignReward(obj) {
  var reportObj = nimoReport._formatReportObj('show/signin_receive_popup',
  // eslint-disable-next-line i18n/no-chinese-character
  '曝光签到奖励领取弹窗', obj);
  nimoReport.report(reportObj);
};

// 点击签到奖励领取弹窗按钮
export var reportClickRewardModal = function reportClickRewardModal(obj) {
  var reportObj = nimoReport._formatReportObj('click/signin_receive_popup',
  // eslint-disable-next-line i18n/no-chinese-character
  '点击签到奖励领取弹窗按钮', obj);
  nimoReport.report(reportObj);
};

// 每日签到曝光事件
export var reportTaskCenter = function reportTaskCenter(obj) {
  var reportObj = nimoReport._formatReportObj('show/signin_icon',
  // eslint-disable-next-line i18n/no-chinese-character
  '每日签到曝光事件', obj);
  nimoReport.report(reportObj);
};

// 每日签到点击事件
export var reportSignInSuccess = function reportSignInSuccess(obj) {
  var reportObj = nimoReport._formatReportObj('click/signin_icon',
  // eslint-disable-next-line i18n/no-chinese-character
  '每日签到点击事件', obj);
  nimoReport.report(reportObj);
};

// 每日任务入口点击事件
export var reportClickSignInPopup = function reportClickSignInPopup(obj) {
  var reportObj = nimoReport._formatReportObj('click/task_entrance',
  // eslint-disable-next-line i18n/no-chinese-character
  '每日任务入口点击事件', obj);
  nimoReport.report(reportObj);
};

// 每日任务入口曝光事件
export var reportShowSignInPopup = function reportShowSignInPopup(obj) {
  var reportObj = nimoReport._formatReportObj('show/task_entrance',
  // eslint-disable-next-line i18n/no-chinese-character
  '每日任务入口曝光事件', obj);
  nimoReport.report(reportObj);
};