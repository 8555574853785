import { useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';
export var useDebounceCallback = function useDebounceCallback(fn, waiting) {
  var leading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var callback = useMemo(function () {
    return debounce(fn, waiting, {
      leading: leading
    });
  }, [fn, waiting, leading]);
  useEffect(function () {
    return function () {
      callback.cancel();
    };
  }, [callback]);
  return callback;
};