import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var BatchGetAnchorPunishReq = function BatchGetAnchorPunishReq() {
  this.tUser = new Nimo.UserId();
  this.vUid = new Taf.Vector(new Taf.INT64());
};
BatchGetAnchorPunishReq.prototype._clone = function () {
  return new Nimo.BatchGetAnchorPunishReq();
};
BatchGetAnchorPunishReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BatchGetAnchorPunishReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BatchGetAnchorPunishReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeVector(1, this.vUid);
};
BatchGetAnchorPunishReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.vUid = is.readVector(1, false, this.vUid);
};
Nimo.BatchGetAnchorPunishReq = BatchGetAnchorPunishReq;
export default BatchGetAnchorPunishReq;