import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import memoize from 'memoize-one';

/**
 * 预处理小数点位数(四舍五入)
 * @param {*} viewNum
 * @param {number} [divisor=1000]
 * @returns
 */
function preFormatViewNum(viewNum) {
  var divisor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
  // const r = viewNum % divisor;

  // 如果小数点后全部是0，采用注释的做法
  // divisor * (19/20) === ((divisor - divisor / 10) + divisor / 20)
  // 示例：当divisor == 1000时, 如果 r >= 50 && r < 950（四舍五入后小数不为0的情况） 才保留一位小数，否则不保留小数
  // const newViewNum = r >= divisor / 20 && r < divisor * (19 / 20)
  //   ? (viewNum / divisor).toFixed(1)
  //   : (viewNum / divisor).toFixed(0);
  var newViewNum = (viewNum / divisor).toFixed(1);
  return newViewNum;
}

/**
 * 预处理小数点位数（向下取整）
 * @param {number} viewNum
 * @param {number} divisor
 */
function preFormatViewNumByFloor(viewNum) {
  var divisor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
  return +"".concat(Math.floor(+"".concat(viewNum / divisor, "e1")), "e-1");
}

/**
 * 数值千分位
 *
 * @export
 * @param {*} num
 * @param {string} [separator=','] 分割符号
 * @returns {string} 千分位格式的数字字符串
 */
export function splitNum(num) {
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ',';
  var reg = /(-?\d+)(\d{3})/;
  var pointIdx = null;
  var pointPart = '';
  var newNum = '';

  // 不是数字
  if (isNaN(num)) {
    return num;
  }

  // 是无穷大数值
  if (!isFinite(num)) {
    return 0;
  }

  // 转化为字符串
  // eslint-disable-next-line no-param-reassign
  num = typeof num === 'number' ? "".concat(num) : num;
  newNum = num;
  pointIdx = num.indexOf('.');
  if (pointIdx > -1) {
    newNum = num.substring(0, pointIdx);
    pointPart = num.substring(pointIdx + 1);
  }

  // 如果num为字符串
  if (typeof newNum === 'string') {
    while (reg.test(newNum)) {
      newNum = newNum.replace(reg, "$1".concat(separator, "$2"));
    }
  }
  if (pointPart) {
    newNum += ".".concat(pointPart);
  }
  return newNum;
}

/**
 * 去除千分位
 *
 * @export
 * @param {string} str
 * @param {string} [separator=',']
 * @returns
 */
export function joinNum(str) {
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ',';
  if (typeof str === 'string') {
    // eslint-disable-next-line no-param-reassign
    str = str.split(separator);
    // eslint-disable-next-line no-param-reassign
    str = parseFloat(str.join(''));
  }
  return str;
}

/**
 * 格式化观众数（用在直播列表页，我的关注页，首页栏目）
 * 规则：
 * 1、 过千展示 k，过百万展示 m，过十亿展示 b
 * 2、 保留一位小数点
 * 3、 四舍五入
 * @param {number|string} viewNum
 */
export var formatViewNum = function formatViewNum(viewNum) {
  var newViewNum;

  // 不是数字
  if (isNaN(viewNum)) {
    return viewNum;
  }

  // 是无穷大数值
  if (!isFinite(viewNum)) {
    return 0;
  }

  // 转化为数字
  newViewNum = typeof viewNum !== 'number' ? +viewNum : viewNum;
  if (newViewNum < 1000) {
    return newViewNum;
  } else if (newViewNum < Math.pow(10, 6)) {
    newViewNum = preFormatViewNum(newViewNum, 1000);
    return "".concat(splitNum(newViewNum), "k");
  } else if (newViewNum < Math.pow(10, 9)) {
    newViewNum = preFormatViewNum(newViewNum, Math.pow(10, 6));
    return "".concat(splitNum(newViewNum), "m");
  } else {
    newViewNum = preFormatViewNum(newViewNum, Math.pow(10, 9));
    return "".concat(splitNum(newViewNum), "b");
  }
};

/**
 * 格式化货币（向下取整）
 * @param {number|string} diamondNum
 */
export var formatDiamondNum = function formatDiamondNum(diamondNum) {
  var newDiamondNum;

  // 不是数字
  if (isNaN(diamondNum)) {
    return diamondNum;
  }

  // 是无穷大数值
  if (!isFinite(diamondNum)) {
    return 0;
  }

  // 转化为数字
  newDiamondNum = typeof diamondNum !== 'number' ? +diamondNum : diamondNum;
  if (newDiamondNum < 1000) {
    return newDiamondNum;
  } else if (newDiamondNum < Math.pow(10, 6)) {
    newDiamondNum = preFormatViewNumByFloor(newDiamondNum, 1000);
    return "".concat(splitNum(newDiamondNum), "k");
  } else if (newDiamondNum < Math.pow(10, 9)) {
    newDiamondNum = preFormatViewNumByFloor(newDiamondNum, Math.pow(10, 6));
    return "".concat(splitNum(newDiamondNum), "m");
  } else {
    newDiamondNum = preFormatViewNumByFloor(newDiamondNum, Math.pow(10, 9));
    return "".concat(splitNum(newDiamondNum), "b");
  }
};

/**
 * int32转16进制颜色色值
 * @param {*} num 需要转换的int32
 */
export var convertInt32ToHex = memoize(function (num) {
  var ten = convertFontColorToTen(num); // Android 端先加了 alpha 值，所以为了兼容 Android 端，需要先位与去掉 alpha 值
  return "#".concat(ten.toString(16)) || '';
});

/**
 * 将 taf 返回的字体颜色值转10进制数字，用于弹幕颜色
 */
export function convertFontColorToTen(num) {
  return num & 0x00ffffff;
}

/**
 * 格式化16进制颜色
 * 后端下发的16进制带alpha的颜色值，前两位是alpha通道，而web是后两位为alpha通道，故需要转换一下
 * 例子：'#D6FFA100' --> '#FFA100D6'
 * '#AARRGGBB' --> '#RRGGBBAA'
 */
export var formatHexColorWithAlpha = memoize(function (color) {
  return typeof color === 'string' ? color.replace(/#([a-zA-Z0-9]{2})([a-zA-Z0-9]*)/, '#$2$1') : '';
});
var alphaDecimalPrecision = 100000;
export var hexa2rgba = function hexa2rgba(hex) {
  var hex8 = "0x".concat(hex.slice(1));
  var _ref = [parseInt(hex8.slice(2, 4), 16), parseInt(hex8.slice(4, 6), 16), parseInt(hex8.slice(6, 8), 16), Math.round(parseInt(hex8.slice(8, 10), 16) / 255 * alphaDecimalPrecision) / alphaDecimalPrecision],
    r = _ref[0],
    g = _ref[1],
    b = _ref[2],
    a = _ref[3];
  return "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(a, ")");
};

/**
 * '#AARRGGBB' -> rgba
 * @param {string} hex
 */
export var convertAARRGGBBtoRGBA = function convertAARRGGBBtoRGBA(hex) {
  if (hex.length === 9 && hex[0] === '#') {
    return hexa2rgba(formatHexColorWithAlpha(hex));
  }
  return hex;
};

/**
 * 16进制颜色转rgba
 * @param {string} hex 16进制颜色值
 * @param {number} alpha 透明度
 * @returns string
 * example: '#ffffff' --> 'rgba(255,255,255,1)'
 */
export var convertHexToRgba = function convertHexToRgba(hex) {
  var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  if (hex) {
    var _hex$split = hex.split(''),
      _hex$split2 = _slicedToArray(_hex$split, 7),
      r1 = _hex$split2[1],
      r2 = _hex$split2[2],
      g1 = _hex$split2[3],
      g2 = _hex$split2[4],
      b1 = _hex$split2[5],
      b2 = _hex$split2[6];
    var r = parseInt("".concat(r1).concat(r2), 16);
    var g = parseInt("".concat(g1).concat(g2), 16);
    var b = parseInt("".concat(b1).concat(b2), 16);
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")");
  } else {
    return '';
  }
};

/**
 * 数字转百分比格式
 * @param {*} num 需要转百分比的数字
 * @param {*} decimals 保留小数点位数
 */
export function toPercent(num) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var newNum = Number(num * 100);
  if (isNaN(newNum)) {
    return 0;
  }
  return "".concat(newNum.toFixed(decimals), "%");
}

/**
 * 格式化余额（向下取整）
 * @param {*} balance 余额
 */
export var formatBalance = formatDiamondNum;
export function formatChips(balance) {
  if (isNaN(balance)) return balance;
  return Math.floor(balance);
}

/**
 * 不足两位数的月日时分秒自动加0
 *
 * @param {number} num 月日时分秒
 * @return {string} 自动补全0的月日时分秒
 */
export function two(num) {
  // eslint-disable-next-line no-param-reassign
  num = num || 0;
  return "0".concat(num).substr(-2);
}

/**
 * 限制数字最大显示位数，超过最大值，末尾加「+」显示·····
 * @param {number} num 限制数字
 * @param {number} digit 最大位数，比如 999 对应 3
 */
export function limitMaxDigit(num) {
  var digit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var gtMax = Math.pow(10, digit);
  return num >= gtMax ? "".concat(gtMax - 1, "+") : num;
}

// 是否奇数
export function isOdd(num) {
  if (typeof num !== 'number' || isNaN(num)) {
    return false;
  }
  return !!(num % 2);
}

// 10进制转62进制
export function string10to62(number) {
  var chars = '0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ'.split('');
  var radix = chars.length;
  var qutient = +number;
  var arr = [];
  var mod;
  do {
    mod = qutient % radix;
    qutient = (qutient - mod) / radix;
    arr.unshift(chars[mod]);
  } while (qutient);
  return arr.join('');
}