import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorPrivilegeConfig = function AnchorPrivilegeConfig() {
  this.iID = 0;
  this.iType = 0;
  this.sName = "";
  this.sDesc = "";
  this.iEnableLevel = 0;
  this.mExt = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sPhoto = "";
  this.sDescDetail = "";
  this.sPhotoDetail = "";
};
AnchorPrivilegeConfig.prototype._clone = function () {
  return new Nimo.AnchorPrivilegeConfig();
};
AnchorPrivilegeConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorPrivilegeConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorPrivilegeConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iID);
  os.writeInt32(1, this.iType);
  os.writeString(2, this.sName);
  os.writeString(3, this.sDesc);
  os.writeInt32(4, this.iEnableLevel);
  os.writeMap(5, this.mExt);
  os.writeString(6, this.sPhoto);
  os.writeString(7, this.sDescDetail);
  os.writeString(8, this.sPhotoDetail);
};
AnchorPrivilegeConfig.prototype.readFrom = function (is) {
  this.iID = is.readInt32(0, false, this.iID);
  this.iType = is.readInt32(1, false, this.iType);
  this.sName = is.readString(2, false, this.sName);
  this.sDesc = is.readString(3, false, this.sDesc);
  this.iEnableLevel = is.readInt32(4, false, this.iEnableLevel);
  this.mExt = is.readMap(5, false, this.mExt);
  this.sPhoto = is.readString(6, false, this.sPhoto);
  this.sDescDetail = is.readString(7, false, this.sDescDetail);
  this.sPhotoDetail = is.readString(8, false, this.sPhotoDetail);
};
Nimo.AnchorPrivilegeConfig = AnchorPrivilegeConfig;
export default AnchorPrivilegeConfig;