import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorPrivilegeConfigReq = function AnchorPrivilegeConfigReq() {
  this.iLevel = 0;
  this.iLangID = 0;
};
AnchorPrivilegeConfigReq.prototype._clone = function () {
  return new Nimo.AnchorPrivilegeConfigReq();
};
AnchorPrivilegeConfigReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorPrivilegeConfigReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorPrivilegeConfigReq.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iLevel);
  os.writeInt32(1, this.iLangID);
};
AnchorPrivilegeConfigReq.prototype.readFrom = function (is) {
  this.iLevel = is.readInt32(0, false, this.iLevel);
  this.iLangID = is.readInt32(1, false, this.iLangID);
};
Nimo.AnchorPrivilegeConfigReq = AnchorPrivilegeConfigReq;
export default AnchorPrivilegeConfigReq;