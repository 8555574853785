import Auth from '@desktop/src/common/services/auth';
import { normalizeDesktopUserInfo } from '@client/common/services/normalizeDesktopUserInfo';
export default (function () {
  if (Auth.checkIsAutoLogin()) {
    // 正在自动登录
    return false;
  } else if (Auth.checkLogin(true)) {
    // 已登录
    return normalizeDesktopUserInfo(Auth.getUserInfo());
  }
  return false;
});