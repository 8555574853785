import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var FollowAllListParam = function FollowAllListParam() {
  this.baseParam = new NimoBuss.BaseParam();
  this.languageId = 0;
  this.refreshCache = 0;
  this.countryCode = "";
  this.deviceType = 0;
  this.pageIndex = 0;
  this.pageCount = 0;
};
FollowAllListParam.prototype._clone = function () {
  return new NimoBuss.FollowAllListParam();
};
FollowAllListParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowAllListParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowAllListParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt32(1, this.languageId);
  os.writeInt32(2, this.refreshCache);
  os.writeString(3, this.countryCode);
  os.writeInt32(4, this.deviceType);
  os.writeInt32(5, this.pageIndex);
  os.writeInt32(6, this.pageCount);
};
FollowAllListParam.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.languageId = is.readInt32(1, false, this.languageId);
  this.refreshCache = is.readInt32(2, false, this.refreshCache);
  this.countryCode = is.readString(3, false, this.countryCode);
  this.deviceType = is.readInt32(4, false, this.deviceType);
  this.pageIndex = is.readInt32(5, false, this.pageIndex);
  this.pageCount = is.readInt32(6, false, this.pageCount);
};
NimoBuss.FollowAllListParam = FollowAllListParam;
export default FollowAllListParam;