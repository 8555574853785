import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ReplaceRoomManagerPrivilegeteRsp = function ReplaceRoomManagerPrivilegeteRsp() {
  this.iCode = 0;
};
ReplaceRoomManagerPrivilegeteRsp.prototype._clone = function () {
  return new Nimo.ReplaceRoomManagerPrivilegeteRsp();
};
ReplaceRoomManagerPrivilegeteRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ReplaceRoomManagerPrivilegeteRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ReplaceRoomManagerPrivilegeteRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
};
ReplaceRoomManagerPrivilegeteRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
};
Nimo.ReplaceRoomManagerPrivilegeteRsp = ReplaceRoomManagerPrivilegeteRsp;
export default ReplaceRoomManagerPrivilegeteRsp;