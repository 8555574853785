import { useEffect } from 'react';
import chatRoomWupModel from '@client/common/models/wup/chatRoomWupModel';
import nimoReport from '@client/common/services/reportWithoutInit';
import chatRuleModel from '@client/common/models/api/chatRule';
import { getMessageSettings } from '@desktop/src/common/components/ChatBoxSettings/settings';
export default function ChatboxReporter(_ref) {
  var isLive = _ref.isLive,
    roomInfo = _ref.roomInfo,
    userInfo = _ref.userInfo,
    type = _ref.type;
  var _ref2 = roomInfo || {},
    roomId = _ref2.roomId,
    auId = _ref2.auId;
  var _ref3 = userInfo || {},
    uid = _ref3.uid;
  var isStreamer = auId > 0 && uid > 0 && String(uid) === String(auId);
  useEffect(function () {
    if (isLive && roomId > 0 && isStreamer) {
      chatRoomWupModel.getMessageMode(roomId).then(function (data) {
        var iFansOnlyModeSwitch = data.iFansOnlyModeSwitch,
          iFollowerOnlyModeSwitch = data.iFollowerOnlyModeSwitch,
          iSlowModeTimeS = data.iSlowModeTimeS;
        var mode = 'nolimt';
        if (iFansOnlyModeSwitch === 1) {
          mode = 'fanclub';
        }
        if (iFollowerOnlyModeSwitch === 1) {
          mode = 'follower';
        }
        nimoReport.formatAndSend('sys/broadcaststart/only_chat_mode', '系统/直播开启/发言模式', {
          mode_: mode,
          type_: type,
          uid_: auId
        });
        nimoReport.formatAndSend('sys/broadcaststart/slowmode', '系统/直播开启/慢速模式开关状态', {
          status_: "".concat(iSlowModeTimeS, "s"),
          type_: type,
          uid_: auId
        });
      });
      chatRuleModel.getChatRule().then(function (x) {
        nimoReport.formatAndSend('sys/broadcaststart/chatrules', '系统/直播开启/弹幕礼仪开关状态', {
          status_: (x === null || x === void 0 ? void 0 : x.chatRuleStatus) === 1 && x !== null && x !== void 0 && x.chatRuleText ? 'on' : 'off',
          type_: type,
          uid_: auId
        });
      });
      var localSetting = getMessageSettings(auId);
      nimoReport.formatAndSend('sys/broadcaststart/enter_room', '系统/直播开启/入场消息开关状态', {
        status_: localSetting.showEnterRoomMsg ? 'on' : 'off',
        type_: type,
        uid_: auId
      });
      nimoReport.formatAndSend('sys/broadcaststart/coin_gift_message', '系统/直播开启/金币礼物消息开关状态', {
        status_: localSetting.showCoinGiftMsg ? 'on' : 'off',
        type_: type,
        uid_: auId
      });
      nimoReport.formatAndSend('sys/broadcaststart/follow_message', '系统/直播开启/关注消息开关状态', {
        status_: localSetting.showFollowAnchorMsg ? 'on' : 'off',
        type_: type,
        uid_: auId
      });
      nimoReport.formatAndSend('sys/broadcaststart/concise', '系统/直播开启/精简弹幕模式开关状态', {
        status_: localSetting.isConcise ? 'on' : 'off',
        type_: type,
        uid_: auId
      });
    }
  }, [isLive, roomId, isStreamer, auId, type]);
  return null;
}