import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var PropsResource = function PropsResource() {
  this.sIcon = "";
  this.sAndroidSource = "";
  this.sIosSource = "";
  this.sWebSource = "";
};
PropsResource.prototype._clone = function () {
  return new Nimo.PropsResource();
};
PropsResource.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PropsResource.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PropsResource.prototype.writeTo = function (os) {
  os.writeString(0, this.sIcon);
  os.writeString(1, this.sAndroidSource);
  os.writeString(2, this.sIosSource);
  os.writeString(3, this.sWebSource);
};
PropsResource.prototype.readFrom = function (is) {
  this.sIcon = is.readString(0, false, this.sIcon);
  this.sAndroidSource = is.readString(1, false, this.sAndroidSource);
  this.sIosSource = is.readString(2, false, this.sIosSource);
  this.sWebSource = is.readString(3, false, this.sWebSource);
};
Nimo.PropsResource = PropsResource;
export default PropsResource;