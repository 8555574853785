import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetPropsListReq = function GetPropsListReq() {
  this.user = new Nimo.UserId();
  this.sLang = "";
  this.sClientType = "";
  this.sMd5 = "";
  this.iVersion = 0;
};
GetPropsListReq.prototype._clone = function () {
  return new Nimo.GetPropsListReq();
};
GetPropsListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPropsListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPropsListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeString(1, this.sLang);
  os.writeString(2, this.sClientType);
  os.writeString(3, this.sMd5);
  os.writeInt32(4, this.iVersion);
};
GetPropsListReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.sLang = is.readString(1, false, this.sLang);
  this.sClientType = is.readString(2, false, this.sClientType);
  this.sMd5 = is.readString(3, false, this.sMd5);
  this.iVersion = is.readInt32(4, false, this.iVersion);
};
Nimo.GetPropsListReq = GetPropsListReq;
export default GetPropsListReq;