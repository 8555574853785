import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var FollowStatusAndCount = function FollowStatusAndCount() {
  this.anchorId = 0;
  this.followed = 0;
  this.fanCount = 0;
};
FollowStatusAndCount.prototype._clone = function () {
  return new NimoBuss.FollowStatusAndCount();
};
FollowStatusAndCount.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowStatusAndCount.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowStatusAndCount.prototype.writeTo = function (os) {
  os.writeInt64(0, this.anchorId);
  os.writeInt32(1, this.followed);
  os.writeInt64(2, this.fanCount);
};
FollowStatusAndCount.prototype.readFrom = function (is) {
  this.anchorId = is.readInt64(0, false, this.anchorId);
  this.followed = is.readInt32(1, false, this.followed);
  this.fanCount = is.readInt64(2, false, this.fanCount);
};
NimoBuss.FollowStatusAndCount = FollowStatusAndCount;
export default FollowStatusAndCount;