import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
import './AnchorInfo';
var BlackWhiteListReq = function BlackWhiteListReq() {
  this.tUserId = new Nimo.UserId();
  this.vFunctionIDs = new Taf.Vector(new Taf.INT64());
  this.tAnchorInfo = new Nimo.AnchorInfo();
};
BlackWhiteListReq.prototype._clone = function () {
  return new Nimo.BlackWhiteListReq();
};
BlackWhiteListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BlackWhiteListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BlackWhiteListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeVector(1, this.vFunctionIDs);
  os.writeStruct(2, this.tAnchorInfo);
};
BlackWhiteListReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.vFunctionIDs = is.readVector(1, false, this.vFunctionIDs);
  this.tAnchorInfo = is.readStruct(2, false, this.tAnchorInfo);
};
Nimo.BlackWhiteListReq = BlackWhiteListReq;
export default BlackWhiteListReq;