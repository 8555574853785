import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var VisitHistoryReq = function VisitHistoryReq() {
  this.udbUserId = 0;
  this.anchorId = 0;
};
VisitHistoryReq.prototype._clone = function () {
  return new NimoBuss.VisitHistoryReq();
};
VisitHistoryReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VisitHistoryReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VisitHistoryReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
  os.writeInt64(1, this.anchorId);
};
VisitHistoryReq.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
  this.anchorId = is.readInt64(1, false, this.anchorId);
};
NimoBuss.VisitHistoryReq = VisitHistoryReq;
export default VisitHistoryReq;