/* eslint-disable no-param-reassign */
import ua from '@client/common/utils/ua';
import { getUnsafeIsWebpSupport, isWebpSupport } from '@client/common/utils/webp';
import { getDefaultImgServerUrl } from './getImgServerUrlImpl';

// 获取缩略图url
export var getThumbImgServerUrl = function getThumbImgServerUrl(src, options, transform) {
  var _transform;
  var browserName = ua.parser.getBrowserName();
  if ((browserName === 'Internet Explorer' || browserName === 'Safari' && getUnsafeIsWebpSupport() !== 1) && ((_transform = transform) === null || _transform === void 0 ? void 0 : _transform.toLowerCase()) === 'webp') {
    if (/\.webp([^/]*)?$/.test(src)) {
      // 若原图为 webp 转换成 png
      transform = 'png';
    } else {
      transform = '';
    }
  }
  return getDefaultImgServerUrl(src, options, transform);
};
export var preloadWebpCheck = function preloadWebpCheck() {
  var browserName = ua.parser.getBrowserName();
  if (browserName === 'Safari') {
    isWebpSupport();
  }
};
export * from './getImgServerUrlImpl';