import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from 'react';
import ABTest from './ABTest';

/**
 * useABTest
 *
 * 使用示例：
 * function TestComponent() {
 *  const val = useABTest('testKey'); // testKey 从 abtest 平台获取
 *  if (val === 'val1') {
 *    // do something...
 *  } else if (val === 'val2') {
 *    // do something else...
 *  }
 * }
 */
export function useABTest() {
  for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
    keys[_key] = arguments[_key];
  }
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];
  useEffect(function () {
    asyncGetABTestValueByKey.apply(void 0, keys).then(function (val) {
      if (val) {
        setValue(val);
      }
    });
  }, [keys, setValue]);
  return value;
}
export function asyncGetABTestValueByKey() {
  for (var _len2 = arguments.length, keys = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    keys[_key2] = arguments[_key2];
  }
  return ABTest.getTestConfig().then(function (config) {
    var _ref = config || {},
      mpConfig = _ref.mpConfig;
    var _value = [];
    var len = keys.length;
    for (var i = 0; i < len; i += 1) {
      var k = keys[i];
      var v = mpConfig[k];
      _value.push(v || null);
    }
    return _value;
  });
}

/**
 * 异步获取当前abtestConfig的具体配置map
 * @returns
 */
export function asyncGetABTestMap() {
  return ABTest.getTestConfig().then(function (config) {
    return (config === null || config === void 0 ? void 0 : config.mpConfig) || {};
  });
}

/**
 * 异步获取当前abtestConfig的整个返回对象
 * @returns
 */
export function asyncGetABTestConfig() {
  return ABTest.getTestConfig();
}

/**
 * 同步获取当前abtestConfig的具体配置map,首次可能未请求回来,会获取不到
 * @returns
 */
export function getABTestMap() {
  var _ABTest$getSyncTestCo;
  return ((_ABTest$getSyncTestCo = ABTest.getSyncTestConfig()) === null || _ABTest$getSyncTestCo === void 0 ? void 0 : _ABTest$getSyncTestCo.mpConfig) || {};
}
/**
 * 同步获取当前abtestConfig的整个返回对象,首次可能未请求回来,会获取不到
 * @returns
 */
export function getABTestConfig() {
  return ABTest.getSyncTestConfig() || {};
}