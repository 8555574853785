import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetPopupDataReq = function GetPopupDataReq() {
  this.tUserId = new Nimo.UserId();
  this.iStyleFilterType = 1;
};
GetPopupDataReq.prototype._clone = function () {
  return new Nimo.GetPopupDataReq();
};
GetPopupDataReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPopupDataReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPopupDataReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt32(1, this.iStyleFilterType);
};
GetPopupDataReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.iStyleFilterType = is.readInt32(1, false, this.iStyleFilterType);
};
Nimo.GetPopupDataReq = GetPopupDataReq;
export default GetPopupDataReq;