import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var PushVideoHis = function PushVideoHis() {
  this.sUrl = "";
  this.iSize = 0;
  this.iStartTime = 0;
  this.iEndTime = 0;
  this.iDuration = 0;
  this.sVid = "";
  this.sFormat = "";
  this.iTurnCodeState = 0;
  this.sTurnCodeKey = "";
  this.sVideoID = "";
  this.iCommentControl = 0;
  this.iVideoStatus = 0;
};
PushVideoHis.prototype._clone = function () {
  return new Nimo.PushVideoHis();
};
PushVideoHis.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PushVideoHis.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PushVideoHis.prototype.writeTo = function (os) {
  os.writeString(0, this.sUrl);
  os.writeInt32(1, this.iSize);
  os.writeInt32(2, this.iStartTime);
  os.writeInt32(3, this.iEndTime);
  os.writeInt32(4, this.iDuration);
  os.writeString(5, this.sVid);
  os.writeString(6, this.sFormat);
  os.writeInt32(7, this.iTurnCodeState);
  os.writeString(8, this.sTurnCodeKey);
  os.writeString(9, this.sVideoID);
  os.writeInt32(10, this.iCommentControl);
  os.writeInt32(11, this.iVideoStatus);
};
PushVideoHis.prototype.readFrom = function (is) {
  this.sUrl = is.readString(0, false, this.sUrl);
  this.iSize = is.readInt32(1, false, this.iSize);
  this.iStartTime = is.readInt32(2, false, this.iStartTime);
  this.iEndTime = is.readInt32(3, false, this.iEndTime);
  this.iDuration = is.readInt32(4, false, this.iDuration);
  this.sVid = is.readString(5, false, this.sVid);
  this.sFormat = is.readString(6, false, this.sFormat);
  this.iTurnCodeState = is.readInt32(7, false, this.iTurnCodeState);
  this.sTurnCodeKey = is.readString(8, false, this.sTurnCodeKey);
  this.sVideoID = is.readString(9, false, this.sVideoID);
  this.iCommentControl = is.readInt32(10, false, this.iCommentControl);
  this.iVideoStatus = is.readInt32(11, false, this.iVideoStatus);
};
Nimo.PushVideoHis = PushVideoHis;
export default PushVideoHis;