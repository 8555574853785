import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ESpecPropType = {
  ESPEC_PROP_ROOM: 0,
  ESPEC_PROP_COUN_GAME: 1,
  ESPEC_PROP_COUN: 2,
  ESPEC_PROP_GAME: 3,
  ESPEC_PROP_LANG: 4
};
Nimo.ESpecPropType = ESpecPropType;
export default ESpecPropType;