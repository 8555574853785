import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var SessionHistoryReq = function SessionHistoryReq() {
  this.tUserId = new Nimo.UserId();
  this.sSyncKey = "";
  this.lMaxMsgId = 0;
};
SessionHistoryReq.prototype._clone = function () {
  return new Nimo.SessionHistoryReq();
};
SessionHistoryReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SessionHistoryReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SessionHistoryReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeString(1, this.sSyncKey);
  os.writeInt64(2, this.lMaxMsgId);
};
SessionHistoryReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.sSyncKey = is.readString(1, false, this.sSyncKey);
  this.lMaxMsgId = is.readInt64(2, false, this.lMaxMsgId);
};
Nimo.SessionHistoryReq = SessionHistoryReq;
export default SessionHistoryReq;