import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
// import CommonLang from '@client/common/lang/main';
import { transformKebabCaseToCamelCase } from '@client/common/utils/string';
import { loadLangPkg, normalizeLangRegistry } from '@client/common/lang';
export var UPDATE_HOME_LANGPKG = 'update_home_langpkg';
export var UPDATE_HEADER_NAV_ACTIVE = 'update_header_nav_active';
export var UPDATE_SIDEBAR_LOCATION = 'update_sidebar_location';
export var UPDATE_PLAYER = 'update_player';
export var UPDATE_PROFILE_LANGPKG = 'update_home_profile_langpkg';
export var updateHomeLangPkg = function updateHomeLangPkg(id) {
  var _id = id || -1;
  return function (dispatch) {
    return Promise.all([loadLangPkg(require('@client/common/lang/login'), _id), loadLangPkg(require('@desktop/src/common/lang/home'), _id)]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        login = _ref2[0],
        home = _ref2[1];
      var res = {
        login: login,
        home: home
      };
      dispatch({
        type: UPDATE_HOME_LANGPKG,
        payload: res
      });
      return res;
    });
  };
};
export var updateHeaderNavActive = function updateHeaderNavActive(key) {
  return {
    type: UPDATE_HEADER_NAV_ACTIVE,
    payload: key
  };
};
export var updateSidebarLocation = function updateSidebarLocation(payload) {
  return {
    type: UPDATE_SIDEBAR_LOCATION,
    payload: payload
  };
};
export var updateHomeModuleLangPkgByRegistry = function updateHomeModuleLangPkgByRegistry(registry, id) {
  return function (dispatch) {
    var langRegistry = normalizeLangRegistry(registry);
    if (!langRegistry) {
      return Promise.reject(new Error('is not a lang registry'));
    }
    var moduleName = langRegistry.namespace.replace(/^common_/, '');
    return loadLangPkg(registry, id).then(function (langPkg) {
      dispatch({
        type: UPDATE_HOME_LANGPKG,
        payload: _defineProperty({}, transformKebabCaseToCamelCase(moduleName), langPkg)
      });
      return langPkg;
    });
  };
};
export var updateFansGroupLangPkg = function updateFansGroupLangPkg(id) {
  return updateHomeModuleLangPkgByRegistry(require('@client/common/lang/fans-group'), id);
};

// TODO: don't require profile lang
export var updateProfileLangPkg = function updateProfileLangPkg(id) {
  return function (dispatch) {
    return loadLangPkg(require('@desktop/src/common/lang/profile'), id).then(function (res) {
      dispatch({
        type: UPDATE_PROFILE_LANGPKG,
        payload: {
          profile: res
        }
      });
      return res;
    });
  };
};
export var updateHomeProfileLangPkgByRegistry = function updateHomeProfileLangPkgByRegistry(registry, id) {
  return function (dispatch) {
    return loadLangPkg(registry, id).then(function (res) {
      dispatch({
        type: UPDATE_PROFILE_LANGPKG,
        payload: {
          profile: res
        }
      });
      return res;
    });
  };
};
export var updatePlayer = function updatePlayer(payload) {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_PLAYER,
      payload: payload
    });
  };
};
export { updateCommonLangPkg } from '../common/actions';
export { initRoomInfo, initRoomGiftList, updateUserInfo, updateAccountBalance, addAccountBalanceTemp, updateMsgWhiteList, updateCurrFansBarrage, updateGiftPackNew, updateWearBadgeInfo, updateBadgeCount, initRoomActivityList, updateAnchorPkOn, updateLiveRoomViewCount, updateAnchorFollowCount, updateRoomFollowStatus, initFacialsList, resetRoomStore, updateFanGroupInfo, initVipSubscribeStatus, addGiftMsg, removeGiftMsg, setPayChatList, setAnnouncement, updateLinkMicSeats, updateLinkMicHost, updateShowRecipient, updateMGiftContributeInfos } from './room/actions';
export { initVideoInfo } from './clip/actions';
export { updateGameList } from './game/actions';