import { local } from '@client/common/utils/storage';
import { setGreyGroupOverrideByUrl } from '@client/common/utils/greyGroup';
export function setStreamGreyGroup() {
  var gg = local.get('nimo_player_stream_20210318');
  gg = gg && parseInt(gg, 10);
  return gg === 0 ? 0 : 1; // 设置 0 才能使用业务流
}
export function setStreamGreyGroupForWap() {
  var gg = setGreyGroupOverrideByUrl('nimo_player_stream_20210318', 0, 1); // nimostream:hystream = 0:1
  return gg === 0 ? 0 : 1; // 设置 0 才能使用业务流
}