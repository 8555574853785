import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var LiveSetting = function LiveSetting() {
  this.lRoomType = 0;
  this.mRoomTypeNameLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sRoomTheme = "";
  this.iLcid = 0;
  this.iTemplateType = 0;
  this.iBusinessType = 0;
  this.iRoomSort = 0;
};
LiveSetting.prototype._clone = function () {
  return new Nimo.LiveSetting();
};
LiveSetting.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveSetting.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveSetting.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomType);
  os.writeMap(1, this.mRoomTypeNameLang);
  os.writeString(2, this.sRoomTheme);
  os.writeInt32(3, this.iLcid);
  os.writeInt32(4, this.iTemplateType);
  os.writeInt32(5, this.iBusinessType);
  os.writeInt32(6, this.iRoomSort);
};
LiveSetting.prototype.readFrom = function (is) {
  this.lRoomType = is.readInt64(0, false, this.lRoomType);
  this.mRoomTypeNameLang = is.readMap(1, false, this.mRoomTypeNameLang);
  this.sRoomTheme = is.readString(2, false, this.sRoomTheme);
  this.iLcid = is.readInt32(3, false, this.iLcid);
  this.iTemplateType = is.readInt32(4, false, this.iTemplateType);
  this.iBusinessType = is.readInt32(5, false, this.iBusinessType);
  this.iRoomSort = is.readInt32(6, false, this.iRoomSort);
};
Nimo.LiveSetting = LiveSetting;
export default LiveSetting;