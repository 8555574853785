import { cookie } from '@client/common/utils/storage';
import { formatReportObj, REPORT_COOKIE_KEYS } from '@client/common/lib/dataReport/index';
import nimoReport from '@client/common/services/report';
nimoReport.bindPcEvent();

/**
 * 调起登录原因映射表
 * 
 * @constant
 * @see 映射关系详见埋点文档 https://docs.google.com/spreadsheets/d/1rzDsR404QbL1fhJyLNcqO_SL-18iJkbc1LbDDufvUPY/edit#gid=1975616580
 */
export var popupLoginTypeMap = {
  BARRAGE: 'barrage',
  GIFTS: 'gifts',
  FOLLOW: 'fllow',
  RECHARGE: 'recharge',
  REPORT: 'report_bt',
  TIPS_1: 'tips1',
  TIPS_2: 'tips2',
  FULLSCREEN: 'fullscreen',
  BULLET_1: 'bullet1',
  BULLET_2: 'bullet2',
  NOLIVE: 'nolive',
  MATCH_NO_LIVE: 'matchnolive',
  LOGIN_TIP_1: 'logintips1',
  LOGIN_TIP_2: 'logintips2',
  LOGINWINDOW: 'loginwindow',
  COINS: 'coins',
  FEEDBACK: 'feedback',
  BULLET_REPORT: 'bulletreport',
  HOME: 'home',
  HOVER: 'hover',
  PICK_ME: 'pickme'
};

/**
 * 拉起登录弹窗埋点
 *
 * @param {string} from 拉起原因
 *
 * fllow	关注主播
 * barrage	评论弹幕
 * login_bt	登录按钮
 * report_bt	举报按钮
 * account_expiration	登录过期唤醒
 * recharge	充值拉起
 * gifts	送礼拉起
 * tips1	引导关注非全屏
 * tips2	引导关注全屏
 * fullscreen	全屏关注按钮
 * bullet1	非全屏弹幕关注
 * bullet2	全屏弹幕关注
 * nolive	不在播关注
 * matchnolive	赛事不在播关注
 * logintips1	hover时的登录引导提示
 * logintips2	主播引导用户登录
 * loginwindow	清晰度限制登录
 * coins	金币宝箱
 * feedback	用户反馈
 * bulletdraw	弹幕抽奖
 * sharedraw	分享抽奖
 * bulletreport 弹幕举报
 * home web首页登录引导
 * hover 直播间主播等级hover内容的“查看详情”
 */
export function reportPopupLogin(from) {
  var loginFrom = REPORT_COOKIE_KEYS.loginFrom;
  var reportObj = formatReportObj('login_alert', '拉起登录弹框', {
    from_: from
  });
  cookie.set(loginFrom, from); // 缓存拉起原因，用于点击登录上报
  nimoReport.report(reportObj);
}

/**
 * 进入直播间
 *
 * @param {string} from 进入来源
 *
 * home_player	首页播放器
 * home_column	首页栏目
 * header_heristory	导航历史
 * header_fllow	导航关注
 * game_roomlist_page	单游戏直播间列表
 * all_roomlist_page	全部直播页面
 * me_fllow_list	我的关注列表
 * /	直接访问
 */
export function reportOpenLiveRoom(from, type) {
  var reportObj = formatReportObj('open_liveroom', '进入直播间', {
    from_: from,
    type_: type
  });
  nimoReport.report(reportObj);
}
export default nimoReport;

/**
 * 获取直播间当前活动状态
 * pk  正在 pk（如果正在连麦，优于连麦）
 * line 正在连麦
 * normal 普通
 */
export function getRoomActivityState() {
  // eslint-disable-next-line no-nested-ternary
  return window.room_pk_state === 1 ? 'pk' : window.room_connect_state === 1 ? 'line' : 'normal';
}