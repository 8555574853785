import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetUserProfileReq = function GetUserProfileReq() {
  this.tUserId = new Nimo.UserId();
  this.vArgs = new Taf.Vector(new Taf.STRING());
};
GetUserProfileReq.prototype._clone = function () {
  return new Nimo.GetUserProfileReq();
};
GetUserProfileReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetUserProfileReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetUserProfileReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeVector(1, this.vArgs);
};
GetUserProfileReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.vArgs = is.readVector(1, false, this.vArgs);
};
Nimo.GetUserProfileReq = GetUserProfileReq;
export default GetUserProfileReq;