/**
 * 路由路径配置
 * index 模块主页面路由
 * langKey 模块 title key
 */
export var routePathConf = {
  home: {
    index: '/i/home',
    langKey: 'dashboard.home'
  },
  streamManager: {
    index: '/i/stream-manager',
    langKey: 'dashboard.streamManager'
  },
  channelAnalytics: {
    index: '/i/channel-analytics',
    langKey: 'dashboard.channelAnalytics'
  },
  stream: {
    index: '/i/stream-url',
    langKey: 'dashboard.stream'
  },
  video: {
    index: '/i/video',
    langKey: 'dashboard.videoClips'
  },
  videoUpload: {
    index: '/i/video-upload',
    langKey: 'dashboard.videoClips'
  },
  fansClub: {
    index: '/i/fans-club',
    langKey: 'dashboard.fanClub'
  },
  anchorLevel: {
    index: '/i/anchor-level',
    langKey: 'dashboard.level'
  },
  liveEnd: {
    index: '/i/live-end',
    langKey: 'dashboard.streamSummary'
  },
  streamTargets: {
    index: '/i/stream-targets',
    langKey: 'dashboard.multiStreamShare'
  },
  followers: {
    index: '/i/followers',
    langKey: 'dashboard.myFollowers'
  },
  streamerCamp: {
    index: '/i/streamercamp',
    langKey: 'dashboard.camp',
    createReg: function createReg() {
      return /^\/i\/streamercamp\b/;
    }
  },
  streamerCampQuickStart: {
    index: '/i/streamercamp/quickstart',
    langKey: 'dashboard.quickstart'
  },
  streamerCampResources: {
    index: '/i/streamercamp/resources',
    langKey: 'dashboard.resources'
  },
  streamerCampMonetize: {
    index: '/i/streamercamp/monetize',
    langKey: 'dashboard.camp_monetize'
  },
  streamerCampBranding: {
    index: '/i/streamercamp/branding',
    langKey: 'dashboard.camp_branding'
  },
  streamerCampContent: {
    index: '/i/streamercamp/content',
    langKey: 'dashboard.camp_content'
  },
  streamerCampCommunity: {
    index: '/i/streamercamp/community',
    langKey: 'dashboard.camp_community'
  },
  schedule: {
    index: '/i/live-preview',
    langKey: 'dashboard.my_schedule'
  },
  myGrowth: {
    index: '/i/my-growth',
    langKey: 'dashboard.mygrowth'
  },
  channel: {
    index: '/i/channel',
    langKey: 'dashboard.channel'
  },
  actApplication: {
    index: '/i/act-application',
    langKey: 'dashboard.eventApplication'
  },
  notFound: {
    index: '',
    createReg: function createReg() {
      return /.*/;
    }
  }
};
Object.keys(routePathConf).forEach(function (key) {
  routePathConf[key].key = key;
  if (key !== 'streamManager') {
    // false-不显示小窗，其他值暂无效
    routePathConf[key].miniPlayerSwitch = false;
    routePathConf[key].startupWindow = true;
  }
});
var createReg = function createReg(path, customCreateReg) {
  if (typeof customCreateReg === 'function') {
    return customCreateReg();
  } else if (typeof path === 'string') {
    return new RegExp("^".concat(path.replace(/:[\w-_]+(?=\/?)/g, function () {
      return '[\\w-_]+';
    }), "(\\/)?$"));
  } else {
    return null;
  }
};
export var getCurRoutePath = function getCurRoutePath(pathname) {
  return Object.values(routePathConf).find(function (route) {
    return pathname.match(createReg(route.index, route.createReg));
  });
};
export var getCurRouteKey = function getCurRouteKey(pathname) {
  return Object.keys(routePathConf).find(function (routeKey) {
    var route = routePathConf[routeKey] || {};
    return pathname.match(createReg(route.index, route.createReg));
  });
};