import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var AnchorLevelDetailByUid = function AnchorLevelDetailByUid() {
  this.lUid = 0;
  this.user = new Nimo.UserId();
};
AnchorLevelDetailByUid.prototype._clone = function () {
  return new Nimo.AnchorLevelDetailByUid();
};
AnchorLevelDetailByUid.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelDetailByUid.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelDetailByUid.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeStruct(1, this.user);
};
AnchorLevelDetailByUid.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.user = is.readStruct(1, false, this.user);
};
Nimo.AnchorLevelDetailByUid = AnchorLevelDetailByUid;
export default AnchorLevelDetailByUid;