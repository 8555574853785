import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var SimpleLiveRoomView = function SimpleLiveRoomView() {
  this.id = 0;
  this.roomTheme = "";
  this.roomType = 0;
  this.roomTypeName = "";
  this.liveStreamStatus = 0;
  this.anchorId = 0;
  this.anchorAvatarUrl = "";
  this.fansCount = 0;
  this.templateType = 0;
  this.anchorName = "";
};
SimpleLiveRoomView.prototype._clone = function () {
  return new NimoBuss.SimpleLiveRoomView();
};
SimpleLiveRoomView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SimpleLiveRoomView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SimpleLiveRoomView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.id);
  os.writeString(1, this.roomTheme);
  os.writeInt64(2, this.roomType);
  os.writeString(3, this.roomTypeName);
  os.writeInt32(4, this.liveStreamStatus);
  os.writeInt64(5, this.anchorId);
  os.writeString(6, this.anchorAvatarUrl);
  os.writeInt32(7, this.fansCount);
  os.writeInt32(8, this.templateType);
  os.writeString(9, this.anchorName);
};
SimpleLiveRoomView.prototype.readFrom = function (is) {
  this.id = is.readInt64(0, false, this.id);
  this.roomTheme = is.readString(1, false, this.roomTheme);
  this.roomType = is.readInt64(2, false, this.roomType);
  this.roomTypeName = is.readString(3, false, this.roomTypeName);
  this.liveStreamStatus = is.readInt32(4, false, this.liveStreamStatus);
  this.anchorId = is.readInt64(5, false, this.anchorId);
  this.anchorAvatarUrl = is.readString(6, false, this.anchorAvatarUrl);
  this.fansCount = is.readInt32(7, false, this.fansCount);
  this.templateType = is.readInt32(8, false, this.templateType);
  this.anchorName = is.readString(9, false, this.anchorName);
};
NimoBuss.SimpleLiveRoomView = SimpleLiveRoomView;
export default SimpleLiveRoomView;