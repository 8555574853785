import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './ForbidMessageLogRecord';
var GetMessageBannedOperateRsp = function GetMessageBannedOperateRsp() {
  this.iCode = 0;
  this.lRoomId = 0;
  this.lUserUDBId = 0;
  this.vRecordList = new Taf.Vector(new Nimo.ForbidMessageLogRecord());
  this.bIsFinish = true;
};
GetMessageBannedOperateRsp.prototype._clone = function () {
  return new Nimo.GetMessageBannedOperateRsp();
};
GetMessageBannedOperateRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMessageBannedOperateRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMessageBannedOperateRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lUserUDBId);
  os.writeVector(3, this.vRecordList);
  os.writeBoolean(4, this.bIsFinish);
};
GetMessageBannedOperateRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lUserUDBId = is.readInt64(2, false, this.lUserUDBId);
  this.vRecordList = is.readVector(3, false, this.vRecordList);
  this.bIsFinish = is.readBoolean(4, false, this.bIsFinish);
};
Nimo.GetMessageBannedOperateRsp = GetMessageBannedOperateRsp;
export default GetMessageBannedOperateRsp;