// import React from 'react';
import { replaceLangVars, replaceLangDollar } from '@client/common/utils/string';
export var getShareKey = function getShareKey(docType) {
  var shareBySelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var index = +shareBySelf;
  // 运营打点 / 运营上传-游戏专区 / 运营实时剪辑 / 运营后台上传视频 / 有趣视频
  if ([2, 5, 8, 11, 12].includes(docType)) {
    return 'operation_share_dot';
  }
  // 直播录像
  else if (docType === 1) {
    return ['record_page_share_by_other', 'record_page_share_by_owner'][index];
  }
  // 主播剪辑 / 用户剪辑
  else if ([3, 4].includes(docType)) {
    return ['clip_video_share_by_other', 'clip_video_share_by_owner'][index];
  }
  // 用户上传 / 授权上传：
  else if ([6, 7].includes(docType)) {
    return ['user_upload_video_share_by_other', 'user_upload_video_share_by_owner'][index];
  }
  return 'video_share_default';
};
var getShareText = function getShareText(_ref) {
  var shareDynamicInfo = _ref.shareDynamicInfo,
    abtestParam = _ref.abtestParam,
    defaultLangKey = _ref.defaultLangKey,
    lang = _ref.lang;
  if (shareDynamicInfo) {
    var _shareDynamicInfo$abt, _shareDynamicInfo$def;
    var multiLangMap = abtestParam && ((_shareDynamicInfo$abt = shareDynamicInfo[abtestParam]) === null || _shareDynamicInfo$abt === void 0 ? void 0 : _shareDynamicInfo$abt.multiLangMap) || ((_shareDynamicInfo$def = shareDynamicInfo[defaultLangKey]) === null || _shareDynamicInfo$def === void 0 ? void 0 : _shareDynamicInfo$def.multiLangMap) || {};
    if (multiLangMap[lang]) {
      return multiLangMap[lang];
    } else {
      return multiLangMap['1033'] || '';
    }
  }
  return '';
};
export var getShareTextGuidewindow = function getShareTextGuidewindow(_ref2) {
  var shareDynamicInfo = _ref2.shareDynamicInfo,
    abtestParam = _ref2.abtestParam,
    defaultLangKey = _ref2.defaultLangKey,
    lang = _ref2.lang;
  var shareLangObj = {};
  ['title', 'content', 'button'].map(function (l) {
    if (shareDynamicInfo) {
      var _shareDynamicInfo, _shareDynamicInfo2;
      var multiLangMap = abtestParam && ((_shareDynamicInfo = shareDynamicInfo["".concat(abtestParam, "_").concat(l)]) === null || _shareDynamicInfo === void 0 ? void 0 : _shareDynamicInfo.multiLangMap) || ((_shareDynamicInfo2 = shareDynamicInfo["".concat(defaultLangKey, "_").concat(l)]) === null || _shareDynamicInfo2 === void 0 ? void 0 : _shareDynamicInfo2.multiLangMap) || {};
      if (multiLangMap[lang]) {
        shareLangObj["".concat(abtestParam || defaultLangKey, "_").concat(l)] = multiLangMap[lang];
      } else if (multiLangMap['1033']) {
        shareLangObj["".concat(abtestParam || defaultLangKey, "_").concat(l)] = multiLangMap['1033'];
      }
    }
    return null;
  });
  return shareLangObj;
};
export var getClipFinalShareText = function getClipFinalShareText(_ref3) {
  var video = _ref3.video,
    finalDes3 = _ref3.finalDes3,
    ShareParam = _ref3.ShareParam,
    finalDes2 = _ref3.finalDes2,
    langPkg = _ref3.langPkg;
  var content = '';
  if ((video === null || video === void 0 ? void 0 : video.dotType) === 1) {
    content = replaceLangDollar(finalDes3, ShareParam);
  } else if ((video === null || video === void 0 ? void 0 : video.dotType) === 4) {
    content = replaceLangDollar(finalDes2, ShareParam);
  } else {
    content = replaceLangVars(langPkg === null || langPkg === void 0 ? void 0 : langPkg.clip_share_text3_A, [video === null || video === void 0 ? void 0 : video.gameName, video === null || video === void 0 ? void 0 : video.title]);
  }
  return content;
};
export var getFinalShareText = function getFinalShareText(_ref4) {
  var finalDes = _ref4.finalDes,
    ShareParam = _ref4.ShareParam;
  return replaceLangDollar(finalDes, ShareParam);
};
export default getShareText;