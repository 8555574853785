import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorLevelConfig = function AnchorLevelConfig() {
  this.iLevel = 0;
  this.iExp = 0;
  this.sMedal = "";
};
AnchorLevelConfig.prototype._clone = function () {
  return new Nimo.AnchorLevelConfig();
};
AnchorLevelConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iLevel);
  os.writeInt32(1, this.iExp);
  os.writeString(2, this.sMedal);
};
AnchorLevelConfig.prototype.readFrom = function (is) {
  this.iLevel = is.readInt32(0, false, this.iLevel);
  this.iExp = is.readInt32(1, false, this.iExp);
  this.sMedal = is.readString(2, false, this.sMedal);
};
Nimo.AnchorLevelConfig = AnchorLevelConfig;
export default AnchorLevelConfig;