import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var BlackWhiteListMatchInfo = function BlackWhiteListMatchInfo() {
  this.iField = 0;
  this.sMatchReason = "";
  this.sMatchKey = "";
  this.sTimeReason = "";
};
BlackWhiteListMatchInfo.prototype._clone = function () {
  return new Nimo.BlackWhiteListMatchInfo();
};
BlackWhiteListMatchInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BlackWhiteListMatchInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BlackWhiteListMatchInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iField);
  os.writeString(1, this.sMatchReason);
  os.writeString(2, this.sMatchKey);
  os.writeString(3, this.sTimeReason);
};
BlackWhiteListMatchInfo.prototype.readFrom = function (is) {
  this.iField = is.readInt32(0, false, this.iField);
  this.sMatchReason = is.readString(1, false, this.sMatchReason);
  this.sMatchKey = is.readString(2, false, this.sMatchKey);
  this.sTimeReason = is.readString(3, false, this.sTimeReason);
};
Nimo.BlackWhiteListMatchInfo = BlackWhiteListMatchInfo;
export default BlackWhiteListMatchInfo;