import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var BookParam = function BookParam() {
  this.baseParam = new NimoBuss.BaseParam();
  this.anchorId = 0;
  this.nextLiveNoticeTimestamp = 0;
};
BookParam.prototype._clone = function () {
  return new NimoBuss.BookParam();
};
BookParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BookParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BookParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.anchorId);
  os.writeInt64(2, this.nextLiveNoticeTimestamp);
};
BookParam.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.anchorId = is.readInt64(1, false, this.anchorId);
  this.nextLiveNoticeTimestamp = is.readInt64(2, false, this.nextLiveNoticeTimestamp);
};
NimoBuss.BookParam = BookParam;
export default BookParam;