import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './PopupData';
var GetPopupDataRsp = function GetPopupDataRsp() {
  this.vPopupDataList = new Taf.Vector(new Nimo.PopupData());
};
GetPopupDataRsp.prototype._clone = function () {
  return new Nimo.GetPopupDataRsp();
};
GetPopupDataRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPopupDataRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPopupDataRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.vPopupDataList);
};
GetPopupDataRsp.prototype.readFrom = function (is) {
  this.vPopupDataList = is.readVector(0, false, this.vPopupDataList);
};
Nimo.GetPopupDataRsp = GetPopupDataRsp;
export default GetPopupDataRsp;