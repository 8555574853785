function padLeftZero(str) {
  return "00".concat(str).substr(str.length);
}
export default function formatDate(date, fmt) {
  if (typeof date === 'number') {
    date = new Date(date);
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, "".concat(date.getFullYear()).substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'd+': date.getDate(),
    'H+': date.getHours(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };

  // 遍历这个对象
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = "".concat(o[k]);
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}

/**
 * 复制 schedule-reserve.jsx里的同名函数
 * 用于将一段时间转为 分 秒 格式
 *
 * @param {number} duration 需要换算的时间，单位s
 * @returns {object} 返回如{minute: '130', second: '41'}的数据
 */
export function calcTimeLeft(duration) {
  var time = Math.floor(duration);
  var UNITS = ['minute', 'second'];
  var values = {};
  [60, 1].forEach(function (seconds, i) {
    var value = Math.floor(time / seconds);
    values[UNITS[i]] = paddingZero(value);
    time -= value * seconds;
  });
  return values;
}
function paddingZero(num) {
  if (Number.isNaN(num)) {
    return '00';
  }
  if (num < 10) {
    return "0".concat(num);
  } else {
    return "".concat(num);
  }
}