import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var LiveInfo = function LiveInfo() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.lLiveId = 0;
  this.iLiveType = 0;
  this.lEntranceFee = 0;
};
LiveInfo.prototype._clone = function () {
  return new Nimo.LiveInfo();
};
LiveInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeInt64(2, this.lLiveId);
  os.writeInt32(3, this.iLiveType);
  os.writeInt64(4, this.lEntranceFee);
};
LiveInfo.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.lLiveId = is.readInt64(2, false, this.lLiveId);
  this.iLiveType = is.readInt32(3, false, this.iLiveType);
  this.lEntranceFee = is.readInt64(4, false, this.lEntranceFee);
};
Nimo.LiveInfo = LiveInfo;
export default LiveInfo;