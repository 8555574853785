import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var ChangeSessionMuteStatusReq = function ChangeSessionMuteStatusReq() {
  this.tUserId = new Nimo.UserId();
  this.lSessionId = 0;
  this.iNewMuteStatus = 0;
};
ChangeSessionMuteStatusReq.prototype._clone = function () {
  return new Nimo.ChangeSessionMuteStatusReq();
};
ChangeSessionMuteStatusReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ChangeSessionMuteStatusReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ChangeSessionMuteStatusReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lSessionId);
  os.writeInt32(2, this.iNewMuteStatus);
};
ChangeSessionMuteStatusReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lSessionId = is.readInt64(1, false, this.lSessionId);
  this.iNewMuteStatus = is.readInt32(2, false, this.iNewMuteStatus);
};
Nimo.ChangeSessionMuteStatusReq = ChangeSessionMuteStatusReq;
export default ChangeSessionMuteStatusReq;