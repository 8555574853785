import { cookie } from '@client/common/utils/storage';
import { roomEnter as commonRoomEnter, navigateMainDomain, getLang, getClang, getTheme, getMid } from '@client/common/utils/fn';
import { getPageTheme } from '@client/common/utils/getPageTheme';
export { navigateMainDomain };

// 判断是否应该显示内容语言提示
export var isShowClangTip = function isShowClangTip(item, LCID) {
  var clang = getClang();
  // eslint-disable-next-line eqeqeq
  if (item.lcidText && (LCID != clang || LCID == '1000')) {
    return item.lcidText;
  } else {
    return '';
  }
};

// 判断进入直播间是否使用别名路径
export var roomEnterURL = function roomEnterURL(param) {
  var alise = (param === null || param === void 0 ? void 0 : param.alise) || (param === null || param === void 0 ? void 0 : param.alias);
  var roomId = getRoomId(param);
  return alise ? "/".concat(alise) : "/live/".concat(roomId);
};

// 获取房间ID，兼容接口
export var getRoomId = function getRoomId(data) {
  return (data === null || data === void 0 ? void 0 : data.roomId) || (data === null || data === void 0 ? void 0 : data.roomID) || (data === null || data === void 0 ? void 0 : data.roomid) || (data === null || data === void 0 ? void 0 : data.id);
};
export var roomEnter = commonRoomEnter;

/**
 * 判断当前直播间类型字符串（埋点统计用）
 * 因 首页模板是ejs+php混用，故首页模板用php函数处理
 * @param  {number|string} roomSort 直播间类型
 * @return {string}                 直播间类型字符串
 */
export var getRoomType = function getRoomType(roomSort) {
  var map = {
    0: 'normal',
    1: 'match',
    2: 'team',
    3: 'star'
  };
  return map[roomSort] || 'normal';
};
export var checkIsMatchRoom = function checkIsMatchRoom(roomSort) {
  return roomSort && roomSort === 1;
};
export var getGameDocSrc = function getGameDocSrc(theme) {
  var lang = getLang();
  // eslint-disable-next-line eqeqeq
  var langId = lang == '1033' || !lang ? '1000' : lang;
  var themeParam = theme ? "?_theme=".concat(theme) : "?_theme=".concat(getTheme());
  return "".concat(DOMAINS.article_domain, "/p/").concat(langId, "/host-recruitment-activity100-rules.html").concat(themeParam);
};
export var getDailyLotteryDocSrc = function getDailyLotteryDocSrc() {
  var lang = getLang();
  return "".concat(DOMAINS.article_domain, "/p/").concat(lang, "/lucky-draw-rule.html");
};
export var getVoteDocSrc = function getVoteDocSrc(theme) {
  var lang = getLang();
  return "".concat(DOMAINS.article_domain, "/p/").concat(lang, "/streamervote.html?_theme=").concat(theme || getTheme());
};

// 粉丝说明文章地址
export var getFanDescDocSrc = function getFanDescDocSrc(lang, theme) {
  return "".concat(DOMAINS.article_domain, "/p/").concat(lang || getLang(), "/fan-description.html?_theme=").concat(theme || getTheme());
};
export var getFeedbackDocSrc = function getFeedbackDocSrc() {
  var udb_uid = cookie.get('udbUserId');
  var udb_version = cookie.get('version');
  var udb_biztoken = cookie.get('bizToken');
  var udb_passport = '';
  var iframeParams = '';

  // 兼容cookie拿不到的情况
  if (udb_uid && udb_version && udb_biztoken) {
    udb_uid = encodeURIComponent(udb_uid);
    udb_version = encodeURIComponent(udb_version);
    udb_biztoken = encodeURIComponent(udb_biztoken);
    iframeParams += "&udb_uid=".concat(udb_uid, "&udb_version=").concat(udb_version, "&udb_biztoken=").concat(udb_biztoken, "&udb_passport=").concat(udb_passport);
  }
  var lcid = getLang();
  // 获取当前页面的主题而不是cookie的
  var _theme = getPageTheme();
  var mid = getMid();
  // 链接已有参数，用&拼接后续参数
  iframeParams += "&lcid=".concat(lcid, "&_theme=").concat(_theme, "&mid=").concat(mid);
  return "".concat(DOMAINS.feedback_domain).concat(iframeParams);
};

// 发起竞猜介绍文章地址
export var getLaunchGuessDocSrc = function getLaunchGuessDocSrc(lang, theme) {
  return "".concat(DOMAINS.article_domain, "/p/").concat(lang || getLang(), "/streamer_bet_introduce.html?_theme=").concat(theme || getTheme());
};