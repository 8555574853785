import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var CdnUrlInfo = function CdnUrlInfo() {
  this.imediaType = 0;
  this.smediaUrl = "";
};
CdnUrlInfo.prototype._clone = function () {
  return new Nimo.CdnUrlInfo();
};
CdnUrlInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CdnUrlInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CdnUrlInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.imediaType);
  os.writeString(1, this.smediaUrl);
};
CdnUrlInfo.prototype.readFrom = function (is) {
  this.imediaType = is.readInt32(0, false, this.imediaType);
  this.smediaUrl = is.readString(1, false, this.smediaUrl);
};
Nimo.CdnUrlInfo = CdnUrlInfo;
export default CdnUrlInfo;