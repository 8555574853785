/**
 * 为减少替换的成本和 moment 的用法基本保持一致
 * set、get 以及初始化时 month 都是实际的月份而不是 月份 - 1
 * format 格式化： 一般情况下都使用使用内置 dateStyle、timeStyle。参数各自有：full，long，medium，short
 * https://shimo.im/docs/jYkVcykkk3Tkr9Qt
 * 缩写为：dStS => dateStyle: short,timeStyle: short
 * 自定义格式化：Y年 M月 D日 H时 m分 S秒 组合的格式化字符串，可接 - 表示参数 n:numeric s:short 2:2-digit N:narrow l:long
 * 但是注意就算设置的参数不为 numeric，在不支持 locale api 的浏览器也只会输出为数字的格式化版本
 * 在加大类的格式化时记得补充 fallbackFormatType
 * 在没有特殊的要求的情况下，尽量使用内置的格式类型。
 * diff 返回两个时间的相差值，用法与moment一致，但是不支持 weeks 纬度
 * daysInMonth 返回当前时间月份有多少天
 * get 获取日期值，参数为：year\month|date|day|hour|minute
 * set 设定日期值，参数与 get 方法一致
 * add 和 set 的单位支持 YYYY、Y、year、years|month、months、MM、M|date、dates、DD、D|hour、hours、HH、H|minute、minutes、m、mm|second、seconds、ss、s
 * add 加法 与 moment.add 用法一致
 * subtract 减法 与 moment.subtract 用法一致
 * getTime 返回时间戳
 * utcOffset 设定时区 只接受小时整数，如：+8 -8
 */
import timei18n from '@nimo-fed/timei18n';
import auth from '@client/common/services/auth';
import ua from '@client/common/utils/ua';
import { getLang } from './cookieFn';
import { getUrlParmasLast } from './getUrlParams';
var urlLang = getUrlParmasLast('_lang');
var isInNimoApp = ua.isInNimoApp;
var langCode = null;
if (!isInNimoApp) {
  // 非app内直接读cookie拿lang
  langCode = getLang();
} else if (urlLang) {
  // url 有 _lang 直接读取
  langCode = urlLang;
} else {
  var _auth$getPureCache;
  var cacheLangCode = ((_auth$getPureCache = auth.getPureCache()) === null || _auth$getPureCache === void 0 ? void 0 : _auth$getPureCache.lang) || 1033;
  // app 异步获取语言码时先默认初始化英文格式化
  timei18n.locale(cacheLangCode);
}
var _p = Promise.resolve({
  lang: 0
});
if (!langCode) {
  // 只有 app 内且url没有带_lang时才通过js桥异步获取
  _p = auth.getUserInfo();
} else {
  timei18n.locale(langCode);
}
// 默认全局统一初始化
_p.then(function (_ref) {
  var lang = _ref.lang;
  if (lang) {
    timei18n.locale(lang);
  }
});
export var loadLang = _p;
if (DOMAINS.isDebug === 'true') {
  window.timei18n = timei18n;
}
export { timei18n };