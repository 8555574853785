import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var BannerButtonInfo = function BannerButtonInfo() {
  this.mButtonText = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iActionType = 0;
  this.sActionData = "";
};
BannerButtonInfo.prototype._clone = function () {
  return new Nimo.BannerButtonInfo();
};
BannerButtonInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BannerButtonInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BannerButtonInfo.prototype.writeTo = function (os) {
  os.writeMap(0, this.mButtonText);
  os.writeInt32(1, this.iActionType);
  os.writeString(2, this.sActionData);
};
BannerButtonInfo.prototype.readFrom = function (is) {
  this.mButtonText = is.readMap(0, false, this.mButtonText);
  this.iActionType = is.readInt32(1, false, this.iActionType);
  this.sActionData = is.readString(2, false, this.sActionData);
};
Nimo.BannerButtonInfo = BannerButtonInfo;
export default BannerButtonInfo;