import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorLevelCareerRsp = function AnchorLevelCareerRsp() {
  this.iCode = 0;
  this.sMessage = "";
  this.lBeAnchorDays = 0;
  this.lFollowerCnt = 0;
  this.lContributorCnt = 0;
};
AnchorLevelCareerRsp.prototype._clone = function () {
  return new Nimo.AnchorLevelCareerRsp();
};
AnchorLevelCareerRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelCareerRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelCareerRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sMessage);
  os.writeInt64(2, this.lBeAnchorDays);
  os.writeInt64(3, this.lFollowerCnt);
  os.writeInt64(4, this.lContributorCnt);
};
AnchorLevelCareerRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sMessage = is.readString(1, false, this.sMessage);
  this.lBeAnchorDays = is.readInt64(2, false, this.lBeAnchorDays);
  this.lFollowerCnt = is.readInt64(3, false, this.lFollowerCnt);
  this.lContributorCnt = is.readInt64(4, false, this.lContributorCnt);
};
Nimo.AnchorLevelCareerRsp = AnchorLevelCareerRsp;
export default AnchorLevelCareerRsp;