import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GetMessageModeRsp = function GetMessageModeRsp() {
  this.iCode = 0;
  this.iFansOnlyModeSwitch = 0;
  this.iSlowModeTimeS = 0;
  this.iFollowerOnlyModeSwitch = 0;
  this.iLimitValue = 0;
  this.sErrMsg = "";
  this.iCanUseFansOnly = 0;
  this.iFansOnlyLevel = 0;
  this.iNoLimitRoom = 0;
  this.iChatModeSwitch = 0;
  this.iModeLimitValue = 0;
};
GetMessageModeRsp.prototype._clone = function () {
  return new Nimo.GetMessageModeRsp();
};
GetMessageModeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMessageModeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMessageModeRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt32(1, this.iFansOnlyModeSwitch);
  os.writeInt32(2, this.iSlowModeTimeS);
  os.writeInt32(3, this.iFollowerOnlyModeSwitch);
  os.writeInt32(4, this.iLimitValue);
  os.writeString(5, this.sErrMsg);
  os.writeInt32(6, this.iCanUseFansOnly);
  os.writeInt32(7, this.iFansOnlyLevel);
  os.writeInt32(8, this.iNoLimitRoom);
  os.writeInt32(9, this.iChatModeSwitch);
  os.writeInt32(10, this.iModeLimitValue);
};
GetMessageModeRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.iFansOnlyModeSwitch = is.readInt32(1, false, this.iFansOnlyModeSwitch);
  this.iSlowModeTimeS = is.readInt32(2, false, this.iSlowModeTimeS);
  this.iFollowerOnlyModeSwitch = is.readInt32(3, false, this.iFollowerOnlyModeSwitch);
  this.iLimitValue = is.readInt32(4, false, this.iLimitValue);
  this.sErrMsg = is.readString(5, false, this.sErrMsg);
  this.iCanUseFansOnly = is.readInt32(6, false, this.iCanUseFansOnly);
  this.iFansOnlyLevel = is.readInt32(7, false, this.iFansOnlyLevel);
  this.iNoLimitRoom = is.readInt32(8, false, this.iNoLimitRoom);
  this.iChatModeSwitch = is.readInt32(9, false, this.iChatModeSwitch);
  this.iModeLimitValue = is.readInt32(10, false, this.iModeLimitValue);
};
Nimo.GetMessageModeRsp = GetMessageModeRsp;
export default GetMessageModeRsp;