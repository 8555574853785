import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './RichLabel';
var RichText = function RichText() {
  this.mContent = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mRichLabel = new Taf.Map(new Taf.STRING(), new Nimo.RichLabel());
  this.mFontColor = new Taf.Map(new Taf.INT32(), new Taf.STRING());
};
RichText.prototype._clone = function () {
  return new Nimo.RichText();
};
RichText.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RichText.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RichText.prototype.writeTo = function (os) {
  os.writeMap(0, this.mContent);
  os.writeMap(1, this.mRichLabel);
  os.writeMap(2, this.mFontColor);
};
RichText.prototype.readFrom = function (is) {
  this.mContent = is.readMap(0, false, this.mContent);
  this.mRichLabel = is.readMap(1, false, this.mRichLabel);
  this.mFontColor = is.readMap(2, false, this.mFontColor);
};
Nimo.RichText = RichText;
export default RichText;