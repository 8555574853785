import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './LiveInfo';
import './LiveSetting';
var LiveBeginNotice = function LiveBeginNotice() {
  this.tLiveInfo = new Nimo.LiveInfo();
  this.tLiveSet = new Nimo.LiveSetting();
};
LiveBeginNotice.prototype._clone = function () {
  return new Nimo.LiveBeginNotice();
};
LiveBeginNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveBeginNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveBeginNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tLiveInfo);
  os.writeStruct(1, this.tLiveSet);
};
LiveBeginNotice.prototype.readFrom = function (is) {
  this.tLiveInfo = is.readStruct(0, false, this.tLiveInfo);
  this.tLiveSet = is.readStruct(1, false, this.tLiveSet);
};
Nimo.LiveBeginNotice = LiveBeginNotice;
export default LiveBeginNotice;