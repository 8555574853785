import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var JoinMsgGroupReq = function JoinMsgGroupReq() {
  this.tUserId = new Nimo.UserId();
  this.lMsgGroupId = 0;
};
JoinMsgGroupReq.prototype._clone = function () {
  return new Nimo.JoinMsgGroupReq();
};
JoinMsgGroupReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
JoinMsgGroupReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
JoinMsgGroupReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lMsgGroupId);
};
JoinMsgGroupReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lMsgGroupId = is.readInt64(1, false, this.lMsgGroupId);
};
Nimo.JoinMsgGroupReq = JoinMsgGroupReq;
export default JoinMsgGroupReq;