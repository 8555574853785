import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './CodeLineInfo';
var CodeLine = function CodeLine() {
  this.iNameCode = 0;
  this.iBitRate = 0;
  this.vCdns = new Taf.Vector(new Nimo.CodeLineInfo());
};
CodeLine.prototype._clone = function () {
  return new Nimo.CodeLine();
};
CodeLine.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CodeLine.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CodeLine.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iNameCode);
  os.writeInt32(1, this.iBitRate);
  os.writeVector(2, this.vCdns);
};
CodeLine.prototype.readFrom = function (is) {
  this.iNameCode = is.readInt32(0, false, this.iNameCode);
  this.iBitRate = is.readInt32(1, false, this.iBitRate);
  this.vCdns = is.readVector(2, false, this.vCdns);
};
Nimo.CodeLine = CodeLine;
export default CodeLine;