import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var ShortChainReq = function ShortChainReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.uniquelyIdentifies = "";
  this.originalLink = "";
  this.shortChainType = 0;
  this.liveChannelId = 0;
};
ShortChainReq.prototype._clone = function () {
  return new NimoBuss.ShortChainReq();
};
ShortChainReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShortChainReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShortChainReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeString(1, this.uniquelyIdentifies);
  os.writeString(2, this.originalLink);
  os.writeInt32(3, this.shortChainType);
  os.writeInt64(4, this.liveChannelId);
};
ShortChainReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.uniquelyIdentifies = is.readString(1, false, this.uniquelyIdentifies);
  this.originalLink = is.readString(2, false, this.originalLink);
  this.shortChainType = is.readInt32(3, false, this.shortChainType);
  this.liveChannelId = is.readInt64(4, false, this.liveChannelId);
};
NimoBuss.ShortChainReq = ShortChainReq;
export default ShortChainReq;