import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var LiveRoomInfo = function LiveRoomInfo() {
  this.iLiveStatus = 0;
  this.lPid = 0;
  this.lLiveId = 0;
  this.iBusinessType = 0;
  this.iTemplateType = 0;
  this.iLiveInter = 0;
  this.iLiveType = 0;
  this.iPrivilege = 0;
  this.sPrivateKey = "";
  this.lEntranceFee = 0;
  this.iMaskSeconds = 0;
  this.dScale = 0;
  this.iMuteSeconds = 0;
};
LiveRoomInfo.prototype._clone = function () {
  return new Nimo.LiveRoomInfo();
};
LiveRoomInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveRoomInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveRoomInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iLiveStatus);
  os.writeInt64(1, this.lPid);
  os.writeInt64(2, this.lLiveId);
  os.writeInt32(3, this.iBusinessType);
  os.writeInt32(4, this.iTemplateType);
  os.writeInt32(5, this.iLiveInter);
  os.writeInt32(6, this.iLiveType);
  os.writeInt32(7, this.iPrivilege);
  os.writeString(8, this.sPrivateKey);
  os.writeInt64(9, this.lEntranceFee);
  os.writeInt32(10, this.iMaskSeconds);
  os.writeDouble(11, this.dScale);
  os.writeInt32(12, this.iMuteSeconds);
};
LiveRoomInfo.prototype.readFrom = function (is) {
  this.iLiveStatus = is.readInt32(0, false, this.iLiveStatus);
  this.lPid = is.readInt64(1, false, this.lPid);
  this.lLiveId = is.readInt64(2, false, this.lLiveId);
  this.iBusinessType = is.readInt32(3, false, this.iBusinessType);
  this.iTemplateType = is.readInt32(4, false, this.iTemplateType);
  this.iLiveInter = is.readInt32(5, false, this.iLiveInter);
  this.iLiveType = is.readInt32(6, false, this.iLiveType);
  this.iPrivilege = is.readInt32(7, false, this.iPrivilege);
  this.sPrivateKey = is.readString(8, false, this.sPrivateKey);
  this.lEntranceFee = is.readInt64(9, false, this.lEntranceFee);
  this.iMaskSeconds = is.readInt32(10, false, this.iMaskSeconds);
  this.dScale = is.readDouble(11, false, this.dScale);
  this.iMuteSeconds = is.readInt32(12, false, this.iMuteSeconds);
};
Nimo.LiveRoomInfo = LiveRoomInfo;
export default LiveRoomInfo;