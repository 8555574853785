import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './LiveVideoView';
var PublishUserClipRsp = function PublishUserClipRsp() {
  this.iCode = 0;
  this.liveVideoView = new NimoBuss.LiveVideoView();
  this.contentId = "";
};
PublishUserClipRsp.prototype._clone = function () {
  return new NimoBuss.PublishUserClipRsp();
};
PublishUserClipRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PublishUserClipRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PublishUserClipRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeStruct(1, this.liveVideoView);
  os.writeString(2, this.contentId);
};
PublishUserClipRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.liveVideoView = is.readStruct(1, false, this.liveVideoView);
  this.contentId = is.readString(2, false, this.contentId);
};
NimoBuss.PublishUserClipRsp = PublishUserClipRsp;
export default PublishUserClipRsp;