import { combineReducers } from 'redux';
import baseReducer from '@client/common/store/reducers';
import commonReducer from './common/reducer';
import profileReducer from './profile/reducer';
import homeReducer from './home/reducer';
export var profile = combineReducers({
  base: baseReducer,
  common: commonReducer,
  profile: profileReducer
});
export var home = combineReducers({
  base: baseReducer,
  common: commonReducer,
  home: homeReducer
});
var _cache;
export var getDashboardReducer = function getDashboardReducer() {
  if (!_cache) {
    _cache = combineReducers({
      base: baseReducer,
      common: commonReducer,
      home: homeReducer,
      profile: profileReducer
    });
  }
  return _cache;
};