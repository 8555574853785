import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './MsgItem';
var MsgSendRsp = function MsgSendRsp() {
  this.tMsgItem = new Nimo.MsgItem();
  this.lClientMsgId = 0;
  this.sErrMsg = "";
  this.iCode = 0;
};
MsgSendRsp.prototype._clone = function () {
  return new Nimo.MsgSendRsp();
};
MsgSendRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgSendRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgSendRsp.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tMsgItem);
  os.writeInt64(1, this.lClientMsgId);
  os.writeString(2, this.sErrMsg);
  os.writeInt32(3, this.iCode);
};
MsgSendRsp.prototype.readFrom = function (is) {
  this.tMsgItem = is.readStruct(0, false, this.tMsgItem);
  this.lClientMsgId = is.readInt64(1, false, this.lClientMsgId);
  this.sErrMsg = is.readString(2, false, this.sErrMsg);
  this.iCode = is.readInt32(3, false, this.iCode);
};
Nimo.MsgSendRsp = MsgSendRsp;
export default MsgSendRsp;