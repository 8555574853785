import NimoSailActivity from "../nimosailactivity";
import Taf from "@nimo-fed/taf-lib-wup";
var OpenShareRewardMissionRsp = function OpenShareRewardMissionRsp() {
  this.iCode = 0;
};
OpenShareRewardMissionRsp.prototype._clone = function () {
  return new NimoSailActivity.OpenShareRewardMissionRsp();
};
OpenShareRewardMissionRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
OpenShareRewardMissionRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
OpenShareRewardMissionRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
};
OpenShareRewardMissionRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
};
NimoSailActivity.OpenShareRewardMissionRsp = OpenShareRewardMissionRsp;
export default OpenShareRewardMissionRsp;