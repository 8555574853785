import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetMsgGroupInfoReq = function GetMsgGroupInfoReq() {
  this.tUserId = new Nimo.UserId();
  this.lMsgGroupId = 0;
  this.sShareInviteURL = "";
};
GetMsgGroupInfoReq.prototype._clone = function () {
  return new Nimo.GetMsgGroupInfoReq();
};
GetMsgGroupInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMsgGroupInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMsgGroupInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lMsgGroupId);
  os.writeString(2, this.sShareInviteURL);
};
GetMsgGroupInfoReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lMsgGroupId = is.readInt64(1, false, this.lMsgGroupId);
  this.sShareInviteURL = is.readString(2, false, this.sShareInviteURL);
};
Nimo.GetMsgGroupInfoReq = GetMsgGroupInfoReq;
export default GetMsgGroupInfoReq;