import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var PopupData = function PopupData() {
  this.lPopupId = 0;
  this.sUrl = "";
  this.sTitle = "";
  this.sContent = "";
  this.sLinkUrl = "";
  this.iSourceType = 0;
  this.iPopupStyleType = 0;
};
PopupData.prototype._clone = function () {
  return new Nimo.PopupData();
};
PopupData.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PopupData.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PopupData.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lPopupId);
  os.writeString(1, this.sUrl);
  os.writeString(2, this.sTitle);
  os.writeString(3, this.sContent);
  os.writeString(4, this.sLinkUrl);
  os.writeInt32(6, this.iSourceType);
  os.writeInt32(7, this.iPopupStyleType);
};
PopupData.prototype.readFrom = function (is) {
  this.lPopupId = is.readInt64(0, false, this.lPopupId);
  this.sUrl = is.readString(1, false, this.sUrl);
  this.sTitle = is.readString(2, false, this.sTitle);
  this.sContent = is.readString(3, false, this.sContent);
  this.sLinkUrl = is.readString(4, false, this.sLinkUrl);
  this.iSourceType = is.readInt32(6, false, this.iSourceType);
  this.iPopupStyleType = is.readInt32(7, false, this.iPopupStyleType);
};
Nimo.PopupData = PopupData;
export default PopupData;