import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import Message from '@client/common/components/Message';
import Auth from '@desktop/src/common/services/auth';
import { profileStore } from '@desktop/src/common/models/store';
import { getAnchorPrivilegeConfigList, getAnchorLevelDetail } from '@desktop/src/common/models/profile/actions';
import NimoRspCode from '@client/jce/Nimo/NimoRspCode';
import { getLang } from '@client/common/utils/fn';
export var apiCodeErrHandler = function apiCodeErrHandler() {
  var code = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var _ref = profileStore.getState().profile || {},
    langPkg = _ref.langPkg;
  var langLibToast = (langPkg === null || langPkg === void 0 ? void 0 : langPkg.toast) || {};
  switch (code) {
    case 10305: // 登录过期
    case 10406:
      Message.error("".concat(code, " ").concat(langLibToast.logExpire));
      setTimeout(function () {
        Auth.loginOut();
      }, 2000);
      break;
    case 10405:
      Message.error("".concat(code, " ").concat(langLibToast.disable));
      setTimeout(function () {
        Auth.loginOut();
      }, 2000);
      break;
    case 10010: // 敏感词
    case 15033:
    case 10408:
      Message.error("".concat(code, " ").concat(langLibToast.sensitive));
      break;
    default:
      Message.error(langLibToast.reviseerror);
      break;
  }
};

/**
 * 判断是否具有某主播特权
 * @param {*} items 主播特权识别 ID。单个特权为 number 类型，多个特权为 array 类型
 * @param {*} lang 操作语言ID 不传则自动获取 cookie
 * @return {arrary} [result, anchorLevel] 返回结果和当前用户的主播等级 若传入 items 为单个则 result 为对象，若传入为数组 则 rsult 为数组
 * 单个特权 result 结构： {auth: [true|false] 是有有权限, requiredLevel: number 该特权要求的等级, config: 该特权配置信息数组}
 */
export var getAnchorLevelAuth = function getAnchorLevelAuth(items, lang) {
  if (!Array.isArray(items)) items = [items];
  var _ref2 = profileStore.getState().profile || {},
    anchorLevelDetail = _ref2.anchorLevelDetail,
    anchorPrivilegeConfigList = _ref2.anchorPrivilegeConfigList;
  var iLevel = anchorLevelDetail.iLevel;
  var dispatch = profileStore.dispatch;
  var result = new Array(items.length).fill(1).map(function () {
    return {
      auth: false,
      requiredLevel: 0,
      config: []
    };
  });
  var arr = [];
  var notAnchor = Symbol('notAnhor');
  arr.push(iLevel === undefined ? dispatch(getAnchorLevelDetail()).catch(function (err) {
    if (err && err.code === NimoRspCode.kMRC_ANCHOR_NOT_FOUND) {
      return notAnchor;
    } // 不是主播的情况下继续执行 其他错误抛出到外层catch
    return Promise.reject(err);
  }) : anchorLevelDetail);
  arr.push(!anchorPrivilegeConfigList.length ? dispatch(getAnchorPrivilegeConfigList(lang || parseInt(getLang(), 10))) : anchorPrivilegeConfigList);
  return Promise.all(arr).then(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      anchorLevelRes = _ref4[0],
      configs = _ref4[1];
    var anchorLevel = anchorLevelRes.iLevel || 0; // 不是主播的情况默认用 0 计算
    for (var i = 0; i < configs.length; i += 1) {
      var config = configs[i];
      var itemIdx = items.indexOf(config.iType);
      if (itemIdx !== -1) {
        result[itemIdx].auth = config.iEnableLevel <= anchorLevel || result[itemIdx].auth;
        result[itemIdx].requiredLevel = result[itemIdx].requiredLevel == 0 || config.iEnableLevel < result[itemIdx].requiredLevel ? config.iEnableLevel : result[itemIdx].requiredLevel;
        result[itemIdx].config.push(config);
      }
    }
    var _anchorLevel = anchorLevelRes === notAnchor ? false : anchorLevel; // 区分不为主播时返回 false
    return items.length === 1 ? [result[0], _anchorLevel] : [result, _anchorLevel];
  });
};