import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetDashBoardRecommendConfigReq = function GetDashBoardRecommendConfigReq() {
  this.tUser = new Nimo.UserId();
  this.vLocation = new Taf.Vector(new Taf.INT32());
  this.sLang = "";
};
GetDashBoardRecommendConfigReq.prototype._clone = function () {
  return new Nimo.GetDashBoardRecommendConfigReq();
};
GetDashBoardRecommendConfigReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetDashBoardRecommendConfigReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetDashBoardRecommendConfigReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeVector(1, this.vLocation);
  os.writeString(2, this.sLang);
};
GetDashBoardRecommendConfigReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.vLocation = is.readVector(1, false, this.vLocation);
  this.sLang = is.readString(2, false, this.sLang);
};
Nimo.GetDashBoardRecommendConfigReq = GetDashBoardRecommendConfigReq;
export default GetDashBoardRecommendConfigReq;