import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var BookRsp = function BookRsp() {
  this.bookInfo = "";
  this.bookStatus = 0;
};
BookRsp.prototype._clone = function () {
  return new NimoBuss.BookRsp();
};
BookRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BookRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BookRsp.prototype.writeTo = function (os) {
  os.writeString(0, this.bookInfo);
  os.writeInt32(1, this.bookStatus);
};
BookRsp.prototype.readFrom = function (is) {
  this.bookInfo = is.readString(0, false, this.bookInfo);
  this.bookStatus = is.readInt32(1, false, this.bookStatus);
};
NimoBuss.BookRsp = BookRsp;
export default BookRsp;