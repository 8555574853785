import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var PkSummaryView = function PkSummaryView() {
  this.pkUid = 0;
  this.highLightStatus = 0;
  this.statTime = 0;
  this.score = 0;
  this.winningStreak = 0;
  this.partiesScoreAmount = 0;
  this.partiesScoreDiff = 0;
  this.streamerUId = 0;
  this.pkStreamerCountryCode = "";
  this.isRankPK = 0;
  this.winningStreakConfig = 0;
  this.grade = 0;
  this.gradeIcon = "";
  this.streamerCountryCode = "";
  this.rankWinningStreak = 0;
};
PkSummaryView.prototype._clone = function () {
  return new NimoBuss.PkSummaryView();
};
PkSummaryView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PkSummaryView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PkSummaryView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.pkUid);
  os.writeInt32(1, this.highLightStatus);
  os.writeInt64(2, this.statTime);
  os.writeInt32(3, this.score);
  os.writeInt32(4, this.winningStreak);
  os.writeInt32(5, this.partiesScoreAmount);
  os.writeInt32(6, this.partiesScoreDiff);
  os.writeInt64(7, this.streamerUId);
  os.writeString(8, this.pkStreamerCountryCode);
  os.writeInt32(9, this.isRankPK);
  os.writeInt32(10, this.winningStreakConfig);
  os.writeInt32(11, this.grade);
  os.writeString(12, this.gradeIcon);
  os.writeString(13, this.streamerCountryCode);
  os.writeInt32(14, this.rankWinningStreak);
};
PkSummaryView.prototype.readFrom = function (is) {
  this.pkUid = is.readInt64(0, false, this.pkUid);
  this.highLightStatus = is.readInt32(1, false, this.highLightStatus);
  this.statTime = is.readInt64(2, false, this.statTime);
  this.score = is.readInt32(3, false, this.score);
  this.winningStreak = is.readInt32(4, false, this.winningStreak);
  this.partiesScoreAmount = is.readInt32(5, false, this.partiesScoreAmount);
  this.partiesScoreDiff = is.readInt32(6, false, this.partiesScoreDiff);
  this.streamerUId = is.readInt64(7, false, this.streamerUId);
  this.pkStreamerCountryCode = is.readString(8, false, this.pkStreamerCountryCode);
  this.isRankPK = is.readInt32(9, false, this.isRankPK);
  this.winningStreakConfig = is.readInt32(10, false, this.winningStreakConfig);
  this.grade = is.readInt32(11, false, this.grade);
  this.gradeIcon = is.readString(12, false, this.gradeIcon);
  this.streamerCountryCode = is.readString(13, false, this.streamerCountryCode);
  this.rankWinningStreak = is.readInt32(14, false, this.rankWinningStreak);
};
NimoBuss.PkSummaryView = PkSummaryView;
export default PkSummaryView;