'use strict';

/* eslint-disable no-param-reassign */
import EventEmitter from 'events';
var eventful = function eventful(target) {
  var ee = new EventEmitter();
  Object.defineProperties(target, {
    isEventful: {
      value: true
    },
    on: {
      value: function value(event, listener) {
        if (!listener) {
          return;
        }
        ee.on(event, listener);
      }
    },
    off: {
      value: function value(event, listener) {
        if (!listener) {
          return;
        }
        ee.off(event, listener);
      }
    },
    once: {
      value: function value(event, listener) {
        if (!listener) {
          return;
        }
        ee.once(event, listener);
      }
    },
    emit: {
      value: function value() {
        ee.emit.apply(ee, arguments);
      }
    },
    trigger: {
      value: function value() {
        target.emit.apply(target, arguments);
      }
    }
  });
};
export default eventful;