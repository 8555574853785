import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AddMsgBlackListRsp = function AddMsgBlackListRsp() {
  this.iCode = 0;
  this.lUid = 0;
  this.sErrMsg = "";
};
AddMsgBlackListRsp.prototype._clone = function () {
  return new Nimo.AddMsgBlackListRsp();
};
AddMsgBlackListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AddMsgBlackListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AddMsgBlackListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt64(1, this.lUid);
  os.writeString(2, this.sErrMsg);
};
AddMsgBlackListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.sErrMsg = is.readString(2, false, this.sErrMsg);
};
Nimo.AddMsgBlackListRsp = AddMsgBlackListRsp;
export default AddMsgBlackListRsp;