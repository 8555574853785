import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { isProfilePage } from '@client/common/utils/fn';
import { profile, home, getDashboardReducer } from './reducer';
var isTest = DOMAINS.isDebug === 'true';
var enhancers = function enhancers(opt) {
  var _enhancers = applyMiddleware(thunk);
  var __REDUX__ = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || false;
  return isTest && __REDUX__ ? __REDUX__({
    name: opt === null || opt === void 0 ? void 0 : opt.name
  })(_enhancers) : _enhancers;
};
export var homeStore = window.isNewDashboard ? createStore(getDashboardReducer(), enhancers({
  name: 'dashboard'
})) : createStore(home, enhancers({
  name: 'web主站 (homeStore)'
}));
export var profileStore = window.isNewDashboard ? homeStore : createStore(profile, enhancers({
  name: 'web个人中心 (profileStore)'
}));
export var homePresistor = persistStore(homeStore);
var isHome = !isProfilePage();
export var store = isHome ? homeStore : profileStore;