import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _URL$params;
import Util from '@mobile/src/common/util/util';
import { parseURL, getMid } from '@client/common/utils/fn.js';
import ua from '@client/common/utils/ua.js';
import { session } from '@client/common/utils/storage';
import { FROM_COMMON_SHARE_MAP, FROM_ACT, isFromCommonShare } from '@client/common/services/commonShare';
import { useTrafficShareAB } from '@client/common/services/abTest/shareAB';

// eslint-disable-next-line new-cap
var _URL = new parseURL();
var DCFromVal = 'discordauto';
var wapPid = (_URL === null || _URL === void 0 || (_URL$params = _URL.params) === null || _URL$params === void 0 ? void 0 : _URL$params._from) === DCFromVal ? DCFromVal : 'nimo_wap';
export var WAP_APPFLYER_TYPE_MAP = {
  pid: wapPid,
  // pid
  OPEN_APP: 'openapp',
  // wap导航-打开APP
  FOLLOW: 'follow',
  // wap直播间-关注
  COMMENT: 'comment',
  // wap直播间-评论
  NEW_1: 'new1',
  // wap新用户弹窗
  MATCH_1: 'match1',
  // wap赛事强制导量浮层
  BANNER: 'banner',
  // wap直播间-横幅条
  SEND_COMMENT: 'sendcomment',
  // wap直播间-发送评论
  MORE: 'more',
  // wap直播间-给我更多
  ANCHOR_1: 'anchor1',
  // wap直播间-在播-主播头像
  ANCHOR_2: 'anchor2',
  // wap直播间-不在播-主播头像
  FOLLOW_1: 'follow1',
  // wap直播间-不在播-关注
  TEAM_PK: 'teamPK',
  // wap赛事直播间-战队投票
  SCHEDULE_RESERVATION: 'schedule_reservation',
  // wap赛事直播间-赛事预约
  LOTTERY_WINDOW: 'lotterywindow',
  // wap直播间-赛事抽奖弹窗
  LOTTERY_BANNER: 'lotterybanner',
  // wap直播间-赛事抽奖横幅
  OPEN_APP_1: 'openapp1',
  // wap导航-A组
  OPEN_APP_2: 'openapp2',
  // wap导航-B组
  OPEN_APP_3: 'openapp3',
  // wap导航-C组
  OPEN_APP_4: 'openapp4',
  // wap导航-D组
  SUSPEND_1: 'suspend1',
  // wap列表页-悬浮窗
  SUSPEND_2: 'suspend2',
  // wap直播间-悬浮窗
  MESSAGE: 'message',
  // wap直播间-消息弹窗
  TOP_ANCHOR: 'topanchor',
  // wap头部主播落地页
  CLIP_1: 'clip1',
  // wap剪辑播放页-顶部导航
  CLIP_2: 'clip2',
  // wap剪辑播放页-关注主播
  CLIP_3: 'clip3',
  // wap剪辑播放页-底部横幅
  GO_LUCK_DRAW: 'draw',
  // wap直播间-抽奖弹幕
  GO_BET: 'bet',
  // wap直播间-竞猜弹幕
  BET: 'bet2',
  // wap直播间-竞猜面板
  SHARE: 'share',
  // 社交媒体分享（APP直播间右上角的分享）
  MATCH_LOTTERY: 'match_lottery',
  // wap赛事分享抽奖
  OFFLIVE_1: 'offlive1',
  // 下播展示的播放器内的“点击观看最新录像” 按钮
  OFFLIVE_2: 'offlive2',
  // 下播展示的播放器内的“订阅下次直播” 按钮
  PLAYERFOOTER: 'playerfooter',
  // 播放器底部的“打开 NiMO TV，享受高清直播”
  COMMON_SHARE: 'common_share',
  // 分享（区别于旧的share）
  PICKME: 'pickme',
  // 通过pickme分享进入wap端，引导弹窗
  PICKME_2: 'pickeme_nonshare',
  GUIDEWINDOW: 'guidewindow',
  // WAP弹窗及引导弹窗
  CLIP_4: 'clip',
  // 通过剪辑分享外链进入wap端，引导弹窗
  CLIP_5: 'video',
  // 通过录像分享外链进入wap端，引导弹窗
  WAP_ABTEST: 'wap_abtest',
  // ABTEST
  OFFLIVEINSTANTPOPUP: 'offlive_instant_popup',
  // wap直播间不在播引导弹窗
  STREAMER_REPLAY: 'streamer_replay',
  // 直播间主播录像
  COIN_CHEST: 'coin_chest',
  // 直播间宝箱玩法分享
  GROUPUP: 'groupup',
  // 跳转到app groupup
  ROOM_FLOAT_BTN: 'liveroom_float_download_button',
  // 直播间下方打开APP按钮
  OFFLIE_VIDEO_DOWNLOAD: 'offline_video_download',
  // 有下播视频的时候的下播弹窗按钮
  PERSONALPAGE_VIDEO: 'personalpage_video',
  // 个人页的app-only视频点击
  PERSONALPAGE_MAINBUTTON: 'personalpage_mainbutton',
  // 个人页底部加入频道直播按钮点击
  VIDEOPAGE_VIDEO: 'videopage_video',
  // 视频播放页的app-only视频点击
  VIDEOPAGE_JOINBUTTON: 'videopage_joinbutton',
  // 视频播放页主播信息栏加入直播间按钮点击
  COMMON: 'common',
  // 自然流量
  ROOM_NOT_EXIST_OPEN_APP: 'room_not_exist_open_app',
  // 房间不存在内部打开app
  PLAYER_RECOMMEND: 'player_recommend',
  INVITATION_NEWUSERS: 'invitation_newusers',
  // 新人邀请活动页面跳转app
  WAP_ROOM_LIEV_PREVIEW: 'schedule_popup',
  // wap直播预告弹窗
  WAP_MATCH_ROOM_LIEV_PREVIEW: 'contest_schedule_popup',
  // wap赛事直播间直播预告弹窗
  CONTEST_BADGE: 'contest_badge',
  // Wap赛事直播间赛事徽章激励引导
  HOME_FLOAT_DOWNLOAD_BTN: 'home_float_download_button',
  // Wap首页悬浮打开APP按钮
  GAME_FLOAT_DOWNLOAD_BTN: 'gamepage_float_download_button',
  // Wap品类页悬浮打开APP按钮
  EXPORT_FLOAT_DOWNLOAD_BTN: 'esportspage_float_download_button',
  // Wap赛事中心悬浮打开APP按钮
  HOT_FLOAT_DOWNLOAD_BTN: 'hot_float_download_button',
  // Wap的Hot页面添加
  VIDEO_FLOAT_DOWNLOAD_BTN: 'video_floating_button',
  // wap的视频页底部页悬浮打开APP按钮
  VIDEO_FIRST_POPUP: 'video_first_popup',
  // wap视频页进入时弹出引导弹窗打开按钮
  VIDEO_ENDWATCH_POPUP: 'video_endwatch_popup',
  // wap视频页观看完时弹出引导弹窗打开按钮
  VIDEO_DURINGWATCH_POPUP: 'video_duringwatch_popup',
  // wap视频页观看超过40秒时弹出引导弹窗打开按钮
  VIDEO_COMMENT_POPUP: 'video_comment_popup',
  // wap视频页点击添加评论时弹出引导弹窗打开按钮
  VIDEO_LIKE_POPUP: 'video_like_popup',
  // wap视频页点击评论点赞时弹出引导弹窗打开按钮
  PROFILE_ALBUM_POPUP: 'profile_album_popup',
  // wap个人页点击图片墙时弹出引导弹窗打开按钮
  PERSONAL_POST: 'personal_post',
  // wap个人页点击动态视频/图片
  PERSONAL_POST_MORE: 'personal_post_more',
  // wap个人页点击动态视频/图片
  PERSONAL_POST_SHARE: 'personal_post_share',
  // wap个人页点击动态分享
  PERSONAL_POST_COMMENT: 'personal_post_comment',
  // wap个人页点击动态评论
  PERSONAL_POST_LIKE: 'personal_post_like',
  // wap个人页点击动态点赞
  PERSONAL_POST_DETAIL: 'personal_post_detail',
  // wap个人页动态详情
  YEAR_END_LOTTERY_TASK: 'year_end_lottery_task' // wap个人页动态详情
};
export { FROM_COMMON_SHARE_MAP };
export var WAP_SPECIAL_APPFLYER_TYPE_MAP = {
  DOWNLOAD: 'dl',
  // 打开按钮
  MC_SUSPEND: 'mc_suspend',
  // MC主播招募首页
  MC_OPEN_APP: 'mc_openapp' // MC主播招募直播间列表页
};
export var WAP_ACTIVITY_TYPE_MAP = {
  pid: 'nimo_activity',
  // pid
  VN_MINI: 'miniworld',
  // 越南迷你世界活动
  VN_PUBGM: 'pubgM_activity_vn',
  // 越南 PUBGM 冰雪地图主播招募活动
  VN_ANNUAL: 'Annual_event_VN',
  // 越南年度活动
  VN_BR: 'Annual_event_BR',
  // 巴西年度活动
  VN_tencent: 'tecent_cop_vn',
  // 越南腾讯千人斩挑战活动
  MONEY_RECRUIT: 'moneyrecruit',
  // 东南亚薪资招募低版本弹窗按钮
  ROAD_TO_THE_TOP: 'roadtothetop',
  // road to the top活动
  VM_MUA_AGREEMENT: 'thai%20pubg',
  // 越南电子协议页
  CHA_BRAWL_STARS: 'brawlstar',
  // 2月荒野
  BR_INS100K: 'BR_ins100K'
};
var WAP_SPECIAL_APPFLYER_TYPE_MAP_VALUES = Object.values(WAP_SPECIAL_APPFLYER_TYPE_MAP);
var WAP_SPECIAL_APPFLYER_TYPE_LINK_MAP = _defineProperty(_defineProperty(_defineProperty({}, "".concat(WAP_SPECIAL_APPFLYER_TYPE_MAP.DOWNLOAD), 'https://nimotv.onelink.me/B6cW/f7cb767b?af_dp=nimotv://'), "".concat(WAP_SPECIAL_APPFLYER_TYPE_MAP.MC_SUSPEND), 'https://nimotv.onelink.me/B6cW?pid=MC%E4%B8%BB%E6%92%AD%E6%8B%9B%E5%8B%9F%E9%A1%B5&c=suspend&af_dp=nimotv%3A%2F%2F&af_web_dp=https%3A%2F%2Fwww.nimo.tv%2Fdownload%2Fnimotv'), "".concat(WAP_SPECIAL_APPFLYER_TYPE_MAP.MC_OPEN_APP), 'https://nimotv.onelink.me/B6cW?pid=MC%E4%B8%BB%E6%92%AD%E6%8B%9B%E5%8B%9F%E9%A1%B5&c=openapp&af_dp=nimotv%3A%2F%2F&af_web_dp=https%3A%2F%2Fwww.nimo.tv%2Fdownload%2Fnimotv');
var getPid = function getPid(type) {
  var pid;
  var arr = [WAP_APPFLYER_TYPE_MAP, WAP_ACTIVITY_TYPE_MAP];
  for (var index = 0; index < arr.length; index += 1) {
    var map = arr[index];
    if (Object.values(map).indexOf(type) !== -1) {
      pid = map.pid;
      break;
    }
  }
  return pid;
};
var Traffic_APPFLYER_TYPE_LIST = [WAP_APPFLYER_TYPE_MAP.VIDEO_FIRST_POPUP, WAP_APPFLYER_TYPE_MAP.VIDEO_ENDWATCH_POPUP, WAP_APPFLYER_TYPE_MAP.VIDEO_DURINGWATCH_POPUP, WAP_APPFLYER_TYPE_MAP.VIDEO_DURINGWATCH_POPUP, WAP_APPFLYER_TYPE_MAP.VIDEO_COMMENT_POPUP, WAP_APPFLYER_TYPE_MAP.VIDEO_LIKE_POPUP, WAP_APPFLYER_TYPE_MAP.VIDEO_FLOAT_DOWNLOAD_BTN, WAP_APPFLYER_TYPE_MAP.VIDEOPAGE_VIDEO, WAP_APPFLYER_TYPE_MAP.VIDEOPAGE_JOINBUTTON];

/**
 * 逻辑一：
 * 如果url含有参数_from=facebook_traffic，
 * 或是本次会话当中访问过含有上面参数的页面（会记录在sessionStorage），
 * 则直接跳转Google Play或App Store。
 *
 * 逻辑二：
 * 如果是来自特定场景产生的分享链接（如pickme），
 * 则会带上以下参数：
 * _from(af_sub1)：来源（在FROM_COMMON_SHARE_MAP里面才算）
 * _channel(af_sub2)：分享的直播间id，固定 af_sub2
 * _shareuid(af_sub3)：分享者 userid，_from 不在FROM_ACT的时候放在 af_sub3
 * _activityid(af_sub3)：模版系统生成的id，_from 在FROM_ACT的时候放在 af_sub3
 * _source(af_sub4): 分享渠道, 固定 af_sub4
 * 并判断为fromCommonShare。
 *
 * 逻辑三：
 * 如果是点击APP直播间右上角分享按钮产生的分享链接，
 * 会带上以下参数：
 * _source：分享渠道，_uid：分享者用户uid，_mid：分享者设备mid
 * 会使用这些参数拼接成appflyer跳转链接。
 * 
 * appsflyer参数说明：https://support.appsflyer.com/hc/zh-cn/articles/207447163
 */
export default (function (type, anchorId, roomId, options) {
  var _URL$params3, _URL$params4, _URL$params5, _URL$params6, _URL$params7, _URL$params8;
  // onelink跳转到wap站时，会带上一系列af参数
  var _URL$params2 = _URL.params,
    _from = _URL$params2._from,
    _uid = _URL$params2._uid,
    _source = _URL$params2._source,
    _identity = _URL$params2._identity,
    _activityid = _URL$params2._activityid,
    _shareuid = _URL$params2._shareuid,
    _channel = _URL$params2._channel,
    af_sub1 = _URL$params2.af_sub1,
    af_sub2 = _URL$params2.af_sub2,
    af_sub3 = _URL$params2.af_sub3,
    af_dp = _URL$params2.af_dp,
    ad = _URL$params2.ad,
    adset = _URL$params2.adset;
  var _ref = options || {},
    bridgeURL = _ref.bridgeURL,
    op_af_sub1 = _ref.op_af_sub1,
    op_af_sub2 = _ref.op_af_sub2,
    _ref$op_af_force_dp = _ref.op_af_force_dp,
    op_af_force_dp = _ref$op_af_force_dp === void 0 ? false : _ref$op_af_force_dp;
  // 视频业务需要单个视频vid数据 + wap端视频页启动AB测试，原sub5位置所带的vid参数，不再合适。需要调整为af_sub4携带。
  var af_sub4 = (options === null || options === void 0 ? void 0 : options.af_sub4) || ((_URL$params3 = _URL.params) === null || _URL$params3 === void 0 ? void 0 : _URL$params3.af_sub4);
  var shareAB = useTrafficShareAB();
  var googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.huya.nimo';
  var appStoreUrl = 'itms-apps://apps.apple.com/ID/app/id1367087826?ls=1&mt=8';
  var isFacebookTraffic = (_from === 'facebook_traffic' || session.get('facebook_traffic') === '1') && ((_URL$params4 = _URL.params) === null || _URL$params4 === void 0 ? void 0 : _URL$params4.campaign) !== 'video';
  if (isFacebookTraffic) {
    if (ua.isIOS) {
      return appStoreUrl;
    }
    if (ua.isAdr) {
      return googlePlayUrl;
    }
  }
  if (~WAP_SPECIAL_APPFLYER_TYPE_MAP_VALUES.indexOf(type)) {
    return WAP_SPECIAL_APPFLYER_TYPE_LINK_MAP[type];
  }

  // const isCOrChampion = () => {
  //   return !!(c || champion);
  // };

  /**
   * APP端构造这些url参数时已经进行了encodeURIComponent操作；
   * 但为了防止用户手动构造url参数并含有特殊字符，进行第二次编解码；
   * 故先进行decodeURIComponent，然后再encodeURIComponent，最后再拼接到appflyer链接；
   */
  var uid = encodeURIComponent(decodeURIComponent(_uid || ''));
  var source = encodeURIComponent(decodeURIComponent(af_sub4 || _source || '-1'));
  var identity = encodeURIComponent(decodeURIComponent(_identity || ''));
  var from = encodeURIComponent(decodeURIComponent(af_sub1 || _from || ''));
  var fromShare = !!_source;
  var fromCommonShare = isFromCommonShare(from);
  var isGoogleTraffic = _from === 'google_traffic' && ((_URL$params5 = _URL.params) === null || _URL$params5 === void 0 ? void 0 : _URL$params5.campaign) === 'video';
  var isFBTraffic = _from === 'facebook_traffic' && ((_URL$params6 = _URL.params) === null || _URL$params6 === void 0 ? void 0 : _URL$params6.campaign) === 'video';

  // const afAd = session.get('liveroom_param');
  var pid = getPid(type);
  var campaign = type;
  if (fromCommonShare) {
    pid = WAP_APPFLYER_TYPE_MAP.pid;
    campaign = WAP_APPFLYER_TYPE_MAP.COMMON_SHARE;
  } else if (fromShare) {
    pid = WAP_APPFLYER_TYPE_MAP.SHARE;
    campaign = source;
  } else if ((_URL === null || _URL === void 0 || (_URL$params7 = _URL.params) === null || _URL$params7 === void 0 ? void 0 : _URL$params7.c) !== 'common_share' && (_URL === null || _URL === void 0 || (_URL$params8 = _URL.params) === null || _URL$params8 === void 0 ? void 0 : _URL$params8._from) !== 'common_share' && !isFacebookTraffic) {
    campaign = WAP_APPFLYER_TYPE_MAP.COMMON;
    from = 'wap';
  }

  // else if (!isCOrChampion() && window.isWapRoom) {
  //   _type = WAP_APPFLYER_TYPE_MAP.WAP_ABTEST;
  // }
  var url = "https://nimotv.onelink.me/B6cW?pid=".concat(pid, "&c=").concat(campaign, "&af_adset=").concat(from, "&af_ad=").concat(type, "&af_dp=");
  var trafficUrl = "https://nimotv.onelink.me/B6cW?pid=".concat(isGoogleTraffic ? 'google_to_wap' : 'fb_to_wap', "&c=video&af_dp=");
  if ((isGoogleTraffic || isFBTraffic) && Traffic_APPFLYER_TYPE_LIST.includes(type)) {
    // 承接AB实验，实验组跳转对应视频主播直播间页，对照组跳转对应视频页
    if ((shareAB === null || shareAB === void 0 ? void 0 : shareAB.video_channel_app_dp) === 'video_wap_to_room') {
      trafficUrl += encodeURIComponent("nimotv://huya.nimo.com/living_room?anchor_id=".concat(anchorId, "&room_id=").concat(roomId));
    } else {
      var vid = adset ? adset.split('_')[0] : ad.split('_')[0];
      trafficUrl += encodeURIComponent("nimotv://huya.nimo.com/video_page?resourceId=".concat(vid));
    }
    trafficUrl += "&af_sub5=".concat(getMid());
    trafficUrl += "&af_adset=".concat(isFBTraffic ? ad : adset);
    trafficUrl += "&af_ad=".concat(type);
    trafficUrl += "&af_force_deeplink=true&is_retargeting=true";
    return trafficUrl;
  }
  if (bridgeURL) {
    url += encodeURIComponent(bridgeURL);
  } else if (af_dp) {
    url += af_dp;
  } else if (anchorId !== undefined && roomId !== undefined) {
    url += encodeURIComponent("nimotv://huya.nimo.com/living_room?anchor_id=".concat(anchorId, "&room_id=").concat(roomId));
    if (fromCommonShare) {
      // 跳转直播间 js 桥增加 from 参数，指定跳转来源，app 针对不同来源做不同处理
      url += encodeURIComponent("&from=".concat(from));
    }
  } else {
    url += 'nimotv%3A%2F%2F';
  }
  if (_from === "liveshow_highlight_poster") {
    url += encodeURIComponent("&af_web_dp=nimotv://huya.nimo.com/personal_page?udbid=".concat(anchorId, "&tabtype=", 0));
  } else {
    url += '&af_web_dp=https%3A%2F%2Fwww.nimo.tv%2Fdownload%2Fnimotv';
  }
  if (fromCommonShare) {
    var sourceRoomId = encodeURIComponent(decodeURIComponent(af_sub2 || _channel || '0'));
    var custom = af_sub3 || (FROM_ACT.includes(from) ? _activityid : _shareuid) || '0';
    custom = encodeURIComponent(decodeURIComponent(custom));
    url += "&af_sub1=".concat(from, "&af_sub2=").concat(sourceRoomId, "&af_sub3=").concat(custom, "&af_sub4=").concat(source);
  } else if (fromShare) {
    url += "&af_sub1=".concat(uid).concat(identity ? "&af_sub2=".concat(identity) : '', "&af_sub3=").concat(type);
  } else if (pid === 'nimo_wap' || pid === DCFromVal) {
    var afSub1 = op_af_sub1 || '0';
    var afSub2 = op_af_sub2 || 'direct';
    var afSub3 = 'other';
    var anchorActLink = Util.cookie.get('ss_source_anchor_link_roomId');
    var ssSource = Util.cookie.get('ss_source_ad_type') || Util.cookie.get('ss_source');
    var currentRoomId = window.G_roomBaseInfo ? window.G_roomBaseInfo.roomId : null;
    if (anchorActLink) {
      afSub1 = "activity2_".concat(anchorActLink);
    }
    if (ssSource) {
      afSub2 = ssSource;
    }
    if (currentRoomId) {
      afSub3 = currentRoomId;
    }
    url += "&af_sub1=".concat(afSub1, "&af_sub2=").concat(afSub2, "&af_sub3=").concat(afSub3);
    if (af_sub4 && pid === 'nimo_wap') {
      url += "&af_sub4=".concat(source);
    }
    // if (!isCOrChampion() && window.isWapRoom) {
    //   url += `&af_adset=liveroom_popup`;
    //   if (afAd) {
    //     url += `&af_ad=${afAd}`;
    //   }
    // }
  }
  if (pid === 'nimo_wap') {
    url += "&af_sub5=".concat(getMid());
  }
  if (op_af_force_dp) {
    url += '&af_force_deeplink=true';
  }
  url += "&is_retargeting=true";
  return url;
});