import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { local } from '@client/common/utils/storage';
var storeKey = 'text-bulletscreen-settings';
export var FIELD_SWITCH = 'switch';
export var FIELD_PICKME = 'pickme';
export var Value = {
  position: {
    full: '1',
    top: '2',
    bottom: '3'
  },
  size: {
    sm: '1',
    md: '2',
    lg: '3'
  },
  speed: {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5
  }
};
export var PositionValue = _defineProperty(_defineProperty(_defineProperty({}, Value.position.full, {
  start: 0,
  end: 1
}), Value.position.top, {
  start: 0,
  end: 0.333
}), Value.position.bottom, {
  start: 0.667,
  end: 1
});
export var SizeValue = _defineProperty(_defineProperty(_defineProperty({}, Value.size.sm, 16), Value.size.md, 25), Value.size.lg, 36);
export var SpeedValue = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Value.speed.xs, 20000), Value.speed.sm, 13333), Value.speed.md, 10000), Value.speed.lg, 8000), Value.speed.xl, 6666);
export var reportValueMap = _defineProperty(_defineProperty({
  position: _defineProperty(_defineProperty(_defineProperty({}, Value.position.full, "full"), Value.position.top, "top"), Value.position.bottom, "bottom"),
  size: _defineProperty(_defineProperty(_defineProperty({}, Value.size.sm, 'min'), Value.size.md, 'mid'), Value.size.lg, 'max'),
  speed: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Value.speed.xs, '50%'), Value.speed.sm, '75%'), Value.speed.md, '100%'), Value.speed.lg, '125%'), Value.speed.xl, '150%')
}, FIELD_PICKME, {
  'true': '1',
  'false': '0'
}), FIELD_SWITCH, {
  'true': '1',
  'false': '0'
});
function getDefaultSettings() {
  return _defineProperty({
    position: Value.position.full,
    size: Value.size.md,
    speed: Value.speed.md,
    opacity: 70
  }, FIELD_SWITCH, undefined);
}
export function getSettings() {
  var rawdata = local.get(storeKey);
  if (!rawdata) return getDefaultSettings();
  var settings;
  try {
    settings = JSON.parse(rawdata);
  } catch (_) {
    // ignore
  }
  settings = settings ? _objectSpread(_objectSpread({}, getDefaultSettings()), settings) : getDefaultSettings();
  return settings;
}
export function setSettings(settings) {
  if (_typeof(settings) === 'object' && settings !== null) {
    local.set(storeKey, JSON.stringify(settings));
  }
}