import { escape } from '@client/common/utils/dom';

/**
 * 转换接口的数据|| app js桥数据
 * 参考 Room.php
 * @param {Object} res
 */

export function normalizeRoomInfo(res) {
  var data = res || {};
  var anchorId = +data.anchorId || data.anchorid;
  return {
    roomId: +data.id || data.roomid || data.roomId,
    auId: anchorId,
    anchorId: anchorId,
    alise: data.alise,
    nickname: escape(data.anchorName || ''),
    head_img: data.headImg || '',
    title: escape(data.roomTheme || ''),
    gameId: data.roomType,
    game: data.roomTypeName || data.game,
    background_img: data.backgroundImg || '',
    anchorAnnouncement: escape(data.anchorAnnouncement || ''),
    avatarUrl: data.anchorAvatarUrl || data.avatarUrl,
    viewerNum: data.viewerNum || 0,
    anchorLang: data.lcid || 1033,
    anchorLangText: data.lcidText || '',
    anchorCountryCode: data.anchorCountryCode || 'ID',
    liveStreamStatus: data.liveStreamStatus || 0,
    isPlayback: data.isPlayback || 0,
    roomScreenshots: data.roomScreenshots,
    endLiveTime: data.endLiveTime || 0,
    roomSort: data.roomSort,
    businessType: data.businessType || 1,
    templateType: data.templateType || 1,
    eventId: data.eventId || '',
    eventRoomListView: data.eventRoomListView || [],
    teamId: data.teamId || '',
    teamRoomViews: data.teamRoomViews || [],
    starLiveRoomView: data.starLiveRoomView || [],
    gameAbbrList: data.gameAbbrList || [],
    roomTitle: data.title,
    roomDescription: data.description,
    createdTime: data.createdTime || null,
    updatedTime: data.updatedTime || null,
    startLiveTime: data.startLiveTime || null,
    normalMultiRoomList: data.normalMultiRoomList || [],
    chatRuleStatus: data.chatRuleStatus,
    chatRuleText: escape(data.chatRuleText || ''),
    authenticatedAnchor: data.authenticatedAnchor || 0,
    liveType: data === null || data === void 0 ? void 0 : data.liveType,
    blocked: !!(data !== null && data !== void 0 && data.blockCurrentUser) // 是否被屏蔽
  };
}