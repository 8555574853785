import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ConfirmPopupRsp = function ConfirmPopupRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
};
ConfirmPopupRsp.prototype._clone = function () {
  return new Nimo.ConfirmPopupRsp();
};
ConfirmPopupRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ConfirmPopupRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ConfirmPopupRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
};
ConfirmPopupRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
};
Nimo.ConfirmPopupRsp = ConfirmPopupRsp;
export default ConfirmPopupRsp;