import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GiftConfig';
import './SpecialGiftItem';
var GetGiftListByIdRsp = function GetGiftListByIdRsp() {
  this.iCode = 0;
  this.vGift = new Taf.Vector(new Nimo.GiftConfig());
  this.mSpecialGift = new Taf.Map(new Taf.INT32(), new Taf.Vector(new Nimo.SpecialGiftItem()));
  this.vGiftChannelPriority = new Taf.Vector(new Taf.INT32());
};
GetGiftListByIdRsp.prototype._clone = function () {
  return new Nimo.GetGiftListByIdRsp();
};
GetGiftListByIdRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetGiftListByIdRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetGiftListByIdRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeVector(1, this.vGift);
  os.writeMap(2, this.mSpecialGift);
  os.writeVector(3, this.vGiftChannelPriority);
};
GetGiftListByIdRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.vGift = is.readVector(1, false, this.vGift);
  this.mSpecialGift = is.readMap(2, false, this.mSpecialGift);
  this.vGiftChannelPriority = is.readVector(3, false, this.vGiftChannelPriority);
};
Nimo.GetGiftListByIdRsp = GetGiftListByIdRsp;
export default GetGiftListByIdRsp;