import ua from '@client/common/utils/ua';
export var isSupportSpeedTest = function () {
  // 忽略老edge和ie
  try {
    return window.performance && window.PerformanceResourceTiming && window.performance.addEventListener && window.performance.clearResourceTimings && window.performance.getEntriesByName && !ua.isIE && !(ua.isMSEdge && !ua.parser.satisfies({
      edge: '>70'
    }));
  } catch (e) {
    return false;
  }
}();