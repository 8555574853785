import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './EmoticonInfo';
var EmoticonGroup = function EmoticonGroup() {
  this.iType = 0;
  this.lOwnerId = 0;
  this.iGroupId = 0;
  this.vEmoticonInfo = new Taf.Vector(new Nimo.EmoticonInfo());
  this.sGroupName = "";
  this.bCanUse = false;
  this.iUserLevel = 0;
  this.sDesc = "";
  this.iSort = 0;
  this.sUrl = "";
  this.lUseTime = 0;
  this.vExtraData = new Taf.BinBuffer();
};
EmoticonGroup.prototype._clone = function () {
  return new Nimo.EmoticonGroup();
};
EmoticonGroup.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EmoticonGroup.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EmoticonGroup.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iType);
  os.writeInt64(1, this.lOwnerId);
  os.writeInt32(2, this.iGroupId);
  os.writeVector(3, this.vEmoticonInfo);
  os.writeString(4, this.sGroupName);
  os.writeBoolean(5, this.bCanUse);
  os.writeInt32(6, this.iUserLevel);
  os.writeString(7, this.sDesc);
  os.writeInt32(8, this.iSort);
  os.writeString(9, this.sUrl);
  os.writeInt64(10, this.lUseTime);
  os.writeBytes(11, this.vExtraData);
};
EmoticonGroup.prototype.readFrom = function (is) {
  this.iType = is.readInt32(0, false, this.iType);
  this.lOwnerId = is.readInt64(1, false, this.lOwnerId);
  this.iGroupId = is.readInt32(2, false, this.iGroupId);
  this.vEmoticonInfo = is.readVector(3, false, this.vEmoticonInfo);
  this.sGroupName = is.readString(4, false, this.sGroupName);
  this.bCanUse = is.readBoolean(5, false, this.bCanUse);
  this.iUserLevel = is.readInt32(6, false, this.iUserLevel);
  this.sDesc = is.readString(7, false, this.sDesc);
  this.iSort = is.readInt32(8, false, this.iSort);
  this.sUrl = is.readString(9, false, this.sUrl);
  this.lUseTime = is.readInt64(10, false, this.lUseTime);
  this.vExtraData = is.readBytes(11, false, this.vExtraData);
};
Nimo.EmoticonGroup = EmoticonGroup;
export default EmoticonGroup;