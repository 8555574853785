import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var VideoData = function VideoData() {
  this.videourl = "";
  this.Duration = 0;
  this.vtype = 0;
  this.iCdn = 0;
  this.lVid = 0;
  this.sVideoTypeName = "";
};
VideoData.prototype._clone = function () {
  return new Nimo.VideoData();
};
VideoData.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VideoData.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VideoData.prototype.writeTo = function (os) {
  os.writeString(0, this.videourl);
  os.writeInt32(1, this.Duration);
  os.writeInt32(2, this.vtype);
  os.writeInt32(3, this.iCdn);
  os.writeInt64(4, this.lVid);
  os.writeString(5, this.sVideoTypeName);
};
VideoData.prototype.readFrom = function (is) {
  this.videourl = is.readString(0, false, this.videourl);
  this.Duration = is.readInt32(1, false, this.Duration);
  this.vtype = is.readInt32(2, false, this.vtype);
  this.iCdn = is.readInt32(3, false, this.iCdn);
  this.lVid = is.readInt64(4, false, this.lVid);
  this.sVideoTypeName = is.readString(5, false, this.sVideoTypeName);
};
Nimo.VideoData = VideoData;
export default VideoData;