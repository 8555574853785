import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './PushVideoHis';
var GetPushVideoHisRsp = function GetPushVideoHisRsp() {
  this.lRoomId = 0;
  this.iTotalNum = 0;
  this.iPage = 0;
  this.iPageNum = 0;
  this.vVideos = new Taf.Vector(new Nimo.PushVideoHis());
  this.sTimezone = "";
  this.iModifyVideoStatusAuth = 0;
  this.iModifyRemainCnt = 0;
};
GetPushVideoHisRsp.prototype._clone = function () {
  return new Nimo.GetPushVideoHisRsp();
};
GetPushVideoHisRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPushVideoHisRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPushVideoHisRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt32(1, this.iTotalNum);
  os.writeInt32(2, this.iPage);
  os.writeInt32(3, this.iPageNum);
  os.writeVector(4, this.vVideos);
  os.writeString(5, this.sTimezone);
  os.writeInt32(6, this.iModifyVideoStatusAuth);
  os.writeInt32(7, this.iModifyRemainCnt);
};
GetPushVideoHisRsp.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.iTotalNum = is.readInt32(1, false, this.iTotalNum);
  this.iPage = is.readInt32(2, false, this.iPage);
  this.iPageNum = is.readInt32(3, false, this.iPageNum);
  this.vVideos = is.readVector(4, false, this.vVideos);
  this.sTimezone = is.readString(5, false, this.sTimezone);
  this.iModifyVideoStatusAuth = is.readInt32(6, false, this.iModifyVideoStatusAuth);
  this.iModifyRemainCnt = is.readInt32(7, false, this.iModifyRemainCnt);
};
Nimo.GetPushVideoHisRsp = GetPushVideoHisRsp;
export default GetPushVideoHisRsp;