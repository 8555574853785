import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var MarkReadReq = function MarkReadReq() {
  this.tUserId = new Nimo.UserId();
  this.lId = 0;
  this.lLatestMsgId = 0;
};
MarkReadReq.prototype._clone = function () {
  return new Nimo.MarkReadReq();
};
MarkReadReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MarkReadReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MarkReadReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lId);
  os.writeInt64(2, this.lLatestMsgId);
};
MarkReadReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lId = is.readInt64(1, false, this.lId);
  this.lLatestMsgId = is.readInt64(2, false, this.lLatestMsgId);
};
Nimo.MarkReadReq = MarkReadReq;
export default MarkReadReq;