import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
import './AnchorInfo';
var GetGiftListByTypeReq = function GetGiftListByTypeReq() {
  this.user = new Nimo.UserId();
  this.sLang = "";
  this.sClientType = "";
  this.iGiftListType = 0;
  this.iCallType = 0;
  this.vPlay = new Taf.Vector(new Taf.INT32());
  this.tAnchorInfo = new Nimo.AnchorInfo();
};
GetGiftListByTypeReq.prototype._clone = function () {
  return new Nimo.GetGiftListByTypeReq();
};
GetGiftListByTypeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetGiftListByTypeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetGiftListByTypeReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeString(1, this.sLang);
  os.writeString(2, this.sClientType);
  os.writeInt32(3, this.iGiftListType);
  os.writeInt32(4, this.iCallType);
  os.writeVector(5, this.vPlay);
  os.writeStruct(6, this.tAnchorInfo);
};
GetGiftListByTypeReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.sLang = is.readString(1, false, this.sLang);
  this.sClientType = is.readString(2, false, this.sClientType);
  this.iGiftListType = is.readInt32(3, false, this.iGiftListType);
  this.iCallType = is.readInt32(4, false, this.iCallType);
  this.vPlay = is.readVector(5, false, this.vPlay);
  this.tAnchorInfo = is.readStruct(6, false, this.tAnchorInfo);
};
Nimo.GetGiftListByTypeReq = GetGiftListByTypeReq;
export default GetGiftListByTypeReq;