import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './LiveRoomView';
var LiveRoomPKInfo = function LiveRoomPKInfo() {
  this.liveRoomViewA = new NimoBuss.LiveRoomView();
  this.liveRoomViewB = new NimoBuss.LiveRoomView();
  this.pkScoreA = 0;
  this.pkScoreB = 0;
};
LiveRoomPKInfo.prototype._clone = function () {
  return new NimoBuss.LiveRoomPKInfo();
};
LiveRoomPKInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveRoomPKInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveRoomPKInfo.prototype.writeTo = function (os) {
  os.writeStruct(0, this.liveRoomViewA);
  os.writeStruct(1, this.liveRoomViewB);
  os.writeInt64(2, this.pkScoreA);
  os.writeInt64(3, this.pkScoreB);
};
LiveRoomPKInfo.prototype.readFrom = function (is) {
  this.liveRoomViewA = is.readStruct(0, false, this.liveRoomViewA);
  this.liveRoomViewB = is.readStruct(1, false, this.liveRoomViewB);
  this.pkScoreA = is.readInt64(2, false, this.pkScoreA);
  this.pkScoreB = is.readInt64(3, false, this.pkScoreB);
};
NimoBuss.LiveRoomPKInfo = LiveRoomPKInfo;
export default LiveRoomPKInfo;