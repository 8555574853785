import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var MsgSessionReq = function MsgSessionReq() {
  this.tUserId = new Nimo.UserId();
  this.lId = 0;
  this.sSyncKey = "";
  this.iMsgBizSource = 0;
  this.lMaxMsgId = 0;
};
MsgSessionReq.prototype._clone = function () {
  return new Nimo.MsgSessionReq();
};
MsgSessionReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgSessionReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgSessionReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lId);
  os.writeString(2, this.sSyncKey);
  os.writeInt32(3, this.iMsgBizSource);
  os.writeInt64(4, this.lMaxMsgId);
};
MsgSessionReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lId = is.readInt64(1, false, this.lId);
  this.sSyncKey = is.readString(2, false, this.sSyncKey);
  this.iMsgBizSource = is.readInt32(3, false, this.iMsgBizSource);
  this.lMaxMsgId = is.readInt64(4, false, this.lMaxMsgId);
};
Nimo.MsgSessionReq = MsgSessionReq;
export default MsgSessionReq;