import domains from '@client/common/services/domains';
import HYSDK from './getHYSDK';
import { events, getInstanceCache, getInstance } from '@nimo-fed/models-nimo/common/lib/nm-socket/signalingInstance';
import { getUnsafeAuth } from '@client/common/services/getUnsafeAuth';
import littleAuth from '@nimo-fed/little-auth';
function getPlayerIns(options) {
  var _ref = options || {},
    deviceId = _ref.deviceId;
  var player = null;

  // eslint-disable-next-line no-undef
  if (HYSDK) {
    HYSDK.setAppId(domains.hyplayer_appid);
    var signal = getInstanceCache();
    var guid = deviceId || getUnsafeAuth().guid;
    if (signal) {
      HYSDK.setSignalSdk(signal);
      if (!guid) onGuid(signal);
    } else {
      getInstance({
        // 不确定短链接的可不可以
        noConnect: 1
      }).then(function (instance) {
        HYSDK.setSignalSdk(instance);
        if (!guid) onGuid(instance);
      });
    }
    if (guid) {
      setGuid(guid);
    }
    player = HYSDK.createPlayer({});
  }
  return player;
}
export default getPlayerIns;
function onGuid(signal) {
  if (/nimo/.test(navigator.userAgent)) {
    littleAuth.getUserInfo().then(function (_ref2) {
      var guid = _ref2.guid;
      if (guid) setGuid(guid);
    });
  } else {
    var _handler = function handler(_ref3) {
      var guid = _ref3.guid;
      signal.off(events.WEBSOCKET_DOLAUNCH, _handler);
      if (guid) setGuid(guid);
    };
    signal.on(events.WEBSOCKET_DOLAUNCH, _handler);
  }
}
function setGuid(sguid) {
  HYSDK.setProperties({
    deviceId: sguid
  });
}