import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var AnchorLevelConfigReq = function AnchorLevelConfigReq() {
  this.iLevel = 0;
  this.iLangID = 0;
  this.iNeedNextLevel = 0;
};
AnchorLevelConfigReq.prototype._clone = function () {
  return new Nimo.AnchorLevelConfigReq();
};
AnchorLevelConfigReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelConfigReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelConfigReq.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iLevel);
  os.writeInt32(1, this.iLangID);
  os.writeInt32(2, this.iNeedNextLevel);
};
AnchorLevelConfigReq.prototype.readFrom = function (is) {
  this.iLevel = is.readInt32(0, false, this.iLevel);
  this.iLangID = is.readInt32(1, false, this.iLangID);
  this.iNeedNextLevel = is.readInt32(2, false, this.iNeedNextLevel);
};
Nimo.AnchorLevelConfigReq = AnchorLevelConfigReq;
export default AnchorLevelConfigReq;