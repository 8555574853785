import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetEmoticonByTypeReq = function GetEmoticonByTypeReq() {
  this.tUser = new Nimo.UserId();
  this.lAnchorId = 0;
  this.vType = new Taf.Vector(new Taf.INT32());
};
GetEmoticonByTypeReq.prototype._clone = function () {
  return new Nimo.GetEmoticonByTypeReq();
};
GetEmoticonByTypeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEmoticonByTypeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEmoticonByTypeReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lAnchorId);
  os.writeVector(2, this.vType);
};
GetEmoticonByTypeReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lAnchorId = is.readInt64(1, false, this.lAnchorId);
  this.vType = is.readVector(2, false, this.vType);
};
Nimo.GetEmoticonByTypeReq = GetEmoticonByTypeReq;
export default GetEmoticonByTypeReq;