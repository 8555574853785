import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var TextFormat = function TextFormat() {
  this.mText = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iFontColor = -1;
  this.iIsBold = 0;
  this.iIsUnderline = 0;
  this.mFontColor = new Taf.Map(new Taf.INT32(), new Taf.STRING());
};
TextFormat.prototype._clone = function () {
  return new Nimo.TextFormat();
};
TextFormat.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TextFormat.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TextFormat.prototype.writeTo = function (os) {
  os.writeMap(0, this.mText);
  os.writeInt32(1, this.iFontColor);
  os.writeInt32(2, this.iIsBold);
  os.writeInt32(3, this.iIsUnderline);
  os.writeMap(4, this.mFontColor);
};
TextFormat.prototype.readFrom = function (is) {
  this.mText = is.readMap(0, false, this.mText);
  this.iFontColor = is.readInt32(1, false, this.iFontColor);
  this.iIsBold = is.readInt32(2, false, this.iIsBold);
  this.iIsUnderline = is.readInt32(3, false, this.iIsUnderline);
  this.mFontColor = is.readMap(4, false, this.mFontColor);
};
Nimo.TextFormat = TextFormat;
export default TextFormat;