import { cookie } from '@client/common/utils/storage';
import { safeThemeValue } from '@client/common/utils/cookieFn';
export var getPageTheme = function getPageTheme() {
  var _window;
  var _ref = ((_window = window) === null || _window === void 0 ? void 0 : _window.nimo_base) || {},
    hasMulTheme = _ref.hasMulTheme,
    defaultTheme = _ref.defaultTheme;
  if (hasMulTheme) {
    var _themeMode = cookie.get('theme') || 1;
    return safeThemeValue(_themeMode);
  }
  if (defaultTheme) {
    return safeThemeValue(defaultTheme);
  }
  return 1;
};