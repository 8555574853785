import { liveRoomModel } from '@client/common/models/api/liveRoom';
import { homeStore } from '@desktop/src/common/models/store';
import { normalizeRoomInfoWithOrigin } from '@client/common/services/room.js';
import { initRoomInfo, initRoomGiftList, initFacialsList, initVipSubscribeStatus } from '@desktop/src/common/models/home/actions';
import emoticonModel from '@client/common/models/wup/emoticon';
import giftMgr from '@client/common/services/giftMgr';
import { getDecorationInfoList } from '@desktop/src/common/models/common/actions';
import decoration from '@client/common/models/wup/decoration';
import liveSocketModel from '@client/common/models/socket/liveSocketModel';
import { subscribeModel } from '@client/common/models/vip/subscribe';
export function fetchRoomInfo(udbUserId, lang) {
  if (!udbUserId) return {
    status: 404
  };
  return liveRoomModel.fetchLiveRoomInfo({
    udbUserId: udbUserId,
    anchorId: udbUserId,
    language: lang
  }).then(function (data) {
    var roomInfo = normalizeRoomInfoWithOrigin(data);
    homeStore.dispatch(initRoomInfo(roomInfo));
    return {
      status: 200,
      data: roomInfo
    };
  }, function (res) {
    var err = res || {};
    // 10306    账号已注销
    return {
      status: err.code === 11022 || err.code === 11033 || err.code === 11051 || err.code === 10306 ? 404 : 500
    };
  });
}
export var initDecorationInfoListAsync = function initDecorationInfoListAsync() {
  return function (dispatch) {
    // eslint-disable-next-line import/no-named-as-default-member
    return decoration.getDecorationInfoList().then(function (list) {
      return dispatch(getDecorationInfoList(list));
    }).catch(function () {});
  };
};
export var initRoomGiftListAsync = function initRoomGiftListAsync(dataSource) {
  return function (dispatch) {
    var roomId = dataSource.roomId,
      auId = dataSource.auId,
      gameId = dataSource.gameId,
      anchorLang = dataSource.anchorLang,
      anchorCountryCode = dataSource.anchorCountryCode;
    giftMgr.getGiftListOfRoom({
      roomId: roomId,
      gameId: gameId,
      anchorLang: anchorLang,
      anchorCountryCode: anchorCountryCode,
      anchorId: auId
    }).then(function (data) {
      var _ref = data || {},
        giftList = _ref.giftList,
        giftTabList = _ref.giftTabList;
      dispatch(initRoomGiftList(giftList, giftTabList));
    });
  };
};
export var subscribeRoomChannel = function subscribeRoomChannel(_ref2) {
  var roomId = _ref2.roomId,
    auId = _ref2.auId,
    nickname = _ref2.nickname,
    gameId = _ref2.gameId,
    anchorLang = _ref2.anchorLang,
    anchorCountryCode = _ref2.anchorCountryCode;
  if (roomId) {
    // 确保容器组件优先建立长连接，并初始化运行时直播间信息
    liveSocketModel.dispose() // 先取消订阅之前的直播间，再初始化当前直播间
    .init({
      roomId: roomId,
      auId: auId,
      nickname: nickname,
      gameId: gameId,
      anchorLang: anchorLang,
      anchorCountryCode: anchorCountryCode
    });
  }
};
export var initFacialsListAsync = function initFacialsListAsync(dataSource) {
  return function (dispatch) {
    var roomId = dataSource.roomId;
    return emoticonModel.getEmoticonByRoom({
      roomId: roomId
    }).then(function (res) {
      dispatch(initFacialsList(res));
    }).catch(function () {});
  };
};
export function initVipSubscribeStatusAsync(_ref3) {
  var auId = _ref3.auId;
  return function (dispatch) {
    return subscribeModel.fetchVipSubscribeStatus({
      anchorId: auId
    }).then(function (res) {
      dispatch(initVipSubscribeStatus(res === null || res === void 0 ? void 0 : res.status));
    });
  };
}