import auth from '@client/common/services/auth';
import { local } from './storage';
var key = 'nimo_anmos_uid';
var uid = local.get(key);
var _createUuid = function _createUuid() {
  var chars = '0123456789'.split('');
  var uuid = [];
  for (var i = 0; i < 13; i += 1) {
    uuid[i] = chars[0 | Math.random() * 10];
  }
  return uuid.join('');
};

/**
 * 获取匿名uid
 * 参考国内的做法 @huyafed/create-anonymous-uid
 * 从localStorage读取匿名uid
 * 取不到则临时生成一个，调ubd anonymous方法获取匿名uid并写入localStorage
 * @returns
 */
export var getAnmosUid = function getAnmosUid() {
  if (uid) {
    return uid;
  } else {
    uid = _createUuid();
    auth.anonymousLogin({
      action: 1,
      style: 1,
      success: function success(res) {
        var _ref = res || {},
          data = _ref.data;
        uid = data === null || data === void 0 ? void 0 : data.uid;
        local.set(key, uid);
      }
    });
    return uid;
  }
};