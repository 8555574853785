import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import Nimo from '../nimo';
import '../Nimo/AVPostMediaRscInfo';
var UserRealTimeClipRsp = function UserRealTimeClipRsp() {
  this.iCode = 0;
  this.sMessage = "";
  this.vInfos = new Taf.Vector(new Nimo.AVPostMediaRscInfo());
  this.videoId = "";
};
UserRealTimeClipRsp.prototype._clone = function () {
  return new NimoBuss.UserRealTimeClipRsp();
};
UserRealTimeClipRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserRealTimeClipRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserRealTimeClipRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sMessage);
  os.writeVector(2, this.vInfos);
  os.writeString(3, this.videoId);
};
UserRealTimeClipRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sMessage = is.readString(1, false, this.sMessage);
  this.vInfos = is.readVector(2, false, this.vInfos);
  this.videoId = is.readString(3, false, this.videoId);
};
NimoBuss.UserRealTimeClipRsp = UserRealTimeClipRsp;
export default UserRealTimeClipRsp;