import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var FollowRequestView = function FollowRequestView() {
  this.relationship = 0;
};
FollowRequestView.prototype._clone = function () {
  return new NimoBuss.FollowRequestView();
};
FollowRequestView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowRequestView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowRequestView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.relationship);
};
FollowRequestView.prototype.readFrom = function (is) {
  this.relationship = is.readInt32(0, false, this.relationship);
};
NimoBuss.FollowRequestView = FollowRequestView;
export default FollowRequestView;