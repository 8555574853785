/**
 * @template T
 * @param {T[]} items
 * @param {(item:T) => string | number} getId
 * @returns
 */
var uniqBySavePrev = function uniqBySavePrev(items, getId) {
  var set = new Set();
  var array = [];
  for (var i = 0; i < items.length; i += 1) {
    var element = items[i];
    var id = getId(element);
    if (!set.has(id)) {
      set.add(id);
      array.push(element);
    }
  }
  return array;
};
export default uniqBySavePrev;