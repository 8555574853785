import ua from '@client/common/utils/ua';
import { getGreyGroup } from '@nimo-fed/util';
import { getUrlParmasLast } from '@client/common/utils/getUrlParams';
import supportInstallPrompt from '@client/common/lib/install-pwa/supportInstallPrompt';
var registered = false;
function onLoad() {
  if (document.readyState !== 'complete') {
    return new Promise(function (res) {
      return window.addEventListener('load', function () {
        return res();
      });
    });
  }
  return Promise.resolve();
}
var noCacheSw = function noCacheSw() {
  onLoad().then(function () {
    navigator.serviceWorker.register('/sw.js');
  });
};
function setGreyGroup(key) {
  var greyGroup = 0;
  try {
    greyGroup = parseInt(localStorage.getItem(key), 10);
  } catch (e) {
    return -1;
  }
  if (isNaN(greyGroup)) {
    for (var _len = arguments.length, groups = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      groups[_key - 1] = arguments[_key];
    }
    greyGroup = getGreyGroup.apply(void 0, groups);
    try {
      localStorage.setItem(key, greyGroup);
    } catch (e) {
      return -1;
    }
  }
  return greyGroup;
}

// 获取SW AB 变量
export var getSwAB = function getSwAB() {
  if (process.env.platform !== 'mobile') return;
  if (ua.isInNimoApp) return;
  if (ua.isInNimoPc) return;
  if (!('serviceWorker' in navigator)) return;
  // 暂时只让支持安装pwa的浏览器加sw
  if (!supportInstallPrompt()) return;
  /** @type {string} */
  var abtest = getUrlParmasLast('swab');
  if (abtest) {
    try {
      localStorage.setItem('nimo-swab', abtest);
    } catch (_) {
      //
    }
    // eslint-disable-next-line consistent-return
    return "sw-".concat(abtest);
  }

  // 灰度 50% workbox(sw-1)
  var result = setGreyGroup('nimo-swab', 5, 5);
  if (result < 0) return;

  // eslint-disable-next-line consistent-return
  return "sw-".concat(result);
};
export var setSwAB = function setSwAB() {
  if (process.env.NO_CACHE) return;
  var swAB = getSwAB();
  if (swAB === undefined) return;
  window._nmswab = swAB;
};
export var registerServiceWorker = function registerServiceWorker() {
  if (ua.isInNimoPc) return;
  if (registered) return;
  registered = true;
  if (supportInstallPrompt()) {
    noCacheSw();
  }
};