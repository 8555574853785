import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var AnchorTaskDetailByUidReq = function AnchorTaskDetailByUidReq() {
  this.user = new Nimo.UserId();
  this.lUID = 0;
};
AnchorTaskDetailByUidReq.prototype._clone = function () {
  return new Nimo.AnchorTaskDetailByUidReq();
};
AnchorTaskDetailByUidReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorTaskDetailByUidReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorTaskDetailByUidReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lUID);
};
AnchorTaskDetailByUidReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lUID = is.readInt64(1, false, this.lUID);
};
Nimo.AnchorTaskDetailByUidReq = AnchorTaskDetailByUidReq;
export default AnchorTaskDetailByUidReq;