import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var Tag = function Tag() {
  this.tagId = "";
  this.tagType = 0;
  this.tagName = "";
};
Tag.prototype._clone = function () {
  return new NimoBuss.Tag();
};
Tag.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
Tag.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
Tag.prototype.writeTo = function (os) {
  os.writeString(0, this.tagId);
  os.writeInt32(1, this.tagType);
  os.writeString(2, this.tagName);
};
Tag.prototype.readFrom = function (is) {
  this.tagId = is.readString(0, false, this.tagId);
  this.tagType = is.readInt32(1, false, this.tagType);
  this.tagName = is.readString(2, false, this.tagName);
};
NimoBuss.Tag = Tag;
export default Tag;