import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { INIT_ROOM_INFO, UPDATE_MSG_WHITE_LIST, RESET_ROOM_STORE, ADD_GIFT_MSG, REMOVE_GIFT_MSG, INIT_ROOM_GIFT_LIST, UPDATE_ACCOUNT_BALANCE, PLAYER_PAUSE, ANCHOR_ONLINE, UPDATE_USER_INFO, STREAM_UPDATE, UPDATE_CURR_FANS_BARRAGE, UPDATE_GIFT_PACK_NEW, UPDATE_WEAR_BADGE_INFO, UPDATE_BADGE_COUNT, INIT_ROOM_ACTIVITY_LIST, ANCHOR_PK_ON, UPDATE_LIVE_ROOM_VIEW_COUNT, UPDATE_ANCHOR_FOLLOW_COUNT, UPDATE_ROOM_FOLLOW_STATUS, INIT_FACIALS_LIST, UPDATE_CHATROOM_POPOUT, UPDATE_FAN_GROUP_INFO, INIT_SUBSCRIBE_STATUS, UPDATE_ROOM_PACKAGE_LIST, SET_MEETING_INFO, UPDATE_SOCIAL_MEDIA_POPOUT, SET_PAY_CHAT_LIST, SET_ANNOUNCEMENT, SET_MEETING_EMOJI_LIST, UPDATE_LINK_MIC_SEATS, UPDATE_LINK_MIC_HOST, UPDATE_SHOW_RECIPIENT, UPDATE_PENALTY_INFO, UPDATE_MGIFT_CONTRIBUTE_INFOS } from './actions';
import { unescape } from '@client/common/utils/dom';
var initialState = {
  isOnline: false,
  // 是否在播
  roomSort: 0,
  // 直播间类型
  playerState: 'idle',
  // 播放器状态
  giftList: [],
  // 直播间礼物列表
  giftListLoaded: false,
  // 直播间礼物列表是否已加载成功
  giftTabList: [],
  packageList: [],
  accountBalance: {
    // 用户账户余额
    coin: {},
    // 金币
    gem: {},
    // 宝石
    diamond: {},
    // 钻石
    r_diamond: {} // r 钻
  },
  roomInfo: {},
  userInfo: {},
  // 当前用户信息
  // 流数据，单直播间只有1个。但存在多个播放器的情况则会有多个流，要通过直播间id区分
  // 每个流数据有roomLine和playbackVideoData两个字段，分别表示直播流地址和回放地址
  stream: {},
  bsLotteryOn: false,
  // 是否正在进行弹幕分享抽奖
  multiNodeLotteryOn: false,
  // 是否正在进行多节点抽奖
  anchorPKOn: false,
  // 是否正在进行主播 pk,
  anchorPKOnFetched: false,
  // 是否已经得到主播 pk状态
  mcTogetherOn: false,
  // 是否正在进行连麦
  // TODO: 播放器是否全屏
  msgWhiteList: [],
  // 重复聊天消息白名单，存放弹幕抽奖、弹幕投票内容等
  currFansBarrage: {
    barrageType: 0,
    fanLevel: 0,
    color: '#fff',
    open: true
  },
  // 粉丝弹幕-最后一次使用的粉丝
  wearBadgeInfo: null,
  // 粉丝团-当前佩戴的徽章
  badgeCount: 0,
  // 粉丝团-当前佩拥有的徽章总数
  giftPackNew: false,
  // 是否有新得到的背包礼物
  normalActivityList: null,
  // 直播间普通展示位
  specialActivity: null,
  // 直播间S展示位
  viewCount: null,
  // 直播间人气
  followCount: null,
  // 主播粉丝数量
  isFollow: false,
  gotFollowed: false,
  facialsList: [],
  // 直播间表情列表
  chatRoomPopout: false,
  // 是否弹出公屏
  socialMediaPopout: false,
  // 是否弹出社媒绑定弹窗
  fanGroupInfo: {},
  announcement: '',
  // 房间公告
  subscribeStatus: 1,
  // 用户对当前主播订阅状态
  giftMsgList: [],
  // 缓存礼物消息（目前仅缓存跳转直播间的世界横幅消息）
  meetingEmojiList: null,
  linkMicList: [],
  // 多人连麦观众
  linkMicHost: null,
  // 多人连麦主播
  showRecipient: false,
  // 多人送礼显示接受者
  penaltyInfo: null,
  // 直播间信息
  mGiftContributeInfos: {} // 礼物冠名信息 key:giftId value:对应信息，ID1269349-礼物墙优化新增
};

// 通用合并state的action type
var commonMergeTypes = [INIT_ROOM_GIFT_LIST, UPDATE_ACCOUNT_BALANCE, PLAYER_PAUSE, ANCHOR_ONLINE, UPDATE_USER_INFO, STREAM_UPDATE, UPDATE_CURR_FANS_BARRAGE, UPDATE_GIFT_PACK_NEW, UPDATE_WEAR_BADGE_INFO, UPDATE_BADGE_COUNT, INIT_ROOM_ACTIVITY_LIST, ANCHOR_PK_ON, UPDATE_LIVE_ROOM_VIEW_COUNT, UPDATE_ANCHOR_FOLLOW_COUNT, UPDATE_ROOM_FOLLOW_STATUS, INIT_FACIALS_LIST, UPDATE_CHATROOM_POPOUT, UPDATE_FAN_GROUP_INFO, INIT_SUBSCRIBE_STATUS, UPDATE_ROOM_PACKAGE_LIST, SET_MEETING_INFO, UPDATE_SOCIAL_MEDIA_POPOUT, SET_PAY_CHAT_LIST, SET_ANNOUNCEMENT, SET_MEETING_EMOJI_LIST, UPDATE_LINK_MIC_SEATS, UPDATE_LINK_MIC_HOST, UPDATE_SHOW_RECIPIENT, UPDATE_PENALTY_INFO, UPDATE_MGIFT_CONTRIBUTE_INFOS];

// eslint-disable-next-line default-param-last
function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = _objectWithoutProperties(action, _excluded);
  if (commonMergeTypes.indexOf(type) > -1) {
    return _objectSpread(_objectSpread({}, state), payload);
  }
  switch (type) {
    case INIT_ROOM_INFO:
      {
        var anchorAnnouncement = payload === null || payload === void 0 ? void 0 : payload.anchorAnnouncement;
        var announcement = anchorAnnouncement ? unescape(anchorAnnouncement) : '';
        return _objectSpread(_objectSpread({}, state), {}, {
          roomInfo: _objectSpread({}, payload),
          announcement: announcement // 房间公告
        });
      }
    case RESET_ROOM_STORE:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        roomInfo: state.roomInfo,
        // FIXME: 如果充值 roomInfo，小窗播放器无法复用直播间信息，导致显示有问题
        userInfo: state.userInfo,
        giftMsgList: state.giftMsgList // 缓存的礼物消息不能清空
      });
    case UPDATE_MSG_WHITE_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        msgWhiteList: updateMsgWhiteList(state.msgWhiteList, payload)
      });
    case ADD_GIFT_MSG:
      return _objectSpread(_objectSpread({}, state), {}, {
        giftMsgList: updateMsgWhiteList(state.giftMsgList, {
          updateType: 'add',
          msg: payload.message
        })
      });
    case REMOVE_GIFT_MSG:
      return _objectSpread(_objectSpread({}, state), {}, {
        giftMsgList: updateMsgWhiteList(state.giftMsgList, {
          updateType: 'delete',
          msg: payload.message
        })
      });
    default:
      return state;
  }
}
function updateMsgWhiteList(list, data) {
  var _ref = data || {},
    updateType = _ref.updateType,
    msg = _ref.msg;
  var _msgList = msg;
  var _newList = list;
  if (!Array.isArray(msg)) {
    _msgList = [msg];
  }
  switch (updateType) {
    case 'add':
      _newList = [].concat(_toConsumableArray(list), _toConsumableArray(_msgList));
      break;
    case 'delete':
      _newList = list.filter(function (item) {
        return _msgList.indexOf(item) === -1;
      });
      break;
    default:
      break;
  }
  return _newList;
}
export default commonReducer;