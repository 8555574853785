import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var VideoItemView = function VideoItemView() {
  this.resolution = "";
  this.videoUrl = "";
  this.IResolution = 0;
  this.iResoVideoType = 0;
  this.sResoVideoTypeName = "";
  this.sRscID = "";
};
VideoItemView.prototype._clone = function () {
  return new NimoBuss.VideoItemView();
};
VideoItemView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VideoItemView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VideoItemView.prototype.writeTo = function (os) {
  os.writeString(0, this.resolution);
  os.writeString(1, this.videoUrl);
  os.writeInt32(2, this.IResolution);
  os.writeInt32(3, this.iResoVideoType);
  os.writeString(4, this.sResoVideoTypeName);
  os.writeString(5, this.sRscID);
};
VideoItemView.prototype.readFrom = function (is) {
  this.resolution = is.readString(0, false, this.resolution);
  this.videoUrl = is.readString(1, false, this.videoUrl);
  this.IResolution = is.readInt32(2, false, this.IResolution);
  this.iResoVideoType = is.readInt32(3, false, this.iResoVideoType);
  this.sResoVideoTypeName = is.readString(4, false, this.sResoVideoTypeName);
  this.sRscID = is.readString(5, false, this.sRscID);
};
NimoBuss.VideoItemView = VideoItemView;
export default VideoItemView;