import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetMessageBannedOperateReq = function GetMessageBannedOperateReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.lUserUDBId = 0;
  this.iPageSize = 0;
  this.iCurrentPage = 0;
};
GetMessageBannedOperateReq.prototype._clone = function () {
  return new Nimo.GetMessageBannedOperateReq();
};
GetMessageBannedOperateReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMessageBannedOperateReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMessageBannedOperateReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lUserUDBId);
  os.writeInt32(3, this.iPageSize);
  os.writeInt32(4, this.iCurrentPage);
};
GetMessageBannedOperateReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lUserUDBId = is.readInt64(2, false, this.lUserUDBId);
  this.iPageSize = is.readInt32(3, false, this.iPageSize);
  this.iCurrentPage = is.readInt32(4, false, this.iCurrentPage);
};
Nimo.GetMessageBannedOperateReq = GetMessageBannedOperateReq;
export default GetMessageBannedOperateReq;