import ensureLogin from './ensureLogin';
import { getCurRouteKey } from './routers';
window.isNewDashboard = true;

// 登录，弹出登录窗口
export var userInfo = ensureLogin();
export var setPageType = function setPageType(pathname) {
  var key = getCurRouteKey(pathname);
  window.G_PAGE_TYPE = key === 'notFound' ? '' : "dashboard_".concat(key);
};
if (userInfo) {
  setPageType(location.pathname);
}