import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var ForbidUserMessageReq = function ForbidUserMessageReq() {
  this.user = new Nimo.UserId();
  this.lForbidUid = 0;
  this.lRoomId = 0;
  this.lTimeS = 0;
  this.sReason = "";
  this.iVersion = 0;
  this.lAnchorUid = 0;
};
ForbidUserMessageReq.prototype._clone = function () {
  return new Nimo.ForbidUserMessageReq();
};
ForbidUserMessageReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ForbidUserMessageReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ForbidUserMessageReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lForbidUid);
  os.writeInt64(2, this.lRoomId);
  os.writeInt64(3, this.lTimeS);
  os.writeString(4, this.sReason);
  os.writeInt32(5, this.iVersion);
  os.writeInt64(6, this.lAnchorUid);
};
ForbidUserMessageReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lForbidUid = is.readInt64(1, false, this.lForbidUid);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
  this.lTimeS = is.readInt64(3, false, this.lTimeS);
  this.sReason = is.readString(4, false, this.sReason);
  this.iVersion = is.readInt32(5, false, this.iVersion);
  this.lAnchorUid = is.readInt64(6, false, this.lAnchorUid);
};
Nimo.ForbidUserMessageReq = ForbidUserMessageReq;
export default ForbidUserMessageReq;