import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState, useCallback } from 'react';
import visitHistoryWupModel from '@client/common/models/wup/visitHistoryWupModel.js';
var useFetchHistoryList = function useFetchHistoryList(userInfo) {
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    historyList = _useState2[0],
    setHistoryList = _useState2[1];
  var _useState3 = useState(true),
    _useState4 = _slicedToArray(_useState3, 2),
    isLoading = _useState4[0],
    setIsLoading = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    isError = _useState6[0],
    setIsError = _useState6[1];
  useEffect(function () {
    fetchHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var fetchHistoryList = useCallback(function () {
    visitHistoryWupModel.getVisitHistory({
      udbUserId: userInfo === null || userInfo === void 0 ? void 0 : userInfo.udbUserId,
      countryCode: userInfo === null || userInfo === void 0 ? void 0 : userInfo.country
    }).then(function (rsp) {
      var _rsp$visitHistoryInfo = rsp.visitHistoryInfoList,
        visitHistoryInfoList = _rsp$visitHistoryInfo === void 0 ? [] : _rsp$visitHistoryInfo;
      setHistoryList(formatHistoryByTime(visitHistoryInfoList));
    }).catch(function () {
      setIsError(true);
    }).finally(function () {
      setIsLoading(false);
    });
  }, [userInfo === null || userInfo === void 0 ? void 0 : userInfo.country, userInfo === null || userInfo === void 0 ? void 0 : userInfo.udbUserId]);
  var updateHistoryList = function updateHistoryList() {
    return fetchHistoryList();
  };
  return [{
    historyList: historyList,
    isLoading: isLoading,
    isError: isError
  }, updateHistoryList];
};
var formatHistoryByTime = function formatHistoryByTime() {
  var dataSources = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var todayStartStamp = new Date().setHours(0, 0, 0, 0); // 今天00:00
  var yesterdayStartStamp = todayStartStamp - 24 * 60 * 60 * 1000; // 昨天00:00

  var result = {
    todayHistory: [],
    yesterdayHistory: [],
    otherHistory: []
  };
  dataSources.forEach(function (data) {
    var visitTime = data.visitTime;
    if (visitTime > todayStartStamp) {
      result.todayHistory.push(data);
    } else if (visitTime > yesterdayStartStamp) {
      result.yesterdayHistory.push(data);
    } else {
      result.otherHistory.push(data);
    }
  });
  return result;
};
export default useFetchHistoryList;