import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var CodeLineInfo = function CodeLineInfo() {
  this.iCdnType = 0;
  this.sFlvUrl = "";
  this.sHlsUrl = "";
  this.sRtmpUrl = "";
  this.iCdnTypeHY = 0;
};
CodeLineInfo.prototype._clone = function () {
  return new Nimo.CodeLineInfo();
};
CodeLineInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CodeLineInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CodeLineInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCdnType);
  os.writeString(1, this.sFlvUrl);
  os.writeString(2, this.sHlsUrl);
  os.writeString(3, this.sRtmpUrl);
  os.writeInt32(4, this.iCdnTypeHY);
};
CodeLineInfo.prototype.readFrom = function (is) {
  this.iCdnType = is.readInt32(0, false, this.iCdnType);
  this.sFlvUrl = is.readString(1, false, this.sFlvUrl);
  this.sHlsUrl = is.readString(2, false, this.sHlsUrl);
  this.sRtmpUrl = is.readString(3, false, this.sRtmpUrl);
  this.iCdnTypeHY = is.readInt32(4, false, this.iCdnTypeHY);
};
Nimo.CodeLineInfo = CodeLineInfo;
export default CodeLineInfo;