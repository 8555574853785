import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var StickerEffect = function StickerEffect() {
  this.sResource = "";
};
StickerEffect.prototype._clone = function () {
  return new Nimo.StickerEffect();
};
StickerEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
StickerEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
StickerEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sResource);
};
StickerEffect.prototype.readFrom = function (is) {
  this.sResource = is.readString(0, false, this.sResource);
};
Nimo.StickerEffect = StickerEffect;
export default StickerEffect;