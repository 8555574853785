import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var FireRoomManagerReq = function FireRoomManagerReq() {
  this.user = new Nimo.UserId();
  this.lUid = 0;
  this.lRoomId = 0;
  this.iType = 0;
};
FireRoomManagerReq.prototype._clone = function () {
  return new Nimo.FireRoomManagerReq();
};
FireRoomManagerReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FireRoomManagerReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FireRoomManagerReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lUid);
  os.writeInt64(2, this.lRoomId);
  os.writeInt32(3, this.iType);
};
FireRoomManagerReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
  this.iType = is.readInt32(3, false, this.iType);
};
Nimo.FireRoomManagerReq = FireRoomManagerReq;
export default FireRoomManagerReq;