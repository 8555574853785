import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _broadcasts;
/* eslint-disable camelcase */
import WS_JServerPacket from '@client/jce/Nimo/WS_JServerPacket';
import EURI from '@client/jce/Nimo/EURI';
import TafTransDownSocketModel from '../TafTransDownSocketModel';
var EUriRoomRankChange = EURI.EUriRoomRankChange,
  EUriRoomRankUp = EURI.EUriRoomRankUp,
  EUriRoomFansRankChange = EURI.EUriRoomFansRankChange,
  EUriRoomGiftsChange = EURI.EUriRoomGiftsChange,
  EUriResRankUp = EURI.EUriResRankUp,
  EUriAuditPunish = EURI.EUriAuditPunish,
  EUriFollow = EURI.EUriFollow,
  EUriReJoinClubTip = EURI.EUriReJoinClubTip,
  EUriFanUpgradeClub = EURI.EUriFanUpgradeClub,
  EUriFanJoinClub = EURI.EUriFanJoinClub,
  EUriSubcriptionNotice = EURI.EUriSubcriptionNotice;
export { WS_JServerPacket, EUriRoomRankChange, EUriRoomRankUp, EUriRoomFansRankChange, EUriRoomGiftsChange, EUriResRankUp, EUriAuditPunish, EUriFollow, EUriReJoinClubTip, EUriFanUpgradeClub, EUriFanJoinClub, EUriSubcriptionNotice };

/**
 * 透传协议广播集合
 */
export default new TafTransDownSocketModel({
  broadcasts: (_broadcasts = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_broadcasts, EUriRoomRankChange, [EUriRoomRankChange, WS_JServerPacket]), EUriRoomRankUp, [EUriRoomRankUp, WS_JServerPacket]), EUriRoomFansRankChange, [EUriRoomFansRankChange, WS_JServerPacket]), EUriRoomGiftsChange, [EUriRoomGiftsChange, WS_JServerPacket]), EUriResRankUp, [EUriResRankUp, WS_JServerPacket]), EUriAuditPunish, [EUriAuditPunish, WS_JServerPacket]), EUriFollow, [EUriFollow, WS_JServerPacket]), EUriReJoinClubTip, [EUriReJoinClubTip, WS_JServerPacket]), EUriFanUpgradeClub, [EUriFanUpgradeClub, WS_JServerPacket]), EUriFanJoinClub, [EUriFanJoinClub, WS_JServerPacket]), _defineProperty(_broadcasts, EUriSubcriptionNotice, [EUriSubcriptionNotice, WS_JServerPacket]))
});