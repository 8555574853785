var opened;
var timer;
export var openStreamTargets = function openStreamTargets(data) {
  var wWidth = window.outerWidth;
  var wHeight = window.outerHeight;
  var width = 600;
  var height = 700;
  var top = (wHeight - height) / 2;
  var left = (wWidth - width) / 2;
  if (opened && !opened.closed) {
    opened.focus();
  } else {
    var _ref = data || {},
      socialMediaId = _ref.socialMediaId;
    var hash = socialMediaId ? "#".concat(socialMediaId) : '';
    opened = window.open("//".concat(DOMAINS.maindomain, "/popout/stream-targets").concat(hash), '_blank', "width=".concat(width, ",height=").concat(height, ",top=").concat(top, ",left=").concat(left));
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    if (opened) {
      timer = setInterval(function () {
        // gc after closed
        if (!opened || opened.closed) {
          if (timer) {
            clearInterval(timer);
          }
          timer = null;
          opened = null;
        }
      }, 1000);
    }
  }
  return opened;
};