import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var CheckUserStatusInRoomReq = function CheckUserStatusInRoomReq() {
  this.tUser = new Nimo.UserId();
  this.lRoomId = 0;
  this.lNimoId = 0;
  this.vRoomPrivilegete = new Taf.Vector(new Taf.INT32());
  this.lAnchorUid = 0;
  this.lUdbUserId = 0;
};
CheckUserStatusInRoomReq.prototype._clone = function () {
  return new Nimo.CheckUserStatusInRoomReq();
};
CheckUserStatusInRoomReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CheckUserStatusInRoomReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CheckUserStatusInRoomReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lNimoId);
  os.writeVector(3, this.vRoomPrivilegete);
  os.writeInt64(4, this.lAnchorUid);
  os.writeInt64(5, this.lUdbUserId);
};
CheckUserStatusInRoomReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lNimoId = is.readInt64(2, false, this.lNimoId);
  this.vRoomPrivilegete = is.readVector(3, false, this.vRoomPrivilegete);
  this.lAnchorUid = is.readInt64(4, false, this.lAnchorUid);
  this.lUdbUserId = is.readInt64(5, false, this.lUdbUserId);
};
Nimo.CheckUserStatusInRoomReq = CheckUserStatusInRoomReq;
export default CheckUserStatusInRoomReq;