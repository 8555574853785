import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ForbidMessageLogRecord = function ForbidMessageLogRecord() {
  this.lOperatorNimoId = 0;
  this.sOperatorNick = "";
  this.lOperateTime = 0;
  this.lBanTime = 0;
};
ForbidMessageLogRecord.prototype._clone = function () {
  return new Nimo.ForbidMessageLogRecord();
};
ForbidMessageLogRecord.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ForbidMessageLogRecord.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ForbidMessageLogRecord.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lOperatorNimoId);
  os.writeString(1, this.sOperatorNick);
  os.writeInt64(2, this.lOperateTime);
  os.writeInt64(3, this.lBanTime);
};
ForbidMessageLogRecord.prototype.readFrom = function (is) {
  this.lOperatorNimoId = is.readInt64(0, false, this.lOperatorNimoId);
  this.sOperatorNick = is.readString(1, false, this.sOperatorNick);
  this.lOperateTime = is.readInt64(2, false, this.lOperateTime);
  this.lBanTime = is.readInt64(3, false, this.lBanTime);
};
Nimo.ForbidMessageLogRecord = ForbidMessageLogRecord;
export default ForbidMessageLogRecord;