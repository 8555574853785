import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var GiftPlay = function GiftPlay() {
  this.iPlay = 0;
  this.iPlayBoxId = 0;
  this.iRoyalLevel = 0;
  this.iGuardianLevel = 0;
  this.iAnchorTurnamentLevel = 0;
};
GiftPlay.prototype._clone = function () {
  return new Nimo.GiftPlay();
};
GiftPlay.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftPlay.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftPlay.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iPlay);
  os.writeInt32(1, this.iPlayBoxId);
  os.writeInt32(2, this.iRoyalLevel);
  os.writeInt32(3, this.iGuardianLevel);
  os.writeInt32(4, this.iAnchorTurnamentLevel);
};
GiftPlay.prototype.readFrom = function (is) {
  this.iPlay = is.readInt32(0, false, this.iPlay);
  this.iPlayBoxId = is.readInt32(1, false, this.iPlayBoxId);
  this.iRoyalLevel = is.readInt32(2, false, this.iRoyalLevel);
  this.iGuardianLevel = is.readInt32(3, false, this.iGuardianLevel);
  this.iAnchorTurnamentLevel = is.readInt32(4, false, this.iAnchorTurnamentLevel);
};
Nimo.GiftPlay = GiftPlay;
export default GiftPlay;