import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var DoneActionMessageReq = function DoneActionMessageReq() {
  this.tUser = new Nimo.UserId();
  this.sId = "";
  this.lDone = 0;
};
DoneActionMessageReq.prototype._clone = function () {
  return new Nimo.DoneActionMessageReq();
};
DoneActionMessageReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DoneActionMessageReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DoneActionMessageReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeString(1, this.sId);
  os.writeInt32(2, this.lDone);
};
DoneActionMessageReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.sId = is.readString(1, false, this.sId);
  this.lDone = is.readInt32(2, false, this.lDone);
};
Nimo.DoneActionMessageReq = DoneActionMessageReq;
export default DoneActionMessageReq;