import dashboard from '@client/common/lang/dashboard';
import { useLangPkg } from '@client/common/components/hooks/useLangPkg';

/**
 * @param {{ t: keyof import('@client/common/lang').OptionalLangPkg<typeof dashboard> }} param0 
 * @returns 
 */
export var Translation = function Translation(_ref) {
  var t = _ref.t;
  var lang = useLangPkg(dashboard);
  return lang[t];
};