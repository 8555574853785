import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var UidNickName = function UidNickName() {
  this.lUid = 0;
  this.sNickName = "";
};
UidNickName.prototype._clone = function () {
  return new Nimo.UidNickName();
};
UidNickName.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UidNickName.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UidNickName.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.sNickName);
};
UidNickName.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.sNickName = is.readString(1, false, this.sNickName);
};
Nimo.UidNickName = UidNickName;
export default UidNickName;