import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
import './ContentFormat';
import './BulletFormat';
import './UidNickName';
var SendMessageReq = function SendMessageReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.sContent = "";
  this.iShowMode = 0;
  this.tFormat = new Nimo.ContentFormat();
  this.tBulletFormat = new Nimo.BulletFormat();
  this.vAtSomeone = new Taf.Vector(new Nimo.UidNickName());
  this.lPid = 0;
  this.sNickName = "";
  this.iGender = 0;
  this.iMsgCategory = 0;
  this.iExtraStyle = 0;
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iVersion = 0;
};
SendMessageReq.prototype._clone = function () {
  return new Nimo.SendMessageReq();
};
SendMessageReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SendMessageReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SendMessageReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeString(2, this.sContent);
  os.writeInt32(3, this.iShowMode);
  os.writeStruct(4, this.tFormat);
  os.writeStruct(5, this.tBulletFormat);
  os.writeVector(6, this.vAtSomeone);
  os.writeInt64(7, this.lPid);
  os.writeString(8, this.sNickName);
  os.writeInt32(9, this.iGender);
  os.writeInt32(10, this.iMsgCategory);
  os.writeInt32(11, this.iExtraStyle);
  os.writeMap(12, this.mExtraData);
  os.writeInt32(13, this.iVersion);
};
SendMessageReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.sContent = is.readString(2, false, this.sContent);
  this.iShowMode = is.readInt32(3, false, this.iShowMode);
  this.tFormat = is.readStruct(4, false, this.tFormat);
  this.tBulletFormat = is.readStruct(5, false, this.tBulletFormat);
  this.vAtSomeone = is.readVector(6, false, this.vAtSomeone);
  this.lPid = is.readInt64(7, false, this.lPid);
  this.sNickName = is.readString(8, false, this.sNickName);
  this.iGender = is.readInt32(9, false, this.iGender);
  this.iMsgCategory = is.readInt32(10, false, this.iMsgCategory);
  this.iExtraStyle = is.readInt32(11, false, this.iExtraStyle);
  this.mExtraData = is.readMap(12, false, this.mExtraData);
  this.iVersion = is.readInt32(13, false, this.iVersion);
};
Nimo.SendMessageReq = SendMessageReq;
export default SendMessageReq;