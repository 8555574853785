import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './SimpleLiveRoomView';
import './VideoItemView';
import './Tag';
var LiveVideoView = function LiveVideoView() {
  this.id = "";
  this.videoUrl = "";
  this.videoTheme = "";
  this.businessType = 0;
  this.dotType = 0;
  this.anchorId = 0;
  this.anchorName = "";
  this.authorId = 0;
  this.author = "";
  this.countryCode = "";
  this.lcid = 0;
  this.language = "";
  this.playNum = 0;
  this.playDuration = 0;
  this.showScreenshots = "";
  this.shareScreenshot = "";
  this.resourceId = "";
  this.title = "";
  this.subTitle = "";
  this.videoStreamStatus = 0;
  this.createdTime = 0;
  this.uplongdTime = 0;
  this.upNum = 0;
  this.downNum = 0;
  this.anchorAvatarUrl = "";
  this.anchorAvatarBoxUrl = "";
  this.authorAvatarUrl = "";
  this.authorAvatarBoxUrl = "";
  this.roomTypeName = "";
  this.onlineLiveRoomInfo = new NimoBuss.SimpleLiveRoomView();
  this.roomId = 0;
  this.alise = "";
  this.transcodeStatus = 0;
  this.videoResolution = "";
  this.bWM = 0;
  this.iResoVideoType = 0;
  this.gameId = 0;
  this.type = "video";
  this.gameName = "";
  this.lastUplongTime = 0;
  this.score = 0;
  this.showLcid = "";
  this.tranCodeVideoUrl = "";
  this.resolution = 0;
  this.multiResolutionVideoUrl = new Taf.Vector(new NimoBuss.VideoItemView());
  this.cdnFlag = 0;
  this.lastUpdateTime = 0;
  this.updatedTime = 0;
  this.tagList = new Taf.Vector(new NimoBuss.Tag());
  this.commentCount = 0;
  this.likeCount = 0;
  this.favor = 0;
  this.introduction = "";
  this.videoTagId = "";
  this.videoTagName = "";
  this.tabId = "";
  this.topicId = "";
  this.topicTag = "";
  this.activityId = "";
  this.activityType = "";
  this.eventId = "";
  this.scheduleId = "";
  this.teamId = "";
  this.auditSpotState = "";
  this.source = "";
  this.authorLiveRoomInfo = new NimoBuss.SimpleLiveRoomView();
  this.followStatus = 0;
  this.isAuthorBlock = 0;
  this.widthAndHeight = "";
  this.commentControl = 0;
  this.videoMp4Url = "";
};
LiveVideoView.prototype._clone = function () {
  return new NimoBuss.LiveVideoView();
};
LiveVideoView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveVideoView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveVideoView.prototype.writeTo = function (os) {
  os.writeString(0, this.id);
  os.writeString(1, this.videoUrl);
  os.writeString(2, this.videoTheme);
  os.writeInt32(3, this.businessType);
  os.writeInt32(4, this.dotType);
  os.writeInt64(5, this.anchorId);
  os.writeString(6, this.anchorName);
  os.writeInt64(7, this.authorId);
  os.writeString(8, this.author);
  os.writeString(9, this.countryCode);
  os.writeInt32(10, this.lcid);
  os.writeString(11, this.language);
  os.writeInt64(12, this.playNum);
  os.writeInt64(13, this.playDuration);
  os.writeString(14, this.showScreenshots);
  os.writeString(15, this.shareScreenshot);
  os.writeString(16, this.resourceId);
  os.writeString(17, this.title);
  os.writeString(18, this.subTitle);
  os.writeInt32(19, this.videoStreamStatus);
  os.writeInt64(20, this.createdTime);
  os.writeInt64(21, this.uplongdTime);
  os.writeInt64(22, this.upNum);
  os.writeInt64(23, this.downNum);
  os.writeString(24, this.anchorAvatarUrl);
  os.writeString(25, this.anchorAvatarBoxUrl);
  os.writeString(26, this.authorAvatarUrl);
  os.writeString(27, this.authorAvatarBoxUrl);
  os.writeString(28, this.roomTypeName);
  os.writeStruct(29, this.onlineLiveRoomInfo);
  os.writeInt64(30, this.roomId);
  os.writeString(31, this.alise);
  os.writeInt32(32, this.transcodeStatus);
  os.writeString(33, this.videoResolution);
  os.writeInt32(34, this.bWM);
  os.writeInt32(35, this.iResoVideoType);
  os.writeInt64(36, this.gameId);
  os.writeString(37, this.type);
  os.writeString(38, this.gameName);
  os.writeInt64(39, this.lastUplongTime);
  os.writeFloat(40, this.score);
  os.writeString(41, this.showLcid);
  os.writeString(42, this.tranCodeVideoUrl);
  os.writeInt32(43, this.resolution);
  os.writeVector(44, this.multiResolutionVideoUrl);
  os.writeInt32(45, this.cdnFlag);
  os.writeInt64(46, this.lastUpdateTime);
  os.writeInt64(47, this.updatedTime);
  os.writeVector(48, this.tagList);
  os.writeInt32(49, this.commentCount);
  os.writeInt32(50, this.likeCount);
  os.writeInt32(51, this.favor);
  os.writeString(52, this.introduction);
  os.writeString(53, this.videoTagId);
  os.writeString(54, this.videoTagName);
  os.writeString(55, this.tabId);
  os.writeString(56, this.topicId);
  os.writeString(57, this.topicTag);
  os.writeString(58, this.activityId);
  os.writeString(59, this.activityType);
  os.writeString(60, this.eventId);
  os.writeString(61, this.scheduleId);
  os.writeString(62, this.teamId);
  os.writeString(63, this.auditSpotState);
  os.writeString(64, this.source);
  os.writeStruct(65, this.authorLiveRoomInfo);
  os.writeInt32(66, this.followStatus);
  os.writeInt32(67, this.isAuthorBlock);
  os.writeString(68, this.widthAndHeight);
  os.writeInt32(70, this.commentControl);
  os.writeString(71, this.videoMp4Url);
};
LiveVideoView.prototype.readFrom = function (is) {
  this.id = is.readString(0, false, this.id);
  this.videoUrl = is.readString(1, false, this.videoUrl);
  this.videoTheme = is.readString(2, false, this.videoTheme);
  this.businessType = is.readInt32(3, false, this.businessType);
  this.dotType = is.readInt32(4, false, this.dotType);
  this.anchorId = is.readInt64(5, false, this.anchorId);
  this.anchorName = is.readString(6, false, this.anchorName);
  this.authorId = is.readInt64(7, false, this.authorId);
  this.author = is.readString(8, false, this.author);
  this.countryCode = is.readString(9, false, this.countryCode);
  this.lcid = is.readInt32(10, false, this.lcid);
  this.language = is.readString(11, false, this.language);
  this.playNum = is.readInt64(12, false, this.playNum);
  this.playDuration = is.readInt64(13, false, this.playDuration);
  this.showScreenshots = is.readString(14, false, this.showScreenshots);
  this.shareScreenshot = is.readString(15, false, this.shareScreenshot);
  this.resourceId = is.readString(16, false, this.resourceId);
  this.title = is.readString(17, false, this.title);
  this.subTitle = is.readString(18, false, this.subTitle);
  this.videoStreamStatus = is.readInt32(19, false, this.videoStreamStatus);
  this.createdTime = is.readInt64(20, false, this.createdTime);
  this.uplongdTime = is.readInt64(21, false, this.uplongdTime);
  this.upNum = is.readInt64(22, false, this.upNum);
  this.downNum = is.readInt64(23, false, this.downNum);
  this.anchorAvatarUrl = is.readString(24, false, this.anchorAvatarUrl);
  this.anchorAvatarBoxUrl = is.readString(25, false, this.anchorAvatarBoxUrl);
  this.authorAvatarUrl = is.readString(26, false, this.authorAvatarUrl);
  this.authorAvatarBoxUrl = is.readString(27, false, this.authorAvatarBoxUrl);
  this.roomTypeName = is.readString(28, false, this.roomTypeName);
  this.onlineLiveRoomInfo = is.readStruct(29, false, this.onlineLiveRoomInfo);
  this.roomId = is.readInt64(30, false, this.roomId);
  this.alise = is.readString(31, false, this.alise);
  this.transcodeStatus = is.readInt32(32, false, this.transcodeStatus);
  this.videoResolution = is.readString(33, false, this.videoResolution);
  this.bWM = is.readInt32(34, false, this.bWM);
  this.iResoVideoType = is.readInt32(35, false, this.iResoVideoType);
  this.gameId = is.readInt64(36, false, this.gameId);
  this.type = is.readString(37, false, this.type);
  this.gameName = is.readString(38, false, this.gameName);
  this.lastUplongTime = is.readInt64(39, false, this.lastUplongTime);
  this.score = is.readFloat(40, false, this.score);
  this.showLcid = is.readString(41, false, this.showLcid);
  this.tranCodeVideoUrl = is.readString(42, false, this.tranCodeVideoUrl);
  this.resolution = is.readInt32(43, false, this.resolution);
  this.multiResolutionVideoUrl = is.readVector(44, false, this.multiResolutionVideoUrl);
  this.cdnFlag = is.readInt32(45, false, this.cdnFlag);
  this.lastUpdateTime = is.readInt64(46, false, this.lastUpdateTime);
  this.updatedTime = is.readInt64(47, false, this.updatedTime);
  this.tagList = is.readVector(48, false, this.tagList);
  this.commentCount = is.readInt32(49, false, this.commentCount);
  this.likeCount = is.readInt32(50, false, this.likeCount);
  this.favor = is.readInt32(51, false, this.favor);
  this.introduction = is.readString(52, false, this.introduction);
  this.videoTagId = is.readString(53, false, this.videoTagId);
  this.videoTagName = is.readString(54, false, this.videoTagName);
  this.tabId = is.readString(55, false, this.tabId);
  this.topicId = is.readString(56, false, this.topicId);
  this.topicTag = is.readString(57, false, this.topicTag);
  this.activityId = is.readString(58, false, this.activityId);
  this.activityType = is.readString(59, false, this.activityType);
  this.eventId = is.readString(60, false, this.eventId);
  this.scheduleId = is.readString(61, false, this.scheduleId);
  this.teamId = is.readString(62, false, this.teamId);
  this.auditSpotState = is.readString(63, false, this.auditSpotState);
  this.source = is.readString(64, false, this.source);
  this.authorLiveRoomInfo = is.readStruct(65, false, this.authorLiveRoomInfo);
  this.followStatus = is.readInt32(66, false, this.followStatus);
  this.isAuthorBlock = is.readInt32(67, false, this.isAuthorBlock);
  this.widthAndHeight = is.readString(68, false, this.widthAndHeight);
  this.commentControl = is.readInt32(70, false, this.commentControl);
  this.videoMp4Url = is.readString(71, false, this.videoMp4Url);
};
NimoBuss.LiveVideoView = LiveVideoView;
export default LiveVideoView;