import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var ContentFormat = function ContentFormat() {
  this.iFontColor = -1;
  this.iFontSize = 4;
  this.iPopupStyle = 0;
};
ContentFormat.prototype._clone = function () {
  return new Nimo.ContentFormat();
};
ContentFormat.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ContentFormat.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ContentFormat.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iFontColor);
  os.writeInt32(1, this.iFontSize);
  os.writeInt32(2, this.iPopupStyle);
};
ContentFormat.prototype.readFrom = function (is) {
  this.iFontColor = is.readInt32(0, false, this.iFontColor);
  this.iFontSize = is.readInt32(1, false, this.iFontSize);
  this.iPopupStyle = is.readInt32(2, false, this.iPopupStyle);
};
Nimo.ContentFormat = ContentFormat;
export default ContentFormat;