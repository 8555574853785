// https://my-dummy-domain.netlify.app/
// https://love2dev.com/blog/rel-noopener/
// https://www.yinxiang.com/everhub/note/0a1eeb79-822e-47af-913f-1cbc81a53751
/**
 * @param {string} url 
 */
export var openLink = function openLink(url) {
  var a = document.createElement('a');
  if (a && a.click) {
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener';
    a.click();
  } else {
    var newTab = window.open();
    newTab.opener = null;
    newTab.location = url;
  }
};