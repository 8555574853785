import NimoSailActivity from "../nimosailactivity";
import Taf from "@nimo-fed/taf-lib-wup";
var OpenShareRewardMissionReq = function OpenShareRewardMissionReq() {
  this.udbUserId = 0;
  this.invitePeopleNum = 0;
};
OpenShareRewardMissionReq.prototype._clone = function () {
  return new NimoSailActivity.OpenShareRewardMissionReq();
};
OpenShareRewardMissionReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
OpenShareRewardMissionReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
OpenShareRewardMissionReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
  os.writeInt32(1, this.invitePeopleNum);
};
OpenShareRewardMissionReq.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
  this.invitePeopleNum = is.readInt32(1, false, this.invitePeopleNum);
};
NimoSailActivity.OpenShareRewardMissionReq = OpenShareRewardMissionReq;
export default OpenShareRewardMissionReq;