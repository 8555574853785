import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './DecorationInfo';
import './StreamerEffect';
import './GiftEffectID';
var SendItemSubBroadcastPacket = function SendItemSubBroadcastPacket() {
  this.iItemType = 0;
  this.iItemCount = 0;
  this.lPresenterUid = 0;
  this.lSenderUid = 0;
  this.sPresenterNick = "";
  this.sSenderNick = "";
  this.lRoomId = 0;
  this.sSendContent = "";
  this.iSuperPupleLevel = 0;
  this.iComboScore = 0;
  this.sExpand = "";
  this.iColorEffectType = 0;
  this.sSenderAvatarUrl = "";
  this.iPayType = 0;
  this.mUpgradeEffect = new Taf.Map(new Taf.INT32(), new Taf.INT64());
  this.vDecorationPrefix = new Taf.Vector(new Nimo.DecorationInfo());
  this.vOverlayEffect = new Taf.Vector(new Taf.INT32());
  this.fItemPrice = 0;
  this.mStreamerEffect = new Taf.Map(new Taf.INT32(), new Nimo.StreamerEffect());
  this.sConsumeIndex = "";
  this.iActivityId = 0;
  this.sRecipientNick = "";
  this.lRecipientId = 0;
  this.iIsFold = 0;
  this.iIsShow = 0;
  this.iUserLevel = 0;
  this.iIsContributeUser = 0;
  this.mBigAnimEffectText = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mBigAnimEffectImage = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.tEffectID = new Nimo.GiftEffectID();
  this.iIsMysteryMan = 0;
};
SendItemSubBroadcastPacket.prototype._clone = function () {
  return new Nimo.SendItemSubBroadcastPacket();
};
SendItemSubBroadcastPacket.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SendItemSubBroadcastPacket.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SendItemSubBroadcastPacket.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iItemType);
  os.writeInt32(1, this.iItemCount);
  os.writeInt64(2, this.lPresenterUid);
  os.writeInt64(3, this.lSenderUid);
  os.writeString(4, this.sPresenterNick);
  os.writeString(5, this.sSenderNick);
  os.writeInt64(6, this.lRoomId);
  os.writeString(7, this.sSendContent);
  os.writeInt32(8, this.iSuperPupleLevel);
  os.writeInt32(9, this.iComboScore);
  os.writeString(10, this.sExpand);
  os.writeInt32(11, this.iColorEffectType);
  os.writeString(12, this.sSenderAvatarUrl);
  os.writeInt32(13, this.iPayType);
  os.writeMap(14, this.mUpgradeEffect);
  os.writeVector(15, this.vDecorationPrefix);
  os.writeVector(16, this.vOverlayEffect);
  os.writeFloat(17, this.fItemPrice);
  os.writeMap(18, this.mStreamerEffect);
  os.writeString(20, this.sConsumeIndex);
  os.writeInt32(21, this.iActivityId);
  os.writeString(22, this.sRecipientNick);
  os.writeInt64(23, this.lRecipientId);
  os.writeInt32(24, this.iIsFold);
  os.writeInt32(25, this.iIsShow);
  os.writeInt32(26, this.iUserLevel);
  os.writeInt32(27, this.iIsContributeUser);
  os.writeMap(28, this.mBigAnimEffectText);
  os.writeMap(29, this.mBigAnimEffectImage);
  os.writeStruct(30, this.tEffectID);
  os.writeInt32(31, this.iIsMysteryMan);
};
SendItemSubBroadcastPacket.prototype.readFrom = function (is) {
  this.iItemType = is.readInt32(0, false, this.iItemType);
  this.iItemCount = is.readInt32(1, false, this.iItemCount);
  this.lPresenterUid = is.readInt64(2, false, this.lPresenterUid);
  this.lSenderUid = is.readInt64(3, false, this.lSenderUid);
  this.sPresenterNick = is.readString(4, false, this.sPresenterNick);
  this.sSenderNick = is.readString(5, false, this.sSenderNick);
  this.lRoomId = is.readInt64(6, false, this.lRoomId);
  this.sSendContent = is.readString(7, false, this.sSendContent);
  this.iSuperPupleLevel = is.readInt32(8, false, this.iSuperPupleLevel);
  this.iComboScore = is.readInt32(9, false, this.iComboScore);
  this.sExpand = is.readString(10, false, this.sExpand);
  this.iColorEffectType = is.readInt32(11, false, this.iColorEffectType);
  this.sSenderAvatarUrl = is.readString(12, false, this.sSenderAvatarUrl);
  this.iPayType = is.readInt32(13, false, this.iPayType);
  this.mUpgradeEffect = is.readMap(14, false, this.mUpgradeEffect);
  this.vDecorationPrefix = is.readVector(15, false, this.vDecorationPrefix);
  this.vOverlayEffect = is.readVector(16, false, this.vOverlayEffect);
  this.fItemPrice = is.readFloat(17, false, this.fItemPrice);
  this.mStreamerEffect = is.readMap(18, false, this.mStreamerEffect);
  this.sConsumeIndex = is.readString(20, false, this.sConsumeIndex);
  this.iActivityId = is.readInt32(21, false, this.iActivityId);
  this.sRecipientNick = is.readString(22, false, this.sRecipientNick);
  this.lRecipientId = is.readInt64(23, false, this.lRecipientId);
  this.iIsFold = is.readInt32(24, false, this.iIsFold);
  this.iIsShow = is.readInt32(25, false, this.iIsShow);
  this.iUserLevel = is.readInt32(26, false, this.iUserLevel);
  this.iIsContributeUser = is.readInt32(27, false, this.iIsContributeUser);
  this.mBigAnimEffectText = is.readMap(28, false, this.mBigAnimEffectText);
  this.mBigAnimEffectImage = is.readMap(29, false, this.mBigAnimEffectImage);
  this.tEffectID = is.readStruct(30, false, this.tEffectID);
  this.iIsMysteryMan = is.readInt32(31, false, this.iIsMysteryMan);
};
Nimo.SendItemSubBroadcastPacket = SendItemSubBroadcastPacket;
export default SendItemSubBroadcastPacket;