import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
import './BaseParam';
var LiveRoomInfoByAliseReq = function LiveRoomInfoByAliseReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.alise = "";
  this.language = "";
  this.countryCode = "";
  this.lcid = 0;
};
LiveRoomInfoByAliseReq.prototype._clone = function () {
  return new NimoBuss.LiveRoomInfoByAliseReq();
};
LiveRoomInfoByAliseReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveRoomInfoByAliseReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveRoomInfoByAliseReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeString(1, this.alise);
  os.writeString(2, this.language);
  os.writeString(3, this.countryCode);
  os.writeInt32(4, this.lcid);
};
LiveRoomInfoByAliseReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.alise = is.readString(1, false, this.alise);
  this.language = is.readString(2, false, this.language);
  this.countryCode = is.readString(3, false, this.countryCode);
  this.lcid = is.readInt32(4, false, this.lcid);
};
NimoBuss.LiveRoomInfoByAliseReq = LiveRoomInfoByAliseReq;
export default LiveRoomInfoByAliseReq;