import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GiftEffect';
var GiftResource = function GiftResource() {
  this.sIcon = "";
  this.sDynamicResource = "";
  this.sCornerIcon = "";
  this.tGiftEffect = new Nimo.GiftEffect();
  this.sWebBanner = "";
  this.sAppBanner = "";
};
GiftResource.prototype._clone = function () {
  return new Nimo.GiftResource();
};
GiftResource.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftResource.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftResource.prototype.writeTo = function (os) {
  os.writeString(0, this.sIcon);
  os.writeString(1, this.sDynamicResource);
  os.writeString(2, this.sCornerIcon);
  os.writeStruct(3, this.tGiftEffect);
  os.writeString(4, this.sWebBanner);
  os.writeString(5, this.sAppBanner);
};
GiftResource.prototype.readFrom = function (is) {
  this.sIcon = is.readString(0, false, this.sIcon);
  this.sDynamicResource = is.readString(1, false, this.sDynamicResource);
  this.sCornerIcon = is.readString(2, false, this.sCornerIcon);
  this.tGiftEffect = is.readStruct(3, false, this.tGiftEffect);
  this.sWebBanner = is.readString(4, false, this.sWebBanner);
  this.sAppBanner = is.readString(5, false, this.sAppBanner);
};
Nimo.GiftResource = GiftResource;
export default GiftResource;