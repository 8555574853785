import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './GiftItem';
import './GiftChannel';
import './GiftEffect';
import './GiftPlay';
var GiftConfig = function GiftConfig() {
  this.tGiftItem = new Nimo.GiftItem();
  this.iGiftType = 0;
  this.fExchangeMoney = 0;
  this.mStatus = new Taf.Map(new Taf.STRING(), new Taf.INT32());
  this.mNameLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mDescLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mDynamicResource = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mPackNum = new Taf.Map(new Taf.STRING(), new Taf.Vector(new Taf.INT32()));
  this.vGiftChannel = new Taf.Vector(new Nimo.GiftChannel());
  this.bIsAllRoomCanSee = true;
  this.mGiftEffect = new Taf.Map(new Taf.STRING(), new Nimo.GiftEffect());
  this.iGiftLevel = 0;
  this.vGiftPlay = new Taf.Vector(new Nimo.GiftPlay());
  this.lCreateTime = 0;
  this.bIsActivityGift = true;
  this.iTabAll = 0;
  this.iGiftChannelRelation = 0;
  this.iIsAllRoomCanSee = 0;
};
GiftConfig.prototype._clone = function () {
  return new Nimo.GiftConfig();
};
GiftConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftConfig.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tGiftItem);
  os.writeInt32(1, this.iGiftType);
  os.writeFloat(2, this.fExchangeMoney);
  os.writeMap(3, this.mStatus);
  os.writeMap(4, this.mNameLang);
  os.writeMap(5, this.mDescLang);
  os.writeMap(6, this.mDynamicResource);
  os.writeMap(7, this.mPackNum);
  os.writeVector(8, this.vGiftChannel);
  os.writeBoolean(9, this.bIsAllRoomCanSee);
  os.writeMap(10, this.mGiftEffect);
  os.writeInt32(11, this.iGiftLevel);
  os.writeVector(12, this.vGiftPlay);
  os.writeInt64(13, this.lCreateTime);
  os.writeBoolean(14, this.bIsActivityGift);
  os.writeInt32(15, this.iTabAll);
  os.writeInt32(16, this.iGiftChannelRelation);
  os.writeInt32(17, this.iIsAllRoomCanSee);
};
GiftConfig.prototype.readFrom = function (is) {
  this.tGiftItem = is.readStruct(0, false, this.tGiftItem);
  this.iGiftType = is.readInt32(1, false, this.iGiftType);
  this.fExchangeMoney = is.readFloat(2, false, this.fExchangeMoney);
  this.mStatus = is.readMap(3, false, this.mStatus);
  this.mNameLang = is.readMap(4, false, this.mNameLang);
  this.mDescLang = is.readMap(5, false, this.mDescLang);
  this.mDynamicResource = is.readMap(6, false, this.mDynamicResource);
  this.mPackNum = is.readMap(7, false, this.mPackNum);
  this.vGiftChannel = is.readVector(8, false, this.vGiftChannel);
  this.bIsAllRoomCanSee = is.readBoolean(9, false, this.bIsAllRoomCanSee);
  this.mGiftEffect = is.readMap(10, false, this.mGiftEffect);
  this.iGiftLevel = is.readInt32(11, false, this.iGiftLevel);
  this.vGiftPlay = is.readVector(12, false, this.vGiftPlay);
  this.lCreateTime = is.readInt64(13, false, this.lCreateTime);
  this.bIsActivityGift = is.readBoolean(14, false, this.bIsActivityGift);
  this.iTabAll = is.readInt32(15, false, this.iTabAll);
  this.iGiftChannelRelation = is.readInt32(16, false, this.iGiftChannelRelation);
  this.iIsAllRoomCanSee = is.readInt32(17, false, this.iIsAllRoomCanSee);
};
Nimo.GiftConfig = GiftConfig;
export default GiftConfig;