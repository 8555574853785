import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetPullInfoReq = function GetPullInfoReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.bGetHostIp = false;
  this.mUserData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sPassword = "";
};
GetPullInfoReq.prototype._clone = function () {
  return new Nimo.GetPullInfoReq();
};
GetPullInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPullInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPullInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeBoolean(2, this.bGetHostIp);
  os.writeMap(15, this.mUserData);
  os.writeString(16, this.sPassword);
};
GetPullInfoReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.bGetHostIp = is.readBoolean(2, false, this.bGetHostIp);
  this.mUserData = is.readMap(15, false, this.mUserData);
  this.sPassword = is.readString(16, false, this.sPassword);
};
Nimo.GetPullInfoReq = GetPullInfoReq;
export default GetPullInfoReq;