import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './RichText';
import './BannerButtonInfo';
var AnchorMsgBannerNotice = function AnchorMsgBannerNotice() {
  this.lAnchorId = 0;
  this.sIcon = "";
  this.tTitle = new Nimo.RichText();
  this.tContent = new Nimo.RichText();
  this.iWeight = 0;
  this.tWebButton = new Nimo.BannerButtonInfo();
  this.tPCButton = new Nimo.BannerButtonInfo();
  this.vClient = new Taf.Vector(new Taf.INT32());
  this.iRecordId = 0;
};
AnchorMsgBannerNotice.prototype._clone = function () {
  return new Nimo.AnchorMsgBannerNotice();
};
AnchorMsgBannerNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorMsgBannerNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorMsgBannerNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAnchorId);
  os.writeString(2, this.sIcon);
  os.writeStruct(3, this.tTitle);
  os.writeStruct(4, this.tContent);
  os.writeInt32(5, this.iWeight);
  os.writeStruct(6, this.tWebButton);
  os.writeStruct(7, this.tPCButton);
  os.writeVector(8, this.vClient);
  os.writeInt32(9, this.iRecordId);
};
AnchorMsgBannerNotice.prototype.readFrom = function (is) {
  this.lAnchorId = is.readInt64(0, false, this.lAnchorId);
  this.sIcon = is.readString(2, false, this.sIcon);
  this.tTitle = is.readStruct(3, false, this.tTitle);
  this.tContent = is.readStruct(4, false, this.tContent);
  this.iWeight = is.readInt32(5, false, this.iWeight);
  this.tWebButton = is.readStruct(6, false, this.tWebButton);
  this.tPCButton = is.readStruct(7, false, this.tPCButton);
  this.vClient = is.readVector(8, false, this.vClient);
  this.iRecordId = is.readInt32(9, false, this.iRecordId);
};
Nimo.AnchorMsgBannerNotice = AnchorMsgBannerNotice;
export default AnchorMsgBannerNotice;