import { parseURL } from '@client/common/utils/fn';
var getReferrerFromCookie = function getReferrerFromCookie() {
  var arr = document.cookie.match(/(^| )_nm_referrer=([^;]*)(;|$)/);
  if (arr != null) {
    try {
      return decodeURIComponent(arr[2]);
    } catch (e) {
      // ignore error
    }
  }
  return '';
};
var isSelfHost = function isSelfHost(host) {
  return /\bnimo\.tv$/.test(host);
};

/**
 * @param {string} referrer
 * @returns string
 */
var getThirdPartyHost = function getThirdPartyHost(referrer) {
  if (referrer) {
    var _parseURL = parseURL(referrer),
      host = _parseURL.host;
    if (!isSelfHost(host) && host) {
      return host;
    }
  }
  return '';
};

/**
 * 获取第三方平台refer的host
 * @returns {string}
 */
export var getThirdPartyReferrerHost = function getThirdPartyReferrerHost() {
  return getThirdPartyHost(document.referrer) || getThirdPartyHost(getReferrerFromCookie());
};