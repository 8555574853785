import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
import './UserId';
var GetRoomHistoryMsgReq = function GetRoomHistoryMsgReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
};
GetRoomHistoryMsgReq.prototype._clone = function () {
  return new Nimo.GetRoomHistoryMsgReq();
};
GetRoomHistoryMsgReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRoomHistoryMsgReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRoomHistoryMsgReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
};
GetRoomHistoryMsgReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
};
Nimo.GetRoomHistoryMsgReq = GetRoomHistoryMsgReq;
export default GetRoomHistoryMsgReq;