import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var MainAnimEffect = function MainAnimEffect() {
  this.sResource = "";
};
MainAnimEffect.prototype._clone = function () {
  return new Nimo.MainAnimEffect();
};
MainAnimEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MainAnimEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MainAnimEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sResource);
};
MainAnimEffect.prototype.readFrom = function (is) {
  this.sResource = is.readString(0, false, this.sResource);
};
Nimo.MainAnimEffect = MainAnimEffect;
export default MainAnimEffect;