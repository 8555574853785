import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import ua from '@client/common/utils/ua';
var browser = ua.browser,
  platform = ua.platform,
  os = ua.os,
  isWap = ua.isWap;
var osName = os.name;
function isSupportFlvjs() {
  var supportMSEH264Playback = window.MediaSource && window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"');
  var supportNetworkStreamIO = isSupportFetchStreamLoader() || isSupportXhrMozChunkLoader();
  return supportMSEH264Playback && supportNetworkStreamIO;
}
function isSupportFetchStreamLoader() {
  var isMSEdge = browser.name === 'Microsoft Edge';
  var version = browser.version;
  try {
    var _version$split = version.split('.'),
      _version$split2 = _slicedToArray(_version$split, 2),
      _version$split2$ = _version$split2[1],
      minorVersion = _version$split2$ === void 0 ? '0' : _version$split2$;
    var browserNotBlacklisted = isMSEdge ? parseInt(minorVersion, 10) >= 15048 : true;
    // fetch + stream is broken on Microsoft Edge. Disable before build 15048.
    // see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8196907/
    // Fixed in Jan 10, 2017. Build 15048+ removed from blacklist.
    return window.fetch && window.ReadableStream && browserNotBlacklisted;
  } catch (e) {
    return false;
  }
}
function isSupportXhrMozChunkLoader() {
  try {
    var xhr = new XMLHttpRequest();
    // Firefox 37- requires .open() to be called before setting responseType
    xhr.open('GET', 'https://example.com', true);
    xhr.responseType = 'moz-chunked-arraybuffer';
    return xhr.responseType === 'moz-chunked-arraybuffer';
  } catch (e) {
    // Log.w('MozChunkedLoader', e.message);
    return false;
  }
}
function isSupportHlsjs() {
  var mediaSource = window.MediaSource || window.WebKitMediaSource;
  var sourceBuffer = window.SourceBuffer || window.WebKitSourceBuffer;
  var isTypeSupported = mediaSource && typeof mediaSource.isTypeSupported === 'function' && mediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"');
  var sourceBufferValidAPI = !sourceBuffer || sourceBuffer.prototype && typeof sourceBuffer.prototype.appendBuffer === 'function' && typeof sourceBuffer.prototype.remove === 'function';
  return !!isTypeSupported && !!sourceBufferValidAPI;
}
function isMacOSSafari() {
  return osName === 'macOS' && browser.name === 'Safari';
}
export function isSupportPureAudioOnly() {
  return !isWap && osName === 'Windows' && ['Firefox', 'Opera'].indexOf(browser.name) > -1;
}
export default {
  supportType: {
    flv: !isWap,
    // && !isMacOSSafari(),
    hls: isWap ? true : isSupportHlsjs() || isMacOSSafari(),
    rtmp: false
  },
  supportTech: {
    flash: !isWap,
    flvjs: isSupportFlvjs(),
    hlsjs: isSupportHlsjs(),
    hls: isMacOSSafari(),
    rtmp: false
  },
  platform: platform,
  browser: browser.name
};