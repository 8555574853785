import { PWA_QUERY } from '@client/common/utils/isInPwa';
import nimoReport from '@client/common/services/report';
import { getCountry } from '@client/common/utils/cookieFn';
import { addMQLEventListener, tryMatchMedia } from '@client/common/utils/mediaQuery';
export var initPwaReport = function initPwaReport() {
  var mql = tryMatchMedia(PWA_QUERY);
  if (mql) {
    if (mql.matches) {
      nimoReport.formatAndSend('usr/click/quickapp', '用户/点击/quickapp', {
        from_: getCountry()
      });
    }
    return addMQLEventListener(mql, function (ev) {
      if (ev.matches) {
        nimoReport.formatAndSend('usr/click/quickapp/browser', '用户/点击/quickapp/浏览器', {
          from_: getCountry()
        });
      }
    });
  }
  return function () {};
};